"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var locale = {
  // Options
  items_per_page: '/ sivu',
  jump_to: 'Mene',
  jump_to_confirm: 'Potvrdite',
  page: 'Sivu',
  // Pagination
  prev_page: 'Edellinen sivu',
  next_page: 'Seuraava sivu',
  prev_5: 'Edelliset 5 sivua',
  next_5: 'Seuraavat 5 sivua',
  prev_3: 'Edelliset 3 sivua',
  next_3: 'Seuraavat 3 sivua',
  page_size: 'Page Size'
};
var _default = exports.default = locale;