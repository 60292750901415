import { Button, Card, Input, message } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Layout, { Content } from 'antd/lib/layout/layout';
import { browserLocalPersistence, browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { auth } from '../util/firebase';

type LoginFormValues = {
    email: string;
    password: string;
    remember: boolean;
};

function Login() {
    const [loading, setLoading] = useState(false);

    const onFinish = useCallback(({ email, password, remember }: LoginFormValues) => {
        setLoading(true);
        setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
            .then(() => signInWithEmailAndPassword(auth, email, password))
            .catch(error => {
                setLoading(false);
                message.error('Kirjautuminen epäonnistui');
                console.error(error);
            });
    }, [setLoading]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card title="Kirjaudu sisään" style={{ width: 360 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 32 }}
                        initialValues={{ remember: true }}
                        onFinishFailed={() => {}}
                        autoComplete="off"
                        layout='vertical'
                        onFinish={onFinish}
                    >
                        <FormItem label="Sähköposti" name="email">
                            <Input type="email" required />
                        </FormItem>
                        <FormItem label="Salasana" name="password">
                            <Input.Password required />
                        </FormItem>
                        <FormItem>
                            <FormItem noStyle name="remember" valuePropName='checked'>
                                <Checkbox>Muista minut</Checkbox>
                            </FormItem>
                        </FormItem>
                        <FormItem>
                            <Button loading={loading} style={{ width: '100%' }} type="primary" htmlType="submit">
                                Kirjaudu sisään
                            </Button>
                        </FormItem>
                    </Form>
                </Card>
            </Content>
        </Layout>
    );
};

export default Login;
