import { PlusOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import React, { useState } from "react";

export interface TagEditorProps {
    tags?: string[];
    onChange: (tags: string[]) => void;
    loading?: boolean;
    validate?: (tag: string) => boolean;
    color?: string | ((tag: string) => (string | undefined));
    inputStyle?: React.CSSProperties;
}

const TagEditor = ({ tags = [], onChange, loading, validate, color, inputStyle }: TagEditorProps) => {
    const [newTag, setNewTag] = useState("");
    const [error, setError] = useState(false);

    return (
        <>

            {tags?.map((tag) => (
                <Tag style={{ marginBottom: 6 }} onClose={
                    () => onChange(tags.filter((t) => t !== tag))
                } closable key={tag} color={typeof color === 'function' ? color(tag) : color}>{tag}</Tag>
            ))}
            <Input
                prefix={<PlusOutlined style={{ color: '#bbb', fontSize: 12 }} />}
                style={{ borderStyle: 'dashed', width: 100, lineHeight: '20px', ...inputStyle }}
                size="small"
                placeholder="Uusi tagi"
                value={newTag}
                status={error ? "error" : undefined}
                disabled={loading}
                onChange={(e) => {
                    setNewTag(e.target.value)
                    setError(false)
                }}
                onPressEnter={() => {
                    if (validate && !validate(newTag)) {
                        setError(true);
                        return;
                    }
                    if (newTag) {
                        onChange([...tags, newTag]);
                        setNewTag('');
                    }
                }}
            />
        </>
    );
};

export default TagEditor;
