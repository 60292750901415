import React, { useEffect, useState } from "react";
import { InvoiceChangeProps, InvoiceChange } from "../components/InvoiceChange";
import * as R from "ramda";
import {
  Button,
  DatePicker,
  Descriptions,
  Form,
  Input,
  notification,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  InvoiceAuditLog,
  InvoiceChangeLog,
  InvoiceFetchStatus,
  InvoiceLog,
  S3VersionedObject,
} from "../interfaces/InvoiceLog";
import "./InvoiceDetails.css";
import { getInvoiceLog } from "../api/invoices";
import { useSearchParams } from "react-router-dom";
import { getLogs, knownLogGroups, LogEntry } from "../api/logs";
import dayjs from "dayjs";
import { DateContext } from "../components/Timestamp";
import { LoadingOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Header from "../components/Header";
import Title from "antd/lib/typography/Title";
import { notifyOpaqueError } from "../api";
import { tooltips } from "../atoms/invoiceQuery";
import { Client, Invoice } from "../interfaces";
import Paragraph from "antd/lib/typography/Paragraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const logOptions = R.indexBy(
  R.prop("name"),
  knownLogGroups.map((x) => ({
    name: x.split("/").at(-1),
    id: x,
  })),
);

enum SortOrder {
  Ascending = "ASC",
  Descending = "DESC",
}

const InvoiceDetails: React.FC = () => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    (localStorage.getItem("invoice-history-sort") as SortOrder | null) ??
      SortOrder.Descending,
  );
  useEffect(() => {
    localStorage.setItem("invoice-history-sort", sortOrder);
  }, [sortOrder]);

  const [filters, setFilters] = useState<string[]>(
    localStorage
      .getItem("invoice-history-filters")
      ?.split(",")
      .map((x) => x.trim())
      .filter(Boolean) ?? [],
  );
  useEffect(() => {
    localStorage.setItem("invoice-history-filters", filters.join(","));
  }, [filters]);

  const [localTime, setLocalTime] = useState(
    localStorage.getItem("invoice-history-localtime") !== "false",
  );
  useEffect(() => {
    localStorage.setItem("invoice-history-localtime", localTime.toString());
  }, [localTime]);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const clientId = params.clientId || "";
  const integrationKey = params.integrationKey || "";
  const auroraId = params.auroraId || "";
  const invoiceId = params.invoiceId || "";
  const invoiceNumber = params.invoiceNumber || "";
  const startDate = params.startDate || "";
  const endDate = params.endDate || "";
  const logs = (params.logs || "").split(",").filter(Boolean) || [];
  const [changes, setChanges] = useState<
    (InvoiceChangeProps & { key: string })[]
  >([]);
  const [nloads, setNloads] = useState(0);
  const [nloaded, setNloaded] = useState(0);

  const handleSubmit = async (values: any) => {
    // event.preventDefault();
    values = R.filter(Boolean, values);
    const {
      auroraId,
      clientId,
      integrationKey,
      invoiceId,
      invoiceNumber,
      startDate,
      endDate,
      logs,
    } = values;

    const errors: string[] = [];
    if (
      auroraId &&
      (invoiceId || invoiceNumber || clientId || integrationKey)
    ) {
      errors.push("Anna joko Aurora ID tai lasku-asiakas-tunnistepari");
    }
    if (invoiceId && invoiceNumber) {
      errors.push("Anna vain yksi laskutunniste");
    }
    if (clientId && integrationKey) {
      errors.push("Anna vain yksi asiakastunniste");
    }
    if (errors.length) {
      notification.error({
        message: "Epäkelpo syöte",
        description: errors.join("\n"),
      });
      return;
    }

    setSearchParams(values);
    try {
      const loadId = nloads;
      setNloads((nloads) => nloads + 1);
      setChanges([]);
      try {
        for await (const accumulator of getInvoiceLog({
          ...(!clientId && !integrationKey
            ? { auroraId: parseInt(auroraId || invoiceId) }
            : {
                ...(invoiceNumber ? { invoiceNumber } : { invoiceId }),
                ...(integrationKey
                  ? { integrationKey }
                  : { clientId: parseInt(clientId) }),
              }),
          ...R.filter(Boolean, {
            startDate,
            endDate,
            logGroups: (Array.isArray(logs) ? logs : logs ? [logs] : [])
              .map((name: any) => logOptions[name]?.id)
              .filter((x): x is string => !!x),
          }),
        })) {
          setChanges((changes) => {
            return [
              ...changes,
              ...accumulator.slice(changes.length).map((x, i) => ({
                ...toChangePresentation(x),
                key: `${loadId}-${changes.length + i}`,
              })),
            ];
          });
        }
      } catch (e) {
        notifyOpaqueError(e);
      }
    } finally {
      setNloaded((loaded) => loaded + 1);
    }
  };

  useEffect(() => {
    if (!R.isEmpty(params)) {
      handleSubmit(params);
    }
  }, []);

  const filterS3Rules = <T extends InvoiceChangeProps>(items: T[]) => {
    const fetchQueueDates = items
      .filter(R.propEq("color", sourcesByName["invoice_fetch_queue"]!.color))
      .map(R.prop("date"));
    if (!fetchQueueDates.length) {
      return items;
    }
    const oldestFetchQueueDate = fetchQueueDates.sort()[0];

    const [rules, notRules] = R.partition(
      R.propEq("color", sourcesByName["S3-sääntö"]!.color),
      items,
    ) as [T[], T[]];
    const sorted = rules.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    const lowerBound = R.findIndex(
      R.propSatisfies(
        (x) => x.valueOf() > oldestFetchQueueDate!.valueOf(),
        "date",
      ),
      sorted,
    );

    return [
      ...notRules,
      ...sorted.slice(lowerBound === -1 ? -1 : Math.max(0, lowerBound - 1)),
    ];
  };

  return (
    <Content>
      <Header />
      <Space size="large" style={{ margin: "16px 0 24px 0" }}>
        <Title level={2} style={{ margin: 0 }}>
          Käsittelyhistoria
        </Title>
      </Space>
      <Paragraph>{tooltips.form.usage}</Paragraph>
      <DateContext.Provider
        value={{ localTime, toggleLocalTime: () => setLocalTime(!localTime) }}
      >
        <div className="invoice-details">
          <Form onFinish={handleSubmit} autoComplete="off">
            <Descriptions bordered column={2} size="small">
              <Descriptions.Item span={2} label="Laskun ID Aurorassa">
                <Space>
                  <Form.Item name="auroraId" initialValue={auroraId}>
                    <Input type="search" autoComplete="off" />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Laskun ID asiakkaalta">
                <Space>
                  <Form.Item name="invoiceId" initialValue={invoiceId}>
                    <Input type="search" autoComplete="off" />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Laskunumero">
                <Space>
                  <Form.Item name="invoiceNumber" initialValue={invoiceNumber}>
                    <Input type="search" autoComplete="off" />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Asiakkaan ID">
                <Space>
                  <Form.Item name="clientId" initialValue={clientId}>
                    <Input
                      type="search"
                      autoComplete="off"
                      className="num-no-arr"
                    />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Asiakkaan integraatioavain">
                <Space>
                  <Form.Item
                    name="integrationKey"
                    initialValue={integrationKey}
                  >
                    <Input type="search" autoComplete="off" />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <Tooltip mouseEnterDelay={0.5} title={tooltips.form.logTime}>
                    Lokien alkupvm{" "}
                    <FontAwesomeIcon
                      icon={faInfo}
                      color="#555"
                    ></FontAwesomeIcon>
                  </Tooltip>
                }
              >
                <Space>
                  <Form.Item
                    name="startDate"
                    initialValue={startDate ? dayjs(startDate) : null}
                  >
                    <DatePicker />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item label="Lokien loppupvm">
                <Space>
                  <Form.Item
                    name="endDate"
                    initialValue={endDate ? dayjs(endDate) : null}
                  >
                    <DatePicker />
                  </Form.Item>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item
                span={2}
                label={
                  <Tooltip
                    mouseEnterDelay={0.5}
                    title={tooltips.form.logGroups}
                  >
                    Lokiryhmät{" "}
                    <FontAwesomeIcon
                      icon={faInfo}
                      color="#555"
                    ></FontAwesomeIcon>
                  </Tooltip>
                }
              >
                <Form.Item name="logs" initialValue={logs.filter(Boolean)}>
                  <Select
                    mode="multiple"
                    placeholder="Valitse lokit"
                    popupMatchSelectWidth={false}
                    style={{ minWidth: "200px" }}
                  >
                    {Object.values(logOptions).map((option: any) => (
                      <Select.Option key={option.id} value={option.name}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item span={2}>
                <Button type="primary" htmlType="submit">
                  {"Hae"}
                  {nloaded < nloads && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ marginLeft: "0.5em", color: "white" }}
                          spin
                        />
                      }
                    />
                  )}
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </Form>
          <div className="invoice-change-list">
            <div className="invoice-change-row">
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setSortOrder(
                    sortOrder === SortOrder.Ascending
                      ? SortOrder.Descending
                      : SortOrder.Ascending,
                  )
                }
              >
                {`Aika ${sortOrder === SortOrder.Ascending ? "▲" : "▼"}`}
              </span>
              <span>Tyyppi</span>
              <span>Arvo</span>
            </div>
            <Select
              mode="multiple"
              placeholder="Suodata..."
              onChange={(value) => setFilters(value)}
              style={{ width: "100%", marginTop: "3px" }}
              value={filters}
            >
              {Object.values(sourceTypes).map(({ name, color }) => (
                <Select.Option key={name} value={color}>
                  <span className="circle-prefix" style={{ color }}>
                    ⬤
                  </span>{" "}
                  {name}
                </Select.Option>
              ))}
            </Select>
            {filterS3Rules(
              changes.filter(
                ({ color }) => !filters.length || filters.includes(color),
              ),
            )
              .sort(
                (a, b) =>
                  (a.date.getTime() - b.date.getTime()) *
                  (sortOrder === SortOrder.Ascending ? 1 : -1),
              )
              .map((props) => (
                <InvoiceChange key={props.key} props={{ ...props }} />
              ))}
          </div>
        </div>
      </DateContext.Provider>
    </Content>
  );
};

type KeysOfVariants<T> = T extends any ? keyof T : never;

const historyTypePrefix = {
  insert: <span style={{ color: "green" }}>+</span>,
  update: (
    <>
      <span style={{ color: "green" }}>+</span>
      <span style={{ color: "red" }}>-</span>
    </>
  ),
  delete: <span style={{ color: "red" }}>-</span>,
};

const sourceTypes: {
  name: string;
  prop: KeysOfVariants<InvoiceLog>;
  color: string;
  tooltips?: Record<string, string>;
  data: (_: any) => Omit<InvoiceChangeProps, "color">;
}[] = [
  {
    name: "invoice_history",
    prop: "changeLog",
    color: "#88aaff",
    data: (changeLog: InvoiceChangeLog) => ({
      date: new Date(changeLog.timestamp),
      text: (
        <>
          {historyTypePrefix[changeLog.type]} {changeLog.tableName}
        </>
      ),
      tooltip: tooltips.invoiceHistory[changeLog.type],
      json: {
        oldData: changeLog.oldValues,
        newData: changeLog.newValues,
      },
    }),
  },
  {
    name: "invoice_actions",
    prop: "auditLog",
    color: "#88ffaa",
    tooltips: tooltips.invoiceActions,
    data: (auditLog: InvoiceAuditLog) => ({
      date: new Date(auditLog.createdAt),
      text: auditLog.action,
      name: auditLog.userName ? (
        <p>{auditLog.userName}</p>
      ) : (
        <p style={{ color: "#555" }}>{auditLog.userKey}</p>
      ),
    }),
  },
  {
    name: "invoice_fetch_queue",
    prop: "fetchStatus",
    color: "#44ffff",
    tooltips: tooltips.invoiceFetchQueue,
    data: (fetchStatus: InvoiceFetchStatus) => ({
      date: new Date(fetchStatus.invoice.createdAt),
      text: fetchStatus.invoice.status,
      json: {
        data: fetchStatus.invoice,
      },
    }),
  },
  {
    name: "Cloudwatch",
    prop: "cloudwatchLog",
    color: "#ffdd88",
    tooltips: tooltips.cloudwatch,
    data: (cloudwatchLog: LogEntry) => ({
      date: new Date(cloudwatchLog.time),
      text: cloudwatchLog.log.split("/").at(-1)!,
      logs: () =>
        cloudwatchLog.logs
          ? [cloudwatchLog.logs!]
          : getLogs({
              timeRanges: [
                [
                  dayjs(cloudwatchLog.time),
                  dayjs(cloudwatchLog.time).add(20, "minutes"),
                ],
              ],
              logGroups: [cloudwatchLog.log.replace(/^\w+:/, "")],
              requestId: cloudwatchLog.requestId!,
            }),
    }),
  },
  {
    name: "S3-lasku",
    prop: "s3Invoice",
    color: "#b1b1b1",
    tooltips: tooltips.s3,
    data: (s3Invoice: S3VersionedObject) => ({
      date: new Date(s3Invoice.version.lastModified),
      text: "S3-lasku",
      file: s3Invoice,
    }),
  },
  {
    name: "S3-sääntö",
    prop: "s3ManualRule",
    color: "#32612d",
    tooltips: tooltips.s3,
    data: (manualRule: S3VersionedObject) => ({
      date: new Date(manualRule.version.lastModified),
      text: "S3-sääntö",
      file: manualRule,
    }),
  },
  {
    name: "invoices",
    prop: "invoice",
    color: "#a80874",
    tooltips: tooltips.invoice,
    data: (invoice: Invoice) => ({
      date: new Date(invoice.updatedAt),
      text: "Aurora-tietue",
      json: {
        data: invoice,
      },
    }),
  },
  {
    name: "clients",
    prop: "client",
    color: "#ff0060",
    data: (client: Client) => ({
      date: new Date(client.updatedAt),
      text: `${client.accountingSystem || client.system} · ${
        (tooltips.serviceTemplateAbbreviations as Record<string, string>)[
          client.serviceTemplate
        ]
      }`,
      tooltip: (tooltips.serviceTemplate as Record<string, string>)[
        client.serviceTemplate
      ],
      json: {
        data: client,
      },
    }),
  },
];

const sourcesByName = R.indexBy(R.prop("name"), sourceTypes);

const toChangePresentation = (log: InvoiceLog): InvoiceChangeProps => {
  for (const sourceType of sourceTypes) {
    if (sourceType.prop in log) {
      const res = {
        color: sourceType.color,
        ...sourceType.data((log as any)[sourceType.prop]), // must use `as any` due to loop
      };
      return {
        tooltip:
          typeof res.text === "string"
            ? sourceType.tooltips?.[res.text]
            : undefined,
        ...res,
      };
    }
  }
  throw new Error("Invalid props");
};

export default InvoiceDetails;
