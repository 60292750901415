/**
 * A memoizer that doesn't store errors.
 * @param fn Function whose return value should be memoized
 * @returns A memoized version of the function
 */
export const memoize = <R>(fn: () => R): (() => R) => {
  let cache: R | undefined;
  return () => {
    if (cache === undefined) {
      const res = fn();
      cache = res;
      if (res instanceof Promise) {
        res.catch((error) => {
          cache = undefined;
          throw error;
        });
      }
    }
    return cache;
  };
};

/**
 * Throws an error with the given message
 * @param msg The message to throw
 */
export function throw_(msg: string): never {
  throw new Error(msg);
}
