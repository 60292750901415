import axios from "axios";
import qs from 'qs';
import { apiBase, Collection, getAuthHeader } from ".";
import { Integration } from "../interfaces";

export interface IntegrationParameters {
    timespan?: string;
}

export const getIntegrations = async (params?: IntegrationParameters): Promise<Collection<Integration>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/integrations?${qs.stringify(params)}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}

export const ignoreIntegration = async (integrationKey: string): Promise<void> => {
    await axios({
        method: "DELETE",
        url: `${apiBase}/integrations/${integrationKey}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}

export const getDomains = async (companyName: string, country: string): Promise<string[]> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/domains?companyName=${companyName}&country=${country}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data || [];
}
