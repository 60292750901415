import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faFile,
  faFileCode,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFileText,
  IconDefinition,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { LogEntry } from "../api/logs";
import { Timestamp } from "./Timestamp";
import { Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { JsonView, JsonViewProps } from "./JsonView";
import { S3VersionedObject, S3ObjectVersion } from "../interfaces/InvoiceLog";
import { basename } from "path";
import { notifyOpaqueError } from "../api";
import { re } from "../util/regex";

export type InvoiceChangeProps = {
  color: string;
  date: Date;
  text: JSX.Element | string;
  tooltip?: string;
  name?: JSX.Element | null;
  json?: JsonViewProps;
  logs?: () => Iterable<LogEntry[]> | AsyncIterable<LogEntry[]>;
  file?: S3VersionedObject;
};

// checks if the buffer encodes UTF-8 string
const bufferIsUtf8 = (buf: Buffer) => {
  try {
    return (
      Buffer.from(buf.toString(), "utf8").toString("utf8") === buf.toString()
    );
  } catch (_) {}
  return false;
};

const passArray = <T extends any>(x: T): T & any[] => {
  return Array.isArray(x) ? x : ([] as T & any[]);
};

const getIconDefinition = (fileName: string): IconDefinition => {
  const extension = fileName.split(".").pop();

  switch (extension) {
    case "js":
    case "ts":
      return faFileCode;
    case "md":
      return faFileText;
    case "json":
      return faFileAlt;
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return faFileImage;
    case "pdf":
      return faFilePdf;
    default:
      return faFile;
  }
};

const FileContents = ({
  header,
  data,
}: {
  header: S3ObjectVersion;
  data: Buffer;
}) => {
  let isJson = false;
  const isText =
    [".xml", ".txt", ".json"].some((ext) => header.key.endsWith(ext)) ||
    bufferIsUtf8(data);
  let str: string | undefined = undefined;
  let json: Record<string, any> | undefined = undefined;
  let error: string | undefined = undefined;
  const files: { name: string; data: Buffer }[] = [
    { name: basename(header.key), data },
  ];
  try {
    if (isText) {
      str = data.toString("utf8");
      if (header.key.endsWith(".json") || !/\.\w{2,}$/.test(header.key)) {
        json = JSON.parse(str);
        isJson = true;
        files.push(
          ...passArray(json?.data?.attachments)
            .filter((a: any) => a.name && a.base64)
            .map((a: any) => ({
              name: a.name,
              data: Buffer.from(a.base64, "base64"),
            })),
        );
        if (json?.xml) {
          files.push({
            name: `${
              [json.id, json.version]
                .filter((x) => typeof x !== "undefined")
                .join("_") || "Unnamed"
            }.xml`,
            data: Buffer.from(json.xml, "base64"),
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
    error = String(e);
  }
  return (
    <div>
      <div className="file-list">
        {files.map(({ name, data }, i) => (
          <a
            key={i}
            href={URL.createObjectURL(new Blob([data]))}
            download={name}
          >
            {<FontAwesomeIcon icon={getIconDefinition(name)} />} {name}
          </a>
        ))}
      </div>
      {error && <p>{error}</p>}
      {isJson && <JsonView data={json!} />}
      {str && !isJson && <pre>{data.toString()}</pre>}
    </div>
  );
};

export const InvoiceChange: React.FC<{ props: InvoiceChangeProps }> = ({
  props,
}) => {
  const {
    color,
    date,
    text,
    tooltip,
    name,
    json,
    logs: getLogs,
    file: fileHeader,
  } = props;
  const [expand, setExpand] = useState(false);
  const [logs, setLogs] = useState<React.JSX.Element[]>([]);
  const [fileData, setFileData] = useState<Buffer>();
  const [loading, setLoading] = useState(false);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (!expand) return;
    if (getLogs) {
      if (logs.length) return;
      setLoading(true);
      setLogs([]);
      (async () => {
        try {
          for await (const log of getLogs()) {
            setLogs((prev) => [
              ...prev,
              ...log.map((entry, i) => {
                return (
                  <div key={prev.length + i} className="invoice-log-row">
                    <Timestamp date={new Date(entry.time)} short />
                    <div>{formatMessage(entry.message || "")}</div>
                  </div>
                );
              }),
            ]);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      })();
    } else if (fileHeader) {
      setLoading(true);
      fileHeader
        .data()
        .then(setFileData)
        .catch((e) => notifyOpaqueError(e))
        .finally(() => setLoading(false));
    }
  }, [expand]);

  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  return (
    <div
      className="invoice-change-container"
      style={{ "--accent-color": hexToRgb(color).join(", ") } as CSSProperties}
    >
      <div className="invoice-change-row">
        <Timestamp date={date} />
        {tooltip && (
          <Tooltip
            title={tooltip}
            mouseEnterDelay={0.5}
            overlayStyle={{
              /* https://github.com/ant-design/ant-design/issues/21224#issuecomment-774517752*/
              whiteSpace: "pre-line",
            }}
          >
            <p>
              {text}{" "}
              <FontAwesomeIcon icon={faInfo} color="#555"></FontAwesomeIcon>
            </p>
          </Tooltip>
        )}
        {!tooltip && <p>{text}</p>}
        {((json || getLogs || fileHeader) && (
          <button onClick={toggleExpand}>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined style={{ color: "black" }} spin />
                  }
                />
              </div>
            )}
            {!loading && <FontAwesomeIcon icon={expand ? faMinus : faPlus} />}
          </button>
        )) ||
          name}
      </div>
      {expand && json && <JsonView {...json} />}
      {expand && getLogs && <div className="invoice-log-list">{logs}</div>}
      {expand && fileData && (
        <FileContents header={fileHeader?.version!} data={fileData} />
      )}
    </div>
  );
};

const formatMessage = (msg: string) => {
  const jsonIdx = findTrailingJson(msg.trimEnd());
  if (jsonIdx !== null) {
    try {
      const pre = msg.slice(0, jsonIdx).trim();
      const obj = JSON.parse(msg.slice(jsonIdx));
      return (
        <>
          {pre && <p>{formatLinks(pre)}</p>}
          <JsonView data={obj} />
        </>
      );
    } catch (_) {}
  }
  return <p>{formatLinks(msg)}</p>;
};

const blockPat = re.g`
  (?:[^"{}\[\]]|"(?:[^"]|\\")*")* # quotation-aware content match
  ([{}\[\]])                      # block start or end
`;
const endBlocks = ["}", "]"];
const startBlocks = ["{", "["];

const findTrailingJson = (msg: string): number | null => {
  const segs = [...msg.matchAll(blockPat)];
  const last = segs.at(-1);
  if (!last || last.index + last[0].length !== msg.length) {
    return null;
  }
  const stack = [last[1]!];
  for (let i = segs.length - 2; i >= 0; --i) {
    const str = segs[i]![1]!;
    if (startBlocks.includes(str)) {
      if (stack.pop() !== endBlocks[startBlocks.indexOf(str)]) {
        return null;
      }
      if (stack.length === 0) {
        return segs[i]!.index + segs[i]![0].length - 1;
      }
    } else if (endBlocks.includes(str)) {
      stack.push(str);
    } else {
      return null;
    }
  }
  return null;
};

// https://gist.github.com/gruber/249502?permalink_comment_id=3944969#gistcomment-3944969
// modified to only match HTTP and HTTPS protocols
const linkPat = re.gi`
  \bhttps?                                                                    # protocol
  (?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+                          # domain
  (?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s\x60!()\[\]{};:'".,<>?«»“”‘’])    # path
`;

const formatLinks = (msg: string): JSX.Element[] => {
  let acc = "";
  let res: JSX.Element[] = [];
  let idx = 0;
  for (const match of msg.matchAll(linkPat)) {
    acc += msg.slice(idx, match.index);
    if (acc) {
      res.push(<Fragment key={res.length}>{acc}</Fragment>);
      acc = "";
    }
    res.push(
      <a key={res.length} href={match[0]} target="_blank">
        {match[0]}
      </a>,
    );
    idx = match.index + match[0].length;
  }
  acc += msg.slice(idx);
  if (acc.trim()) {
    res.push(<Fragment key={res.length}>{acc}</Fragment>);
  }
  return res;
};
