import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { CellChange, Column, DefaultCellTypes, Id, ReactGrid, Row, TextCell } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { Button, Divider, Progress, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, Navigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { AggregatedCompanyData, getAggregatedCompanyData } from "../api/aggregatedCompanyData";
import { getOrganizations } from "../api/organizations";
import { getTeams } from "../api/teams";
import { importDataState, importIntegrations } from "../atoms";
import Header from "../components/Header";
import { ClientInput } from "../interfaces";
import { serviceTemplates } from "../util/constants";

type ColumnDef = Column & Partial<DefaultCellTypes> & { title: string } & Pick<TextCell, "validator" | "renderer">;

const defaultColumns: ColumnDef[] = [
    {
        title: 'Asiakas',
        columnId: 'name',
        width: 300,
        resizable: true,
        type: 'text',
        renderer: (name: string) => <a style={{ color: 'black' }} href={`https://www.finder.fi/search?what=${name}`} target="finderWindow">{name}</a>
    },
    {
        title: 'Organisaatio',
        columnId: 'organization',
        width: 100,
        resizable: true,
        type: 'text',
    },
    {
        title: 'Tiimi',
        columnId: 'team',
        width: 100,
        resizable: true,
        type: 'text',
    },
    {
        title: 'Domain',
        columnId: 'domain',
        width: 300,
        resizable: true,
        type: 'text',
    },
    {
        title: 'Päätoimiala',
        columnId: 'mainLineOfBusiness',
        width: 250,
        resizable: true,
        type: 'text',
    },
    {
        title: 'TOL',
        columnId: 'tol',
        width: 60,
        type: 'text'
    },
    {
        title: 'Palvelumalli',
        columnId: 'serviceTemplate',
        width: 180,
        resizable: true,
        type: 'text',
        validator: (value: string) => value ? Object.values(serviceTemplates).map(st => st.name).includes(value) : true,
    },
    {
        title: 'IA päälle',
        columnId: 'activateProgressiveAutomation',
        width:70,
        type: 'checkbox',
    },
    {
        title: 'Tilit',
        columnId: 'fetchOnlyAccounts',
        width: 60,
        resizable: true,
        type: 'text',
    },
    {
        title: 'Dimensiointi',
        columnId: 'dimensions',
        width:90,
        type: 'checkbox',
    },
    {
        title: 'Aloituspvm',
        columnId: 'startDateForFetchingInvoices',
        width: 110,
        type: 'date'
    },
    {
        title: 'Y-tunnus',
        columnId: 'businessId',
        width: 90,
    },
    {
        title: 'Järjestelmä',
        columnId: 'system',
        width: 90,
    },
    {
        title: 'Integraatioavain',
        columnId: 'integrationKey',
        width: 260,
    },
];

const importViewColumns = atom<ColumnDef[]>({
    key: 'importViewColumns',
    default: defaultColumns
});

const ImportIntegrations = () => {
    const [integrations = [], setIntegrations] = useRecoilState(importIntegrations);
    const [_, setImportData] = useRecoilState(importDataState);
    const [fetchAggregatedData, percentage, fetchingCompanyData] = useAggregatedData(integrations);
    const [columns, setColumns] = useRecoilState(importViewColumns);
    const organizationsQuery = useQuery('organizations', () => getOrganizations());
    const teamsQuery = useQuery('teams', () => getTeams().then(c => c.items));
    const [highlightedRows, setHighlightedRows] = useState<Id[]>([]);

    const getCellFields = useCallback((integration: ClientInput, col: ColumnDef): DefaultCellTypes => {
        const cell = { type: col.type } as DefaultCellTypes;

        switch (cell.type) {
            case 'date':
                return {
                    ...cell,
                    date: integration[col.columnId] ? new Date(integration[col.columnId]) : undefined,
                };
            case 'checkbox':
                return {
                    ...cell,
                    checked: integration[col.columnId] ?? false,
                };
            default:
                const result: TextCell = {
                    type: 'text',
                    text: integration[col.columnId] ?? '',
                    validator: col.validator,
                    renderer: col.renderer,
                };

                if (col.columnId === 'organization' && organizationsQuery.data) {
                    result.validator = (value: string) => organizationsQuery.data.items.find(o => o.name === value) ? true : false;
                } else if (col.columnId === 'team' && organizationsQuery.data && teamsQuery.data) {
                    result.validator = (value: string): boolean => {
                        if (!integration.organization) return false;
                        const organization = organizationsQuery.data.items.find(o => o.name === integration.organization);
                        if (!organization) return false;
                        const team = teamsQuery.data.find(t => t.name === value && t.organizationId === organization.id);
                        return team ? true : false;
                    };
                }

                return result;
        }
    }, [organizationsQuery, teamsQuery]);

    const rows: Row[] = useMemo(() => [
        { rowId: 'header', cells: columns.map(col => ({ type: 'header', text: col.title })) },
        ...integrations.map(integration => ({
            rowId: integration.integrationKey,
            cells: columns.map(col => getCellFields(integration, col)),
        }))], [integrations, columns, getCellFields]);

    const getIntegrationFields = useCallback((columnId: keyof ClientInput, cell: DefaultCellTypes): Partial<ClientInput> => {
        switch (cell.type) {
            case 'text':
                return { [columnId]: cell.text };
            case 'date':
                return { [columnId]: cell.date ? cell.date.toDateString() : undefined };
            case 'checkbox':
                return { [columnId]: cell.checked };
            default:
                return {};
        }
    }, []);

    const applyCellChanges = (prev: ClientInput[], changes: CellChange[]): ClientInput[] => {
        changes.filter(change => !['system', 'integrationKey', 'finder'].includes(change.columnId as string)).forEach(change => {
            prev = prev.map(integration => integration.integrationKey === change.rowId ? {
                ...integration,
                ...getIntegrationFields(change.columnId, change.newCell),
            } : integration);
        });
        return [...prev];
    };

    const handleCellsChanged = useCallback((changes: CellChange[]) => {
        setIntegrations(prev => applyCellChanges(prev, changes));
    }, [setIntegrations]);

    const fetchCompanyData = useCallback(() => {
        fetchAggregatedData(
            (data, index) => {
                setIntegrations(prev => {
                    return prev.map((client, i) => i == index ? {
                        ...client,
                        domain: client.domain || data.domain,
                        tol: client.tol || data.tol,
                        mainLineOfBusiness: client.mainLineOfBusiness || data.industry,
                        businessId: client.businessId || businessIdToVatCode(data.businessId, data.country ?? 'FI'),
                    } as ClientInput : client);
                });
            }
        );
    }, [fetchAggregatedData, setIntegrations]);

    const handleColumnResize = useCallback((columnId: Id, width: number) => {
        setColumns(prev => prev.map(col => col.columnId === columnId ? { ...col, width } : col));
    }, [setColumns]);

    if (!integrations.length) {
        return (
            <Navigate to="/integrations"/>
        );
    }

    return (
        <Content>
            <Header />
            <Space align="center" size="large" style={{ margin: '16px 0 24px 0', width: '100%' }}>
                <Title style={{ margin: 0 }} level={2}>Kytkentöjen tuonti</Title>
            </Space>
            <Space style={{ marginBottom: 16 }} split={<Divider type="vertical" />}>
                <Button danger icon={<ArrowLeftOutlined />} onClick={() => setImportData(null)}>Peruuta tuonti</Button>
                <Space direction="vertical">
                    <Button loading={fetchingCompanyData} onClick={fetchCompanyData}>Hae yritysten tiedot</Button>
                    <Progress style={{ width: 300 }} percent={percentage} />
                </Space>
                <Link to="confirm">
                    <Button>Jatka <ArrowRightOutlined /></Button>
                </Link>
            </Space>
            <div style={{ maxWidth: '100%', overflowX: 'auto', overscrollBehaviorX: 'contain' }}>
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    enableFillHandle
                    enableRangeSelection
                    onCellsChanged={handleCellsChanged}
                    onColumnResized={handleColumnResize}
                    stickyLeftColumns={1}
                    onFocusLocationChanged={({ rowId }) => setHighlightedRows([rowId])}
                    highlights={highlightedRows.map(rowId => ({ rowId, columnId: 'name', borderColor: '#6366f1'}))}
                />
            </div>
        </Content>
    );
};

export default ImportIntegrations;

const vatCodeToBusinessId = (vatCode: string): string => {
    return vatCode.substring(2, 9) + '-' + vatCode.substring(9, 10);
}

const businessIdToVatCode = (businessId: string, country: string): string => {
    return country + businessId.substring(0, 7) + businessId.substring(8, 9);
};

const useAggregatedData = (integrations: ClientInput[]): [(callback: (data: AggregatedCompanyData, index: number) => void) => Promise<void>, number, boolean] => {
    const [totalToFetch, setTotalToFetch] = useState(0);
    const [fetchedCount, setFetchedCount] = useState(0);
    const [fetching, setFetching] = useState(false);

    const getData = useCallback(async (callback: (data: AggregatedCompanyData, index: number) => void) => {
        setTotalToFetch(integrations.length);
        setFetchedCount(0);
        setFetching(true);
        for (const index of integrations.keys()) {
            const integration = integrations[index]!;
            let businessId = vatCodeToBusinessId(integration.businessId!);
            let response: AggregatedCompanyData;



            // If there is no businessId, attempt to fetch it
            if (businessId === '-') {
                businessId = '';
            }

            try {
                response = await getAggregatedCompanyData(integration.name, businessId);
            } catch {
                setFetchedCount(count => count + 1);
                continue;
            }
            callback(response, index);
            setFetchedCount(count => count + 1);
        }
        setFetching(false);
    }, [integrations]);

    return [getData, Math.round(fetchedCount / totalToFetch * 100), fetching];
};
