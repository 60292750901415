import axios from "axios";
import qs from "qs";
import * as R from "ramda";
import { apiBase, getAuthHeader } from ".";
import { Client } from "../interfaces";
import { S3ObjectVersion, S3VersionedObject } from "../interfaces/InvoiceLog";

export const getInvoiceS3Prefix = (
  client: Client,
  invoiceId?: string
): string | null => {
  if (!invoiceId) {
    return null;
  }
  return `${client.system}/${
    client.integrationKey || client.id
  }/original/${invoiceId}_invoice`;
};

export async function* getInvoiceS3Versions(
  prefix: string
): AsyncGenerator<{ s3Invoice: S3VersionedObject }[]> {
  const res = await axios.get(
    `${apiBase}/s3/invoiceVersions?${qs.stringify({ prefix })}`,
    {
      headers: {
        Authorization: await getAuthHeader(),
      },
    }
  );
  yield res.data.data.map((obj: any) => ({
    s3Invoice: {
      version: obj,
      data: R.memoizeWith(
        () => "",
        () => getS3ObjectBody(obj)
      ),
    },
  }));
}

export async function* getManualRuleS3Versions(
  prefix: string
): AsyncGenerator<{ s3ManualRule: S3VersionedObject }[]> {
  const res = await axios.get(
    `${apiBase}/s3/manualRuleVersions?${qs.stringify({ prefix })}`,
    {
      headers: {
        Authorization: await getAuthHeader(),
      },
    }
  );
  yield (res.data.data ?? []).map((obj: any) => ({
    s3ManualRule: {
      version: obj,
      data: R.memoizeWith(
        () => "",
        () => getS3ObjectBody(obj)
      ),
    },
  }));
}

export const getS3ObjectBody = async (
  version: S3ObjectVersion
): Promise<Buffer> => {
  const response = await fetch(version.url);

  if (!response.ok) {
    throw new Error(`Error fetching S3 object body: ${response.status}`);
  }

  return Buffer.from(await response.arrayBuffer());
};
