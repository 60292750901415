import { Alert, Button, Card, Descriptions, Form, InputNumber, List, Modal, Radio, Select, Space, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getClientDimensions } from "../api/clients";
import { getMLModelProcessors } from "../api/mlModels";
import { MLModelTrainingRequest } from "../interfaces/mlModel";
import { renderDate } from "../util/datetime";
import { Dimension } from '../interfaces/client';

/**
 * Add hard-coded "actions" that can be predicted as a dimension
 * 
 * These action are not normal dimensions as they may be moved from the dimensionItemValues
 * to different place on the invoice. E.g. fai_vatStatus is moved from the dimensionItemValues
 * into the row's vatStatus field.
 * 
 * All hard-coded actions have prefix "fai_" and then a hard-coded specifier.
 */
const getHardCodedDimensions = (): Dimension[] => {
    return [{
        description: 'ALV-status',
        id: 'fai_vatStatus',
        items: [],
        name: 'ALV-status',
    }]
}

const TrainingRequestForm = ({ clientId, loading, onSubmit }: { clientId: number, loading?: boolean, onSubmit: (req: MLModelTrainingRequest) => void }) => {
    const [label, setLabel] = useState("");
    const [processorsListOpen, setProcessorsListOpen] = useState(false);
    const processorsQuery = useQuery('processors', () => getMLModelProcessors());
    const dimensionsQuery = useQuery(['client', clientId, 'dimensions'], () => getClientDimensions(clientId));


    const dimensionsArray = [...(dimensionsQuery.data?.dimensions ?? []), ...getHardCodedDimensions() ].filter(Boolean);

    return (
        (<Form
            initialValues={{
                label: 'ACCOUNT'
            }}
            labelCol={{ span: 8 }}
            onFinish={(values: MLModelTrainingRequest) => {
                if (values.label === 'ACCOUNT') {
                    values.dimensionId = null;
                }
                onSubmit(values)
            }}
        >
            <Form.Item label="Ennustettava tietue" name="label" rules={[{ required: true, message: "Pakollinen kenttä" }]}>
                <Radio.Group onChange={
                    e => setLabel(e.target.value)
                } buttonStyle="solid">
                    <Radio.Button value="ACCOUNT">Tili</Radio.Button>
                    <Radio.Button value="DIMENSION">Dimensio</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Dimensio"
                name="dimensionId"
            >
                <Select
                    placeholder="Valitse dimensio"
                    loading={dimensionsQuery.isLoading}
                    allowClear
                    disabled={label != "DIMENSION"}
                >
                    {dimensionsArray.map(dimension => (
                        <Select.Option key={dimension.id} value={dimension.id}>
                            {dimension.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Prosessori" name="processorId" extra={<Button style={{ marginLeft: -12 }} type="link" htmlType="button" onClick={() => setProcessorsListOpen(true)}>Avaa lista prosessoreista</Button>}>
                <Select
                    placeholder="Automaattinen"
                    loading={processorsQuery.isLoading}
                    allowClear
                >
                    {processorsQuery.data?.map(processor => (
                        <Select.Option key={processor.id} value={processor.id}>
                            {processor.id} - {processor.processor} - {processor.suggestedLabel} - {processor.suggestedCountry}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Modal
                title="Saatavilla olevat prosessorit"
                open={processorsListOpen}
                onCancel={() => setProcessorsListOpen(false)}
                footer={null}
                width={1000}
            >
                <List
                    loading={processorsQuery.isLoading}
                    dataSource={processorsQuery.data}
                    grid={{ gutter: 16, column: 2 }}
                    renderItem={processor => (
                        <List.Item>
                            <Card
                                hoverable
                                style={{ borderColor: processor.isDeprecated ? 'gold' : undefined, minHeight: 250 }}
                                title={`Prosessori ${processor.id} - ${processor.processor}`}
                                size="small"
                                extra={<Space>
                                    <Tooltip title="Suositeltu ennustuskohde">
                                        <Tag>{processor.suggestedLabel}</Tag>
                                    </Tooltip>
                                    <Tooltip title="Suositeltu kohdemaa">
                                        <img src={`https://flagcdn.com/w20/${processor.suggestedCountry.toLowerCase()}.png`} />
                                    </Tooltip>
                                </Space>}
                            >
                                <p>{processor.description}</p>
                                <Descriptions layout="vertical" size="small">
                                    <Descriptions.Item label="Generaattori">{processor.generator}</Descriptions.Item>
                                    <Descriptions.Item label="Julkaistu">{renderDate(processor.initialRelease)}</Descriptions.Item>
                                    <Descriptions.Item label="Elinkaari päättyy">{renderDate(processor.plannedEndOfLife)}</Descriptions.Item>
                                </Descriptions>
                                {processor.isDeprecated && <Tooltip title={processor.deprecationNotice}>
                                    <Alert
                                        style={{ marginTop: 8 }}
                                        showIcon
                                        type="warning"
                                        message="Prosessori on vanhentunut"
                                    />
                                </Tooltip>}
                            </Card>
                        </List.Item>
                    )}
                />
            </Modal>
            <Form.Item label="Prioriteetti" name="priority">
                <InputNumber />
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
                <Button loading={loading} style={{ float: 'right' }} type="primary" htmlType="submit">Lähetä koulutuspyyntö</Button>
            </Form.Item>
        </Form>)
    );
};

export default TrainingRequestForm;
