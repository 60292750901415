import React from "react";
import { Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import Header from "../components/Header";
import Title from "antd/lib/typography/Title";

const Analytics = () => {
    return (
        <Content style={{ display: 'flex', flexDirection: 'column' }}>
            <Header />
            <Space style={{ margin: '16px 0 8px 0' }}>
                <Title level={2}>Analytiikka</Title>
            </Space>
            <iframe
                style={{ border: 'none', flex: 1, minHeight: 'fit-content' }}
                width="100%"
                height="1600px"
                src="https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/314688591661/dashboards/886d2020-12ab-4b47-97bb-6a0e3466a716?directory_alias=fabricai">
            </iframe>
        </Content>
    );
};

export default Analytics;
