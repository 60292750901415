import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from 'react';
import Header from "../components/Header";
import Title from "antd/lib/typography/Title";
import { message, Space } from "antd";
import UIMessageForm from "../forms/UIMessageForm";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { createUIMessage } from "../api";
import BackLink from "../components/BackLink";

function NewUIMessage() {
    const navigate = useNavigate();
    const createUIMessageMutation = useMutation(createUIMessage);

    useEffect(() => {
        if (createUIMessageMutation.isSuccess) {
            message.success('Tiedote tallennettu');
            navigate('/uiMessages');
        }
    }, [navigate, createUIMessageMutation.isSuccess]);

    return (
        <Content>
            <Header />
            <Space style={{ marginBottom: 24 }} align="center">
                <BackLink to="/uiMessages" />
                <Title level={2} style={{ margin: 0 }}>
                    Uusi UI-tiedote
                </Title>
            </Space>
            <UIMessageForm onSubmit={createUIMessageMutation.mutate} submitting={createUIMessageMutation.isLoading} />
        </Content>
    );
};

export default NewUIMessage;
