import { Space, Table, Popconfirm, Button } from "antd";
import React from "react";
import { ClientTeam, Team } from "../interfaces";
import Identifier from "./Identifier";

export interface TeamListProps {
    teams?: ClientTeam[];
    onRemove?: (team: Team) => void;
    removingTeam?: number;
}

const TeamList = (props: TeamListProps) => {
    return (
        <Table
            bordered
            pagination={false}
            rowKey="id"
            style={{ marginTop: 16 }}
            columns={[
                { title: 'Tiimi', dataIndex: 'name', key: 'name' },
                { title: 'ID', dataIndex: 'id', key: 'id', render: (id: number) => <Identifier code={false}>{id}</Identifier> },
                { title: 'Firebasen ID', dataIndex: 'key', key: 'key', render: (key: string) => <Identifier>{key}</Identifier> },
                {
                    title: 'Toiminnot', key: 'actions', align: 'center', width: 150, render: (team: Team) => (
                        <Space size="middle">
                            <Popconfirm
                                title="Oletko varma?"
                                okText="Kyllä"
                                cancelText="Ei"
                                onConfirm={props.onRemove != undefined ? () => props.onRemove!(team) : undefined}
                            >
                                <Button loading={props.removingTeam === team.id} type="text" size="small" danger>Poista tiimistä</Button>
                            </Popconfirm>
                        </Space>
                    )
                },
            ]}
            dataSource={props.teams}
        />
    );
};

export default TeamList;
