export const renderDate = (date?: Date | string): string => {
    if (!date) {
        return '-';
    };

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return date.toLocaleDateString('fi-FI');
}

export const renderDateTime = (date?: Date | string | null): string => {
    if (!date) {
        return '-';
    };

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return date.toLocaleString('fi-FI');
}
