import React from "react";

export const DateContext = React.createContext({
  localTime: false,
  toggleLocalTime: () => {},
});

export const Timestamp: React.FC<{ date: Date; short?: boolean }> = ({
  date,
  short,
}) => {
  const { localTime, toggleLocalTime } = React.useContext(DateContext);
  const [dateStr, timeZone] = (() => {
    try {
      const str = (short ? date.toLocaleTimeString : date.toLocaleString).bind(
        date
      )("fi-Fi", {
        timeZone: localTime ? undefined : "UTC",
        timeZoneName: !short ? "short" : undefined,
      });
      if (short) {
        return [str, ""];
      }
      const parts = str.split(" ");
      return [parts.slice(0, -1).join(" "), parts[parts.length - 1]];
    } catch (e) {
      console.error(e);
      return ["", ""];
    }
  })();
  return (
    <p
      style={dateStr ? { cursor: "pointer" } : { color: "gray" }}
      onClick={dateStr ? toggleLocalTime : undefined}
    >
      {dateStr || "Ei aikatietoa"}
      {timeZone && <span style={{ color: "#555" }}> {timeZone}</span>}
    </p>
  );
};
