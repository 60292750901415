import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Collapse, Descriptions, Divider, message, Popconfirm, Timeline, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notifyError } from '../api';
import { getClientDimensions } from '../api/clients';
import { deleteMLModel, requestModelRetraining } from '../api/mlModels';
import { MLModel } from '../interfaces/mlModel';
import { renderDateTime } from '../util/datetime';
import Identifier from './Identifier';

const statusColors: any = {
    'COMPLETED': 'green',
    'DEPLOYED': 'green',
    'FAILED': 'red',
    'INFO': 'gray',
    'PENDING': 'gray',
    'PRE_PROCESSING': 'blue',
    'RUNNING': 'blue',
};

const customDots: any = {
    'DEPLOYED': <CheckCircleOutlined />,
    'FAILED': <CloseCircleOutlined />,
};

const MLModelDetails = (props: { model: MLModel, clientId: number }) => {
    const { model, clientId } = props;
    const queryClient = useQueryClient();
    const dimensionsQuery = useQuery(['client', clientId, 'dimensions'], () => getClientDimensions(clientId));
    const retrainMutation = useMutation((id: number) => requestModelRetraining(clientId, id), {
        onSuccess: () => {
            message.success('Koulutuspyyntö lähetetty');
        },
        onError: notifyError,
    });

    const deleteMutation = useMutation((id: number) => deleteMLModel(clientId, id), {
        onSuccess: () => {
            message.success('Malli poistettu');
            queryClient.invalidateQueries(['client', clientId]);
        },
        onError: notifyError,
    });

    const logs = model.logs?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) || [];

    const deleteAction = <Popconfirm title="Oletko varma?" onConfirm={() => deleteMutation.mutate(model.trainingId)}>
        <Button loading={deleteMutation.isLoading} danger type="text" icon={<DeleteOutlined />} key="delete" />
    </Popconfirm>

    const cardActions = model.status === 'FAILED' ? [
        <Tooltip key="retrain" title="Pyydä uudelleenkoulutusta">
            <Button loading={retrainMutation.isLoading} type="text" icon={<SyncOutlined />} key="retrain" onClick={() => retrainMutation.mutate(model.trainingId)} />
        </Tooltip>,
        deleteAction
    ] : [deleteAction];

    return (
        <Card title={<>
            Malli #{model.trainingId}
            <Tooltip title={model.status}>
                <Badge style={{ marginLeft: 8 }} status={statusColors[model.status]} dot />
            </Tooltip>
        </>} extra={<Text type="secondary">{model.label}</Text>} actions={cardActions}>
            <Descriptions bordered size="small" column={2} layout="vertical">
                <Descriptions.Item span={2} label="Mallin ID">
                    <Identifier>
                        {model.modelId}
                    </Identifier>
                </Descriptions.Item>
                <Descriptions.Item label="Generaattori">{model.modelGenerator}</Descriptions.Item>
                <Descriptions.Item label="Prosessori">
                    <Identifier>
                        {model.preprocessor}
                    </Identifier>
                </Descriptions.Item>
                <Descriptions.Item label="Koulutuksen ID">
                    <Identifier code={false}>
                        {model.trainingId}
                    </Identifier>
                </Descriptions.Item>
                {model.label === 'DIMENSION' && <Descriptions.Item label="Dimensio">
                    {model.dimensionId}: {dimensionsQuery.data?.dimensions.find(
                        dimension => dimension.id === model.dimensionId
                    )?.name}
                </Descriptions.Item>}
            </Descriptions>
            <Divider />
            {model.logs && (
                <Collapse>
                    <Collapse.Panel header="Lokit" key="1">
                        <Timeline mode="left">
                            {logs.map(log => (
                                <Timeline.Item dot={customDots[log.status]} color={statusColors[log.status]} key={log.id}>
                                    {(log.status === 'PENDING' && !log.message) ? 'Model has been created and is pending training' : log.message}
                                    <Text type="secondary"> - {renderDateTime(log.createdAt)}{log.requester ? ` by ${log.requester}` : ''}
                                    </Text>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Collapse.Panel>
                </Collapse>
            )}
        </Card>
    )
};

export default MLModelDetails;
