import { Alert, Button, Checkbox, Progress, Space, Spin, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { APIError } from "../api";
import { createClient, updateClientAutomation } from "../api/clients";
import { createMLModel } from "../api/mlModels";
import { getOrganizations } from "../api/organizations";
import { getTeams } from "../api/teams";
import { importDataState, importIntegrations } from "../atoms";
import BackLink from "../components/BackLink";
import Header from "../components/Header";
import { Client, ClientInput, CollectionOrganization, MLModel, Team } from "../interfaces";
import { serviceTemplates } from "../util/constants";

type ClientWithErrors = Partial<Client> & { errors?: { field: string, error: string }[], activateProgressiveAutomation?: boolean };

const ImportIntegrationsConfirmation = () => {
    const [integrations = []] = useRecoilState(importIntegrations);
    const [_, setImportData] = useRecoilState(importDataState);
    const organizationsQuery = useQuery('organizations', () => getOrganizations());
    const teamsQuery = useQuery('teams', () => getTeams().then(c => c.items));
    const [active, setActive] = useState(false);
    const [trainModels, setTrainModels] = useState(false);
    const [importStatus, setImportStatus] = useState<{ finished: boolean, error: boolean }>({ finished: false, error: false });

    const [createClientLog, setCreateClientLog] = useState<string[]>([]);

    const appendLog = (log: string) => setCreateClientLog((prev) => [...prev, `[${new Date().toISOString()}] ${log}`]);

    const trainModelMutation = useMutation((clientId: number) => createMLModel(clientId, { label: 'ACCOUNT', dimensionId: null, priority: null }), {
        onSuccess: (model: MLModel, clientId: number) => appendLog(`Koulutuspyyntö lähetetty asiakkaalle ${clientId} / ID: (${model.trainingId})`),
        onError: (error: APIError, clientId: number) => {
            appendLog(`Koulutuspyynnön luonti epäonnistui asiakkaalle ${clientId}: ${error.message}: ${error.response?.data?.error}`);
            setImportStatus(prev => ({ ...prev, error: true }));
        },
    });

    const createClientMutation = useMutation((client: Partial<Client>) => createClient(client), {
        onSuccess: (client: Client) => appendLog(`Asiakas '${client.name}' luotu onnistuneesti / ID: (${client.id})`),
        onError: (error: APIError, client) => {
            appendLog(`Asiakkaan '${client.name}' luonti epäonnistui: ${error.message}: ${error.response?.data?.error}`);
            setImportStatus(prev => ({ ...prev, error: true }));
        },
    });

    const activateProgressiveAutomationMutation = useMutation((variables: { clientId: number, automationId: number }) => {
        return updateClientAutomation(variables.clientId, variables.automationId, { status: 'ACTIVE' });
    }, {
        onSuccess: (_, variables) => appendLog(`IA:n aktivointi onnistui asiakkaalle ${variables.clientId} / ID: (${variables.automationId})`),
        onError: (error: APIError, variables) => {
            appendLog(`IA:n aktivointi epäonnistui asiakkaalle ${variables.clientId}: ${error.message}: ${error.response?.data?.error}`);
            setImportStatus(prev => ({ ...prev, error: true }));
        },
    })

    const clientsToCreate = useMemo<ClientWithErrors[]>(() => {
        if (organizationsQuery.isLoading || teamsQuery.isLoading) {
            return [];
        }

        return validateIntegrations(integrations, organizationsQuery.data!.items, teamsQuery.data!, active);
    }, [organizationsQuery, teamsQuery, integrations, active]);

    useEffect(() => {
        if (importStatus.finished && !importStatus.error) {
            setImportData(null);
        }
    }, [importStatus, setImportData]);

    const errors = clientsToCreate.filter(c => c.errors !== undefined).flatMap(c => c.errors!.map(e => ({ ...e, client: c.name })));

    const handleStartImport = async () => {
        for (const client of clientsToCreate){
            const created = await createClientMutation.mutateAsync(client);
            if (created && trainModels) {
                await trainModelMutation.mutateAsync(created.id);
            }

            const growlithe = created.automations.approval?.find(a => a.type === 'GROWLITHE');
            console.log(client.activateProgressiveAutomation, created, growlithe)
            if (client.activateProgressiveAutomation && created && growlithe) {
                await activateProgressiveAutomationMutation.mutateAsync({ clientId: created.id, automationId: growlithe.id });
            }
        }
        setImportStatus(prev => ({ ...prev, finished: true }));
    };

    const importStarted = createClientMutation.isLoading || trainModelMutation.isLoading || createClientLog.length > 0;

    if (integrations.length === 0 && !importStarted) {
        return (
            <Navigate to="/integrations" />
        );
    }

    if (organizationsQuery.isLoading || teamsQuery.isLoading) {
        return (
            <Spin size="large" />
        );
    }

    return (
        <Content>
            <Header />
            <Space size="middle" align="center" style={{ margin: '16px 0 24px 0', width: '100%' }}>
                <BackLink to="/integrations/import" />
                <Title style={{ margin: 0 }} level={2}>Vahvista tuonti</Title>
            </Space>
            {errors.length > 0 ? (
                <Space direction="vertical" size="large">
                    <Alert
                        type="error"
                        message="Virheellisiä asiakkaita!"
                        description="Asiakkaita ei voi luoda, sillä osalla asiakkaista on virheellisiä tietoja. Ole hyvä, ja palaa korjaamaan virheelliset asiakkaat. Virheelliset tiedot on listattu alla."
                    />
                    <Table
                        pagination={false}
                        bordered
                        size="small"
                        columns={[
                            { title: 'Asiakas', key: 'client', dataIndex: 'client' },
                            { title: 'Kenttä', key: 'field', dataIndex: 'field' },
                            { title: 'Virhe', key: 'error', dataIndex: 'error' },
                        ]}
                        dataSource={errors.map(e => ({ ...e, key: e.client + e.field }))}
                    />
                </Space>
            ) : (
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <Checkbox disabled={importStarted} checked={active} onChange={e => setActive(e.target.checked)}>Aseta asiakkaat aktiiviseksi</Checkbox>
                    <Checkbox disabled={importStarted} checked={trainModels} onChange={e => setTrainModels(e.target.checked)}>Kouluta tiliöntimallit</Checkbox>
                    <Button
                        disabled={importStarted}
                        type="primary"
                        onClick={handleStartImport}
                    >Tuo {clientsToCreate.length} asiakasta FabricAI:hin</Button>
                    {(importStatus.finished && !importStatus.error) && (
                        <Alert icon type="success" message="Tuonti onnistui!" description="Asiakkaat on luotu onnistuneesti. Voit nyt poistua tältä sivulta" />
                    )}
                    {(importStatus.finished && importStatus.error) && (
                        <Alert type="warning" message="Tuonnissa tapahtui virheitä!" description="Kopioi alla oleva loki talteen ja ota yhteyttä devaukseen." />
                    )}
                    {importStarted && (
                        <>
                            <Progress percent={Math.round(createClientLog.length / clientsToCreate.length * (trainModels ? 50 : 100))} />
                            <Paragraph>
                                <pre style={{ maxHeight: 800, overflowY: 'auto' }}>
                                    {createClientLog.join('\n')}
                                </pre>
                            </Paragraph>
                        </>
                    )}
                </Space>
            )}
        </Content>
    );
};

export default ImportIntegrationsConfirmation;

const validateIntegrations = (integrations: ClientInput[], organizations: CollectionOrganization[], teams: Team[], active?: boolean): ClientWithErrors[] => {
    return integrations.map(integration => {
        const organization = integration.organization ? organizations.find(o => o.name === integration.organization) : null;
        const team = integration.team ? teams.filter(team => team.organizationId === organization?.id).find(t => t.name === integration.team) : null;

        const errors = [];

        // Unknown organization
        if (organization === undefined) {
            errors.push({ field: 'Organisaatio', error: 'Organisaatiota ei ole olemassa' });
        }

        // Unknown team or trying to set team without org
        if (organization === null && team !== null) {
            errors.push({ field: 'Tiimi', error: 'Tiimiä ei voi asettaa ilman organisaatiota' });
        } else if (team === undefined) {
            errors.push({ field: 'Tiimi', error: 'Tiimiä ei ole olemassa' });
        }

        // Invalid servicetemplate
        const allowedTemplates = Object.values(serviceTemplates).map(st => st.name);
        if (integration.serviceTemplate && !allowedTemplates.includes(integration.serviceTemplate)) {
            errors.push({ field: 'Palvelumalli', error: 'Palvelumalli on virheellinen, sallitut arvot ovat: ' + allowedTemplates.join(', ') });
        }

        // No startDate
        if (!integration.startDateForFetchingInvoices) {
            errors.push({ field: 'Aloituspvm', error: 'Aloituspvm ei voi olla tyhjä' });
        }

        // No businessId
        if (!integration.businessId) {
            errors.push({ field: 'Y-tunnus', error: 'Y-tunnus ei voi olla tyhjä' });
        }

        // No name
        if (!integration.name) {
            errors.push({ field: 'Nimi', error: 'Nimi ei voi olla tyhjä' });
        }

        console.log(integration.activateProgressiveAutomation)

        const client: ClientWithErrors = {
            activateProgressiveAutomation: integration.activateProgressiveAutomation,
            businessId: integration.businessId,
            country: integration.system === 'tripletex' ? 'NO' : 'FI',
            domain: integration.domain,
            filters: {
                dimensions: !!integration.dimensions,
                fetchOnlyAccounts: integration.fetchOnlyAccounts?.split(',').map(s => s.trim()).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}) || null,
                fai_circulate_list: false,
            },
            integrationKey: integration.integrationKey,
            mainLineOfBusiness: integration.mainLineOfBusiness,
            name: integration.name,
            organizationId: organization?.id || null,
            serviceTemplate: Object.keys(serviceTemplates).find(st => serviceTemplates[st]?.name === integration.serviceTemplate)!,
            startDateForFetchingInvoices: integration.startDateForFetchingInvoices,
            status: active ? 'ACTIVE' : 'INACTIVE',
            system: integration.system,
            tol: integration.tol,
            teams: team ? [{ id: team.id, name: team.name }] : [],
            errors,
        };
        return client;
    });
};
