import axios from "axios";
import { apiBase, getAuthHeader } from ".";
import { MLModel, MLModelProcessor, MLModelTrainingRequest } from "../interfaces/mlModel";

export const getMLModelProcessors = async (): Promise<MLModelProcessor[]> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/processors`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
}

export const createMLModel = async (clientId: number, request: MLModelTrainingRequest): Promise<MLModel> => {
    const result = await axios({
        method: "POST",
        url: `${apiBase}/clients/${clientId}/mlModels`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: request,
    });

    return result.data.data;
}

export const requestModelRetraining = async (clientId: number, trainingId: number): Promise<MLModel> => {
    const result = await axios({
        method: "POST",
        url: `${apiBase}/clients/${clientId}/mlModels/${trainingId}/retrain`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
}

export const deleteMLModel = async (clientId: number, trainingId: number): Promise<void> => {
    const result = await axios({
        method: "DELETE",
        url: `${apiBase}/clients/${clientId}/mlModels/${trainingId}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
}
