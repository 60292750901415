import axios from "axios";
import qs from "qs";
import { apiBase, APIResponse, Collection, getAuthHeader } from ".";
import { User, UserAction } from "../interfaces";
import { getTeamByID, updateTeam } from "./teams";

export const getUserByUID = async (uid: string): Promise<User> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/users/${uid}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    })

    return result.data.data;
}

export interface UserParameters {
    query?: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    order?: "asc" | "desc";
}

export const getUsers = async (params: UserParameters = {}): Promise<Collection<User>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/users/search?${qs.stringify(params)}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}

export interface CreateUserInput extends User {
    password: string;
    teamId: number | null;
    createMailchimpContact: boolean;
}

// This function also handles adding user to initial team if teamId is provided
export const createUser = async (userInput: CreateUserInput): Promise<User> => {
    const created: APIResponse<User> = await axios({
        method: "POST",
        url: `${apiBase}/users?mailchimp=${userInput.createMailchimpContact}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: userInput,
    });

    const user = created.data.data;

    if (userInput.teamId) {
        const team = await getTeamByID(userInput.teamId);
        const updatedTeam = await updateTeam(userInput.teamId, { members: [ ...team.members, {
            uid: user.uid,
            role: "member",
            email: user.email,
            name: user.name,
        }]});

        user.teams = [ updatedTeam.id ];
    }

    return user;
}

export const updateUser = async (uid: string, user: Partial<User>): Promise<User> => {
    const result = await axios({
        method: "PATCH",
        url: `${apiBase}/users/${uid}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: user,
    });

    return result.data.data;
}

export const deleteUser = async (uid: string): Promise<void> => {
    await axios({
        method: "DELETE",
        url: `${apiBase}/users/${uid}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}

export const getUserActionLog = async (limit: number = 20, offset: number = 0): Promise<Collection<UserAction<string, any>>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/userActions?limit=${limit}&offset=${offset}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}


export const requestPasswordReset = async (email: string): Promise<void> => {
    await axios({
        method: "POST",
        url: `${apiBase}/requestPasswordReset?${qs.stringify({ email })}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}
