
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const BackLink = ({ to }: { to: string }) => {
    return (
        <Link to={to}>
            <Button type="text" size="large" icon={<ArrowLeftOutlined style={{ fontSize: '1.5em' }} />} />
        </Link>
    );
};

export default BackLink;
