import { ApartmentOutlined, ApiOutlined, AppstoreOutlined, FileSearchOutlined, LineChartOutlined, NotificationOutlined, ShopOutlined, TeamOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const logo = new URL('../logo.svg', import.meta.url).toString();

/**
 * clients - Asiakkaat
 * uiMessages - UI-Tiedotteet
 * analytics - Analytiikka
 * organizations - Organisaatiot
 * users - Käyttäjät
 * settings - Asetukset
 * dashboard - Yleisnäkymä
 * integrations - Kytkennät
 */
function Navigation() {
    const location = useLocation();
    const parts = location.pathname.split('/').filter(i => i);
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') === 'true');

    const onCollapse = () => {
        setCollapsed(!collapsed);
        localStorage.setItem('collapsed', (!collapsed).toString());
    }

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div style={{ margin: 24, marginBottom: 32, display: 'flex', alignItems: 'center' }} >
                <img style={{
                    width: 32,
                    marginRight: 16,
                }} src={logo} alt="logo" />
                <h2 style={{ color: 'white', marginBottom: 0, opacity: collapsed ? 0 : 1, transition: 'opacity 0.3s', marginTop: 0 }} >Admin</h2>
            </div>
            <Menu inlineCollapsed={collapsed} theme="dark" mode="inline" selectedKeys={parts.length && parts[0] ? [parts[0]] : []}>
                <Menu.Item key="dashboard" icon={<AppstoreOutlined />}>
                    <Link to="/dashboard">
                        Yleisnäkymä
                    </Link>
                </Menu.Item>
                <Menu.Item key="clients" icon={<ShopOutlined />}>
                    <Link to="/clients">
                        Asiakkaat
                    </Link>
                </Menu.Item>
                <Menu.Item key="organizations" icon={<ApartmentOutlined />}>
                    <Link to="/organizations">
                        Organisaatiot
                    </Link>
                </Menu.Item>
                <Menu.Item key="users" icon={<TeamOutlined />}>
                    <Link to="/users">
                        Käyttäjät
                    </Link>
                </Menu.Item>
                <Menu.Item key="uiMessages" icon={<NotificationOutlined />}>
                    <Link to="/uiMessages">
                        UI-tiedotteet
                    </Link>
                </Menu.Item>
                <Menu.Item key="analytics" icon={<LineChartOutlined />}>
                    <Link to="/analytics">
                        Analytiikka
                    </Link>
                </Menu.Item>
                <Menu.Item key="integrations" icon={<ApiOutlined />}>
                    <Link to="/integrations">
                        Kytkennät
                    </Link>
                </Menu.Item>
                <Menu.Item key="invoiceQuery" icon={<FileSearchOutlined />}>
                  <Link to="/invoiceQuery">Käsittelyhistoria</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default Navigation;
