export interface CountryMapper {
    [key: string]: {
        alpha2: string;
        alpha3: string;
        name: {
            en: string;
            fi: string;
        };
    };
}

const countryMapper: CountryMapper = {
    AFGHANISTAN: {
        alpha2: "AF",
        alpha3: "AFG",
        name: { en: "Afghanistan", fi: "Afganistan" },
    },
    ALBANIA: {
        alpha2: "AL",
        alpha3: "ALB",
        name: { en: "Albania", fi: "Albania" },
    },
    ALGERIA: {
        alpha2: "DZ",
        alpha3: "DZA",
        name: { en: "Algeria", fi: "Algeria" },
    },
    AMERICAN_SAMOA: {
        alpha2: "AS",
        alpha3: "ASM",
        name: { en: "American Samoa", fi: "Amerikan Samoa" },
    },
    ANDORRA: {
        alpha2: "AD",
        alpha3: "AND",
        name: { en: "Andorra", fi: "Andorra" },
    },
    ANGOLA: {
        alpha2: "AO",
        alpha3: "AGO",
        name: { en: "Angola", fi: "Angola" },
    },
    ANGUILLA: {
        alpha2: "AI",
        alpha3: "AIA",
        name: { en: "Anguilla", fi: "Anguilla" },
    },
    ANTARCTICA: {
        alpha2: "AQ",
        alpha3: "ATA",
        name: { en: "Antarctica", fi: "Antarktis" },
    },
    ANTIGUA_AND_BARBUDA: {
        alpha2: "AG",
        alpha3: "ATG",
        name: { en: "Antigua and Barbuda", fi: "Antigua ja Barbuda" },
    },
    ARGENTINA: {
        alpha2: "AR",
        alpha3: "ARG",
        name: { en: "Argentina", fi: "Argentiina" },
    },
    ARMENIA: {
        alpha2: "AM",
        alpha3: "ARM",
        name: { en: "Armenia", fi: "Armenia" },
    },
    ARUBA: { alpha2: "AW", alpha3: "ABW", name: { en: "Aruba", fi: "Aruba" } },
    AUSTRALIA: {
        alpha2: "AU",
        alpha3: "AUS",
        name: { en: "Australia", fi: "Australia" },
    },
    AUSTRIA: {
        alpha2: "AT",
        alpha3: "AUT",
        name: { en: "Austria", fi: "Itävalta" },
    },
    AZERBAIJAN: {
        alpha2: "AZ",
        alpha3: "AZE",
        name: { en: "Azerbaijan", fi: "Azerbaidžan" },
    },
    BAHAMAS: {
        alpha2: "BS",
        alpha3: "BHS",
        name: { en: "Bahamas", fi: "Bahama" },
    },
    BAHRAIN: {
        alpha2: "BH",
        alpha3: "BHR",
        name: { en: "Bahrain", fi: "Bahrain" },
    },
    BANGLADESH: {
        alpha2: "BD",
        alpha3: "BGD",
        name: { en: "Bangladesh", fi: "Bangladesh" },
    },
    BARBADOS: {
        alpha2: "BB",
        alpha3: "BRB",
        name: { en: "Barbados", fi: "Barbados" },
    },
    BELARUS: {
        alpha2: "BY",
        alpha3: "BLR",
        name: { en: "Belarus", fi: "Valko-Venäjä" },
    },
    BELGIUM: {
        alpha2: "BE",
        alpha3: "BEL",
        name: { en: "Belgium", fi: "Belgia" },
    },
    BELIZE: {
        alpha2: "BZ",
        alpha3: "BLZ",
        name: { en: "Belize", fi: "Belize" },
    },
    BENIN: { alpha2: "BJ", alpha3: "BEN", name: { en: "Benin", fi: "Benin" } },
    BERMUDA: {
        alpha2: "BM",
        alpha3: "BMU",
        name: { en: "Bermuda", fi: "Bermuda" },
    },
    BHUTAN: {
        alpha2: "BT",
        alpha3: "BTN",
        name: { en: "Bhutan", fi: "Bhutan" },
    },
    PLURINATIONAL_STATE_OF_BOLIVIA: {
        alpha2: "BO",
        alpha3: "BOL",
        name: { en: "Bolivia, Plurinational State of", fi: "Bolivia" },
    },
    SINT_EUSTATIUS_AND_SABA_BONAIRE: {
        alpha2: "BQ",
        alpha3: "BES",
        name: {
            en: "Bonaire, Saint Eustatius and Saba",
            fi: "Bonaire, Sint Eustatius ja Saba",
        },
    },
    BOSNIA_AND_HERZEGOVINA: {
        alpha2: "BA",
        alpha3: "BIH",
        name: { en: "Bosnia and Herzegovina", fi: "Bosnia ja Hertsegovina" },
    },
    BOTSWANA: {
        alpha2: "BW",
        alpha3: "BWA",
        name: { en: "Botswana", fi: "Botswana" },
    },
    BOUVET_ISLAND: {
        alpha2: "BV",
        alpha3: "BVT",
        name: { en: "Bouvet Island", fi: "Bouvet Island" },
    },
    BRAZIL: {
        alpha2: "BR",
        alpha3: "BRA",
        name: { en: "Brazil", fi: "Brasilia" },
    },
    BRITISH_INDIAN_OCEAN_TERRITORY: {
        alpha2: "IO",
        alpha3: "IOT",
        name: {
            en: "British Indian Ocean Territory",
            fi: "Brittiläinen Intian valtameren alue",
        },
    },
    BRUNEI_DARUSSALAM: {
        alpha2: "BN",
        alpha3: "BRN",
        name: { en: "Brunei Darussalam", fi: "Brunei" },
    },
    BULGARIA: {
        alpha2: "BG",
        alpha3: "BGR",
        name: { en: "Bulgaria", fi: "Bulgaria" },
    },
    BURKINA_FASO: {
        alpha2: "BF",
        alpha3: "BFA",
        name: { en: "Burkina Faso", fi: "Burkina Faso" },
    },
    BURUNDI: {
        alpha2: "BI",
        alpha3: "BDI",
        name: { en: "Burundi", fi: "Burundi" },
    },
    CABO_VERDE: {
        alpha2: "CV",
        alpha3: "CPV",
        name: { en: "Cape Verde", fi: "Kap Verde" },
    },
    CAMBODIA: {
        alpha2: "KH",
        alpha3: "KHM",
        name: { en: "Cambodia", fi: "Kambodža" },
    },
    CAMEROON: {
        alpha2: "CM",
        alpha3: "CMR",
        name: { en: "Cameroon", fi: "Kamerun" },
    },
    CANADA: {
        alpha2: "CA",
        alpha3: "CAN",
        name: { en: "Canada", fi: "Kanada" },
    },
    CAYMAN_ISLANDS: {
        alpha2: "KY",
        alpha3: "CYM",
        name: { en: "Cayman Islands", fi: "Caymansaaret" },
    },
    CENTRAL_AFRICAN_REPUBLIC: {
        alpha2: "CF",
        alpha3: "CAF",
        name: { en: "Central African Republic", fi: "Keski-Afrikan tasavalta" },
    },
    CHAD: { alpha2: "TD", alpha3: "TCD", name: { en: "Chad", fi: "Tšad" } },
    CHILE: { alpha2: "CL", alpha3: "CHL", name: { en: "Chile", fi: "Chile" } },
    CHINA: { alpha2: "CN", alpha3: "CHN", name: { en: "China", fi: "Kiina" } },
    CHRISTMAS_ISLAND: {
        alpha2: "CX",
        alpha3: "CXR",
        name: { en: "Christmas Island", fi: "Christmas Island" },
    },
    COCOS_KEELING_ISLANDS: {
        alpha2: "CC",
        alpha3: "CCK",
        name: { en: "Cocos (Keeling) Islands", fi: "Cocos (Keeling) Islands" },
    },
    COLOMBIA: {
        alpha2: "CO",
        alpha3: "COL",
        name: { en: "Colombia", fi: "Kolumbia" },
    },
    COMOROS: {
        alpha2: "KM",
        alpha3: "COM",
        name: { en: "Comoros", fi: "Komorit" },
    },
    CONGO: {
        alpha2: "CG",
        alpha3: "COG",
        name: { en: "Congo", fi: "Kongon tasavalta" },
    },
    THE_DEMOCRATIC_REPUBLIC_OF_THE_CONGO: {
        alpha2: "CD",
        alpha3: "COD",
        name: {
            en: "Congo, The Democratic Republic of the",
            fi: "Kongon demokraattinen tasavalta",
        },
    },
    COOK_ISLANDS: {
        alpha2: "CK",
        alpha3: "COK",
        name: { en: "Cook Islands", fi: "Cookinsaaret" },
    },
    COSTA_RICA: {
        alpha2: "CR",
        alpha3: "CRI",
        name: { en: "Costa Rica", fi: "Costa Rica" },
    },
    CROATIA: {
        alpha2: "HR",
        alpha3: "HRV",
        name: { en: "Croatia", fi: "Kroatia" },
    },
    CUBA: { alpha2: "CU", alpha3: "CUB", name: { en: "Cuba", fi: "Kuuba" } },
    CURACAO: {
        alpha2: "CW",
        alpha3: "CUW",
        name: { en: "Curacao", fi: "Curaçao" },
    },
    CYPRUS: {
        alpha2: "CY",
        alpha3: "CYP",
        name: { en: "Cyprus", fi: "Kypros" },
    },
    CZECH_REPUBLIC: {
        alpha2: "CZ",
        alpha3: "CZE",
        name: { en: "Czech Republic", fi: "Tšekki" },
    },
    COTE_D_IVOIRE: {
        alpha2: "CI",
        alpha3: "CIV",
        name: { en: "Cote d'Ivoire", fi: "Norsunluurannikko" },
    },
    DENMARK: {
        alpha2: "DK",
        alpha3: "DNK",
        name: { en: "Denmark", fi: "Tanska" },
    },
    DJIBOUTI: {
        alpha2: "DJ",
        alpha3: "DJI",
        name: { en: "Djibouti", fi: "Djibouti" },
    },
    DOMINICA: {
        alpha2: "DM",
        alpha3: "DMA",
        name: { en: "Dominica", fi: "Dominica" },
    },
    DOMINICAN_REPUBLIC: {
        alpha2: "DO",
        alpha3: "DOM",
        name: { en: "Dominican Republic", fi: "Dominikaaninen tasavalta" },
    },
    ECUADOR: {
        alpha2: "EC",
        alpha3: "ECU",
        name: { en: "Ecuador", fi: "Ecuador" },
    },
    EGYPT: { alpha2: "EG", alpha3: "EGY", name: { en: "Egypt", fi: "Egypti" } },
    EL_SALVADOR: {
        alpha2: "SV",
        alpha3: "SLV",
        name: { en: "El Salvador", fi: "El Salvador" },
    },
    EQUATORIAL_GUINEA: {
        alpha2: "GQ",
        alpha3: "GNQ",
        name: { en: "Equatorial Guinea", fi: "Päiväntasaajan Guinea" },
    },
    ERITREA: {
        alpha2: "ER",
        alpha3: "ERI",
        name: { en: "Eritrea", fi: "Eritrea" },
    },
    ESTONIA: {
        alpha2: "EE",
        alpha3: "EST",
        name: { en: "Estonia", fi: "Viro" },
    },
    ETHIOPIA: {
        alpha2: "ET",
        alpha3: "ETH",
        name: { en: "Ethiopia", fi: "Etiopia" },
    },
    FALKLAND_ISLANDS_MALVINAS: {
        alpha2: "FK",
        alpha3: "FLK",
        name: { en: "Falkland Islands (Malvinas)", fi: "Falklandinsaaret" },
    },
    FAROE_ISLANDS: {
        alpha2: "FO",
        alpha3: "FRO",
        name: { en: "Faroe Islands", fi: "Färsaaret" },
    },
    FIJI: { alpha2: "FJ", alpha3: "FJI", name: { en: "Fiji", fi: "Fidži" } },
    FINLAND: {
        alpha2: "FI",
        alpha3: "FIN",
        name: { en: "Finland", fi: "Suomi" },
    },
    FRANCE: {
        alpha2: "FR",
        alpha3: "FRA",
        name: { en: "France", fi: "Ranska" },
    },
    FRENCH_GUIANA: {
        alpha2: "GF",
        alpha3: "GUF",
        name: { en: "French Guiana", fi: "Ranskan Guayana" },
    },
    FRENCH_POLYNESIA: {
        alpha2: "PF",
        alpha3: "PYF",
        name: { en: "French Polynesia", fi: "Ranskan Polynesia" },
    },
    FRENCH_SOUTHERN_TERRITORIES: {
        alpha2: "TF",
        alpha3: "ATF",
        name: {
            en: "French Southern Territories",
            fi: "Ranskan eteläiset alueet",
        },
    },
    GABON: { alpha2: "GA", alpha3: "GAB", name: { en: "Gabon", fi: "Gabon" } },
    GAMBIA: {
        alpha2: "GM",
        alpha3: "GMB",
        name: { en: "Gambia", fi: "Gambia" },
    },
    GEORGIA: {
        alpha2: "GE",
        alpha3: "GEO",
        name: { en: "Georgia", fi: "Georgia" },
    },
    GERMANY: {
        alpha2: "DE",
        alpha3: "DEU",
        name: { en: "Germany", fi: "Saksa" },
    },
    GHANA: { alpha2: "GH", alpha3: "GHA", name: { en: "Ghana", fi: "Ghana" } },
    GIBRALTAR: {
        alpha2: "GI",
        alpha3: "GIB",
        name: { en: "Gibraltar", fi: "Gibraltar" },
    },
    GREECE: {
        alpha2: "GR",
        alpha3: "GRC",
        name: { en: "Greece", fi: "Kreikka" },
    },
    GREENLAND: {
        alpha2: "GL",
        alpha3: "GRL",
        name: { en: "Greenland", fi: "Grönlanti" },
    },
    GRENADA: {
        alpha2: "GD",
        alpha3: "GRD",
        name: { en: "Grenada", fi: "Grenada" },
    },
    GUADELOUPE: {
        alpha2: "GP",
        alpha3: "GLP",
        name: { en: "Guadeloupe", fi: "Guadeloupe" },
    },
    GUAM: { alpha2: "GU", alpha3: "GUM", name: { en: "Guam", fi: "Guam" } },
    GUATEMALA: {
        alpha2: "GT",
        alpha3: "GTM",
        name: { en: "Guatemala", fi: "Guatemala" },
    },
    GUERNSEY: {
        alpha2: "GG",
        alpha3: "GGY",
        name: { en: "Guernsey", fi: "Guernsey" },
    },
    GUINEA: {
        alpha2: "GN",
        alpha3: "GIN",
        name: { en: "Guinea", fi: "Guinea" },
    },
    GUINEA_BISSAU: {
        alpha2: "GW",
        alpha3: "GNB",
        name: { en: "Guinea-Bissau", fi: "Guinea-Bissau" },
    },
    GUYANA: {
        alpha2: "GY",
        alpha3: "GUY",
        name: { en: "Guyana", fi: "Guyana" },
    },
    HAITI: { alpha2: "HT", alpha3: "HTI", name: { en: "Haiti", fi: "Haiti" } },
    HEARD_ISLAND_AND_MCDONALD_ISLANDS: {
        alpha2: "HM",
        alpha3: "HMD",
        name: {
            en: "Heard Island and McDonald Islands",
            fi: "Heard Island and McDonald Islands",
        },
    },
    HOLY_SEE_VATICAN_CITY_STATE: {
        alpha2: "VA",
        alpha3: "VAT",
        name: { en: "Holy See (Vatican City State)", fi: "Vatikaanivaltio" },
    },
    HONDURAS: {
        alpha2: "HN",
        alpha3: "HND",
        name: { en: "Honduras", fi: "Honduras" },
    },
    HONG_KONG: {
        alpha2: "HK",
        alpha3: "HKG",
        name: { en: "Hong Kong", fi: "Hongkong" },
    },
    HUNGARY: {
        alpha2: "HU",
        alpha3: "HUN",
        name: { en: "Hungary", fi: "Unkari" },
    },
    ICELAND: {
        alpha2: "IS",
        alpha3: "ISL",
        name: { en: "Iceland", fi: "Islanti" },
    },
    INDIA: { alpha2: "IN", alpha3: "IND", name: { en: "India", fi: "Intia" } },
    INDONESIA: {
        alpha2: "ID",
        alpha3: "IDN",
        name: { en: "Indonesia", fi: "Indonesia" },
    },
    ISLAMIC_REPUBLIC_OF_IRAN: {
        alpha2: "IR",
        alpha3: "IRN",
        name: { en: "Iran, Islamic Republic of", fi: "Iran" },
    },
    IRAQ: { alpha2: "IQ", alpha3: "IRQ", name: { en: "Iraq", fi: "Irak" } },
    IRELAND: {
        alpha2: "IE",
        alpha3: "IRL",
        name: { en: "Ireland", fi: "Irlanti" },
    },
    ISLE_OF_MAN: {
        alpha2: "IM",
        alpha3: "IMN",
        name: { en: "Isle of Man", fi: "Mansaari" },
    },
    ISRAEL: {
        alpha2: "IL",
        alpha3: "ISR",
        name: { en: "Israel", fi: "Israel" },
    },
    ITALY: { alpha2: "IT", alpha3: "ITA", name: { en: "Italy", fi: "Italia" } },
    JAMAICA: {
        alpha2: "JM",
        alpha3: "JAM",
        name: { en: "Jamaica", fi: "Jamaika" },
    },
    JAPAN: { alpha2: "JP", alpha3: "JPN", name: { en: "Japan", fi: "Japani" } },
    JERSEY: {
        alpha2: "JE",
        alpha3: "JEY",
        name: { en: "Jersey", fi: "Jersey" },
    },
    JORDAN: {
        alpha2: "JO",
        alpha3: "JOR",
        name: { en: "Jordan", fi: "Jordania" },
    },
    KAZAKHSTAN: {
        alpha2: "KZ",
        alpha3: "KAZ",
        name: { en: "Kazakhstan", fi: "Kazakstan" },
    },
    KENYA: { alpha2: "KE", alpha3: "KEN", name: { en: "Kenya", fi: "Kenia" } },
    KIRIBATI: {
        alpha2: "KI",
        alpha3: "KIR",
        name: { en: "Kiribati", fi: "Kiribati" },
    },
    DEMOCRATIC_PEOPLE_S_REPUBLIC_OF_KOREA: {
        alpha2: "KP",
        alpha3: "PRK",
        name: {
            en: "Korea, Democratic People's Republic of",
            fi: "Korean demokraattinen kansantasavalta",
        },
    },
    REPUBLIC_OF_KOREA: {
        alpha2: "KR",
        alpha3: "KOR",
        name: { en: "Korea, Republic of", fi: "Korean tasavalta" },
    },
    KOSOVO_TEMPORARY: {
        alpha2: "RS",
        alpha3: "SRB",
        name: { en: "Serbia", fi: "Serbia" },
    },
    KUWAIT: {
        alpha2: "KW",
        alpha3: "KWT",
        name: { en: "Kuwait", fi: "Kuwait" },
    },
    KYRGYZSTAN: {
        alpha2: "KG",
        alpha3: "KGZ",
        name: { en: "Kyrgyzstan", fi: "Kirgisia" },
    },
    LAO_PEOPLE_S_DEMOCRATIC_REPUBLIC: {
        alpha2: "LA",
        alpha3: "LAO",
        name: { en: "Lao People's Democratic Republic", fi: "Laos" },
    },
    LATVIA: {
        alpha2: "LV",
        alpha3: "LVA",
        name: { en: "Latvia", fi: "Latvia" },
    },
    LEBANON: {
        alpha2: "LB",
        alpha3: "LBN",
        name: { en: "Lebanon", fi: "Libanon" },
    },
    LESOTHO: {
        alpha2: "LS",
        alpha3: "LSO",
        name: { en: "Lesotho", fi: "Lesotho" },
    },
    LIBERIA: {
        alpha2: "LR",
        alpha3: "LBR",
        name: { en: "Liberia", fi: "Liberia" },
    },
    LIBYA: { alpha2: "LY", alpha3: "LBY", name: { en: "Libya", fi: "Libya" } },
    LIECHTENSTEIN: {
        alpha2: "LI",
        alpha3: "LIE",
        name: { en: "Liechtenstein", fi: "Liechtenstein" },
    },
    LITHUANIA: {
        alpha2: "LT",
        alpha3: "LTU",
        name: { en: "Lithuania", fi: "Liettua" },
    },
    LUXEMBOURG: {
        alpha2: "LU",
        alpha3: "LUX",
        name: { en: "Luxembourg", fi: "Luxemburg" },
    },
    MACAO: { alpha2: "MO", alpha3: "MAC", name: { en: "Macao", fi: "Macao" } },
    THE_FORMER_YUGOSLAV_REPUBLIC_OF_MACEDONIA: {
        alpha2: "MK",
        alpha3: "MKD",
        name: {
            en: "Macedonia, The Former Yugoslav Republic of",
            fi: "Makedonia",
        },
    },
    MADAGASCAR: {
        alpha2: "MG",
        alpha3: "MDG",
        name: { en: "Madagascar", fi: "Madagaskar" },
    },
    MALAWI: {
        alpha2: "MW",
        alpha3: "MWI",
        name: { en: "Malawi", fi: "Malawi" },
    },
    MALAYSIA: {
        alpha2: "MY",
        alpha3: "MYS",
        name: { en: "Malaysia", fi: "Malesia" },
    },
    MALDIVES: {
        alpha2: "MV",
        alpha3: "MDV",
        name: { en: "Maldives", fi: "Malediivit" },
    },
    MALI: { alpha2: "ML", alpha3: "MLI", name: { en: "Mali", fi: "Mali" } },
    MALTA: { alpha2: "MT", alpha3: "MLT", name: { en: "Malta", fi: "Malta" } },
    MARSHALL_ISLANDS: {
        alpha2: "MH",
        alpha3: "MHL",
        name: { en: "Marshall Islands", fi: "Marshallinsaaret" },
    },
    MARTINIQUE: {
        alpha2: "MQ",
        alpha3: "MTQ",
        name: { en: "Martinique", fi: "Martinique" },
    },
    MAURITANIA: {
        alpha2: "MR",
        alpha3: "MRT",
        name: { en: "Mauritania", fi: "Mauritania" },
    },
    MAURITIUS: {
        alpha2: "MU",
        alpha3: "MUS",
        name: { en: "Mauritius", fi: "Mauritius" },
    },
    MAYOTTE: {
        alpha2: "YT",
        alpha3: "MYT",
        name: { en: "Mayotte", fi: "Mayotte" },
    },
    MEXICO: {
        alpha2: "MX",
        alpha3: "MEX",
        name: { en: "Mexico", fi: "Meksiko" },
    },
    FEDERATED_STATES_OF_MICRONESIA: {
        alpha2: "FM",
        alpha3: "FSM",
        name: {
            en: "Micronesia, Federated States of",
            fi: "Mikronesian liittovaltio",
        },
    },
    REPUBLIC_OF_MOLDOVA: {
        alpha2: "MD",
        alpha3: "MDA",
        name: { en: "Moldova, Republic of", fi: "Moldova" },
    },
    MONACO: {
        alpha2: "MC",
        alpha3: "MCO",
        name: { en: "Monaco", fi: "Monaco" },
    },
    MONGOLIA: {
        alpha2: "MN",
        alpha3: "MNG",
        name: { en: "Mongolia", fi: "Mongolia" },
    },
    MONTENEGRO: {
        alpha2: "ME",
        alpha3: "MNE",
        name: { en: "Montenegro", fi: "Montenegro" },
    },
    MONTSERRAT: {
        alpha2: "MS",
        alpha3: "MSR",
        name: { en: "Montserrat", fi: "Montserrat" },
    },
    MOROCCO: {
        alpha2: "MA",
        alpha3: "MAR",
        name: { en: "Morocco", fi: "Marokko" },
    },
    MOZAMBIQUE: {
        alpha2: "MZ",
        alpha3: "MOZ",
        name: { en: "Mozambique", fi: "Mosambik" },
    },
    MYANMAR: {
        alpha2: "MM",
        alpha3: "MMR",
        name: { en: "Myanmar", fi: "Myanmar" },
    },
    NAMIBIA: {
        alpha2: "NA",
        alpha3: "NAM",
        name: { en: "Namibia", fi: "Namibia" },
    },
    NAURU: { alpha2: "NR", alpha3: "NRU", name: { en: "Nauru", fi: "Nauru" } },
    NEPAL: { alpha2: "NP", alpha3: "NPL", name: { en: "Nepal", fi: "Nepal" } },
    NETHERLANDS: {
        alpha2: "NL",
        alpha3: "NLD",
        name: { en: "Netherlands", fi: "Alankomaat" },
    },
    NEW_CALEDONIA: {
        alpha2: "NC",
        alpha3: "NCL",
        name: { en: "New Caledonia", fi: "Uusi-Kaledonia" },
    },
    NEW_ZEALAND: {
        alpha2: "NZ",
        alpha3: "NZL",
        name: { en: "New Zealand", fi: "Uusi-Seelanti" },
    },
    NICARAGUA: {
        alpha2: "NI",
        alpha3: "NIC",
        name: { en: "Nicaragua", fi: "Nicaragua" },
    },
    NIGER: { alpha2: "NE", alpha3: "NER", name: { en: "Niger", fi: "Niger" } },
    NIGERIA: {
        alpha2: "NG",
        alpha3: "NGA",
        name: { en: "Nigeria", fi: "Nigeria" },
    },
    NIUE: { alpha2: "NU", alpha3: "NIU", name: { en: "Niue", fi: "Niue" } },
    NORFOLK_ISLAND: {
        alpha2: "NF",
        alpha3: "NFK",
        name: { en: "Norfolk Island", fi: "Norfolkinsaari" },
    },
    NORTHERN_MARIANA_ISLANDS: {
        alpha2: "MP",
        alpha3: "MNP",
        name: { en: "Northern Mariana Islands", fi: "Pohjois-Mariaanit" },
    },
    NORWAY: {
        alpha2: "NO",
        alpha3: "NOR",
        name: { en: "Norway", fi: "Norja" },
    },
    OMAN: { alpha2: "OM", alpha3: "OMN", name: { en: "Oman", fi: "Oman" } },
    PAKISTAN: {
        alpha2: "PK",
        alpha3: "PAK",
        name: { en: "Pakistan", fi: "Pakistan" },
    },
    PALAU: { alpha2: "PW", alpha3: "PLW", name: { en: "Palau", fi: "Palau" } },
    STATE_OF_PALESTINE: {
        alpha2: "PS",
        alpha3: "PSE",
        name: { en: "West Bank", fi: "Palestiina" },
    },
    PANAMA: {
        alpha2: "PA",
        alpha3: "PAN",
        name: { en: "Panama", fi: "Panama" },
    },
    PAPUA_NEW_GUINEA: {
        alpha2: "PG",
        alpha3: "PNG",
        name: { en: "Papua New Guinea", fi: "Papua-Uusi-Guinea" },
    },
    PARAGUAY: {
        alpha2: "PY",
        alpha3: "PRY",
        name: { en: "Paraguay", fi: "Paraguay" },
    },
    PERU: { alpha2: "PE", alpha3: "PER", name: { en: "Peru", fi: "Peru" } },
    PHILIPPINES: {
        alpha2: "PH",
        alpha3: "PHL",
        name: { en: "Philippines", fi: "Filippiinit" },
    },
    PITCAIRN: {
        alpha2: "PN",
        alpha3: "PCN",
        name: { en: "Pitcairn", fi: "Pitcairn" },
    },
    POLAND: {
        alpha2: "PL",
        alpha3: "POL",
        name: { en: "Poland", fi: "Puola" },
    },
    PORTUGAL: {
        alpha2: "PT",
        alpha3: "PRT",
        name: { en: "Portugal", fi: "Portugali" },
    },
    PUERTO_RICO: {
        alpha2: "PR",
        alpha3: "PRI",
        name: { en: "Puerto Rico", fi: "Puerto Rico" },
    },
    QATAR: { alpha2: "QA", alpha3: "QAT", name: { en: "Qatar", fi: "Qatar" } },
    ROMANIA: {
        alpha2: "RO",
        alpha3: "ROU",
        name: { en: "Romania", fi: "Romania" },
    },
    RUSSIAN_FEDERATION: {
        alpha2: "RU",
        alpha3: "RUS",
        name: { en: "Russian Federation", fi: "Venäjä" },
    },
    RWANDA: {
        alpha2: "RW",
        alpha3: "RWA",
        name: { en: "Rwanda", fi: "Ruanda" },
    },
    REUNION: {
        alpha2: "RE",
        alpha3: "REU",
        name: { en: "Reunion", fi: "Réunion" },
    },
    SAINT_BARTHELEMY: {
        alpha2: "BL",
        alpha3: "BLM",
        name: { en: "Saint Barthelemy", fi: "Saint-Barthélemy" },
    },
    ASCENSION_AND_TRISTAN_DA_CUNHA_SAINT_HELENA: {
        alpha2: "SH",
        alpha3: "SHN",
        name: {
            en: "Saint Helena, Ascension and Tristan da Cunha",
            fi: "Saint Helena",
        },
    },
    SAINT_KITTS_AND_NEVIS: {
        alpha2: "KN",
        alpha3: "KNA",
        name: { en: "Saint Kitts and Nevis", fi: "Saint Kitts ja Nevis" },
    },
    SAINT_LUCIA: {
        alpha2: "LC",
        alpha3: "LCA",
        name: { en: "Saint Lucia", fi: "Saint Lucia" },
    },
    SAINT_MARTIN_FRENCH_PART: {
        alpha2: "MF",
        alpha3: "MAF",
        name: { en: "Saint Martin (French part)", fi: "Saint-Martin" },
    },
    SAINT_PIERRE_AND_MIQUELON: {
        alpha2: "PM",
        alpha3: "SPM",
        name: {
            en: "Saint Pierre and Miquelon",
            fi: "Saint-Pierre ja Miquelon",
        },
    },
    SAINT_VINCENT_AND_THE_GRENADINES: {
        alpha2: "VC",
        alpha3: "VCT",
        name: {
            en: "Saint Vincent and the Grenadines",
            fi: "Saint Vincent ja Grenadiinit",
        },
    },
    SAMOA: { alpha2: "WS", alpha3: "WSM", name: { en: "Samoa", fi: "Samoa" } },
    SAN_MARINO: {
        alpha2: "SM",
        alpha3: "SMR",
        name: { en: "San Marino", fi: "San Marino" },
    },
    SAO_TOME_AND_PRINCIPE: {
        alpha2: "ST",
        alpha3: "STP",
        name: { en: "Sao Tome and Principe", fi: "São Tomé ja Príncipe" },
    },
    SAUDI_ARABIA: {
        alpha2: "SA",
        alpha3: "SAU",
        name: { en: "Saudi Arabia", fi: "Saudi-Arabia" },
    },
    SENEGAL: {
        alpha2: "SN",
        alpha3: "SEN",
        name: { en: "Senegal", fi: "Senegal" },
    },
    SERBIA: {
        alpha2: "RS",
        alpha3: "SRB",
        name: { en: "Serbia", fi: "Serbia" },
    },
    SEYCHELLES: {
        alpha2: "SC",
        alpha3: "SYC",
        name: { en: "Seychelles", fi: "Seychellit" },
    },
    SIERRA_LEONE: {
        alpha2: "SL",
        alpha3: "SLE",
        name: { en: "Sierra Leone", fi: "Sierra Leone" },
    },
    SINGAPORE: {
        alpha2: "SG",
        alpha3: "SGP",
        name: { en: "Singapore", fi: "Singapore" },
    },
    SINT_MAARTEN_DUTCH_PART: {
        alpha2: "SX",
        alpha3: "SXM",
        name: { en: "Sint Maarten (Dutch part)", fi: "Sint Maarten" },
    },
    SLOVAKIA: {
        alpha2: "SK",
        alpha3: "SVK",
        name: { en: "Slovakia", fi: "Slovakia" },
    },
    SLOVENIA: {
        alpha2: "SI",
        alpha3: "SVN",
        name: { en: "Slovenia", fi: "Slovenia" },
    },
    SOLOMON_ISLANDS: {
        alpha2: "SB",
        alpha3: "SLB",
        name: { en: "Solomon Islands", fi: "Salomonsaaret" },
    },
    SOMALIA: {
        alpha2: "SO",
        alpha3: "SOM",
        name: { en: "Somalia", fi: "Somalia" },
    },
    SOUTH_AFRICA: {
        alpha2: "ZA",
        alpha3: "ZAF",
        name: { en: "South Africa", fi: "Etelä-Afrikka" },
    },
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: {
        alpha2: "GS",
        alpha3: "SGS",
        name: {
            en: "South Georgia and the South Sandwich Islands",
            fi: "South Georgia and the South Sandwich Islands",
        },
    },
    SOUTH_SUDAN: {
        alpha2: "SS",
        alpha3: "SSD",
        name: { en: "South Sudan", fi: "Etelä-Sudan" },
    },
    SPAIN: {
        alpha2: "ES",
        alpha3: "ESP",
        name: { en: "Spain", fi: "Espanja" },
    },
    SRI_LANKA: {
        alpha2: "LK",
        alpha3: "LKA",
        name: { en: "Sri Lanka", fi: "Sri Lanka" },
    },
    SUDAN: { alpha2: "SD", alpha3: "SDN", name: { en: "Sudan", fi: "Sudan" } },
    SURINAME: {
        alpha2: "SR",
        alpha3: "SUR",
        name: { en: "Suriname", fi: "Suriname" },
    },
    SVALBARD_AND_JAN_MAYEN: {
        alpha2: "SJ",
        alpha3: "SJM",
        name: { en: "Svalbard and Jan Mayen", fi: "Svalbard ja Jan Mayen" },
    },
    SWAZILAND: {
        alpha2: "SZ",
        alpha3: "SWZ",
        name: { en: "Swaziland", fi: "Swazimaa" },
    },
    SWEDEN: {
        alpha2: "SE",
        alpha3: "SWE",
        name: { en: "Sweden", fi: "Ruotsi" },
    },
    SWITZERLAND: {
        alpha2: "CH",
        alpha3: "CHE",
        name: { en: "Switzerland", fi: "Sveitsi" },
    },
    SYRIAN_ARAB_REPUBLIC: {
        alpha2: "SY",
        alpha3: "SYR",
        name: { en: "Syrian Arab Republic", fi: "Syyria" },
    },
    PROVINCE_OF_CHINA_TAIWAN: {
        alpha2: "TW",
        alpha3: "TWN",
        name: { en: "Taiwan, Province of China", fi: "Taiwan" },
    },
    TAJIKISTAN: {
        alpha2: "TJ",
        alpha3: "TJK",
        name: { en: "Tajikistan", fi: "Tadžikistan" },
    },
    UNITED_REPUBLIC_OF_TANZANIA: {
        alpha2: "TZ",
        alpha3: "TZA",
        name: { en: "Tanzania, United Republic of", fi: "Tansania" },
    },
    THAILAND: {
        alpha2: "TH",
        alpha3: "THA",
        name: { en: "Thailand", fi: "Thaimaa" },
    },
    TIMOR_LESTE: {
        alpha2: "TL",
        alpha3: "TLS",
        name: { en: "Timor-Leste", fi: "Itä-Timor" },
    },
    TOGO: { alpha2: "TG", alpha3: "TGO", name: { en: "Togo", fi: "Togo" } },
    TOKELAU: {
        alpha2: "TK",
        alpha3: "TKL",
        name: { en: "Tokelau", fi: "Tokelau" },
    },
    TONGA: { alpha2: "TO", alpha3: "TON", name: { en: "Tonga", fi: "Tonga" } },
    TRINIDAD_AND_TOBAGO: {
        alpha2: "TT",
        alpha3: "TTO",
        name: { en: "Trinidad and Tobago", fi: "Trinidad ja Tobago" },
    },
    TUNISIA: {
        alpha2: "TN",
        alpha3: "TUN",
        name: { en: "Tunisia", fi: "Tunisia" },
    },
    TURKEY: {
        alpha2: "TR",
        alpha3: "TUR",
        name: { en: "Turkey", fi: "Turkki" },
    },
    TURKMENISTAN: {
        alpha2: "TM",
        alpha3: "TKM",
        name: { en: "Turkmenistan", fi: "Turkmenistan" },
    },
    TURKS_AND_CAICOS_ISLANDS: {
        alpha2: "TC",
        alpha3: "TCA",
        name: { en: "Turks and Caicos Islands", fi: "Turks- ja Caicossaaret" },
    },
    TUVALU: {
        alpha2: "TV",
        alpha3: "TUV",
        name: { en: "Tuvalu", fi: "Tuvalu" },
    },
    UGANDA: {
        alpha2: "UG",
        alpha3: "UGA",
        name: { en: "Uganda", fi: "Uganda" },
    },
    UKRAINE: {
        alpha2: "UA",
        alpha3: "UKR",
        name: { en: "Ukraine", fi: "Ukraina" },
    },
    UNITED_ARAB_EMIRATES: {
        alpha2: "AE",
        alpha3: "ARE",
        name: { en: "United Arab Emirates", fi: "Arabiemiirikunnat" },
    },
    UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND: {
        alpha2: "GB",
        alpha3: "GBR",
        name: { en: "United Kingdom", fi: "Yhdistynyt kuningaskunta" },
    },
    UNITED_STATES_MINOR_OUTLYING_ISLANDS: {
        alpha2: "UM",
        alpha3: "UMI",
        name: {
            en: "United States Minor Outlying Islands",
            fi: "Yhdysvaltain pienet erillissaaret",
        },
    },
    UNITED_STATES_OF_AMERICA: {
        alpha2: "US",
        alpha3: "USA",
        name: { en: "United States", fi: "Yhdysvallat" },
    },
    URUGUAY: {
        alpha2: "UY",
        alpha3: "URY",
        name: { en: "Uruguay", fi: "Uruguay" },
    },
    UZBEKISTAN: {
        alpha2: "UZ",
        alpha3: "UZB",
        name: { en: "Uzbekistan", fi: "Uzbekistan" },
    },
    VANUATU: {
        alpha2: "VU",
        alpha3: "VUT",
        name: { en: "Vanuatu", fi: "Vanuatu" },
    },
    BOLIVARIAN_REPUBLIC_OF_VENEZUELA: {
        alpha2: "VE",
        alpha3: "VEN",
        name: { en: "Venezuela, Bolivarian Republic of", fi: "Venezuela" },
    },
    VIET_NAM: {
        alpha2: "VN",
        alpha3: "VNM",
        name: { en: "Viet Nam", fi: "Vietnam" },
    },
    BRITISH_VIRGIN_ISLANDS: {
        alpha2: "VG",
        alpha3: "VGB",
        name: {
            en: "Virgin Islands, British",
            fi: "Brittiläiset Neitsytsaaret",
        },
    },
    U_S_VIRGIN_ISLANDS: {
        alpha2: "VI",
        alpha3: "VIR",
        name: { en: "Virgin Islands, U.S.", fi: "Yhdysvaltain Neitsytsaaret" },
    },
    WALLIS_AND_FUTUNA: {
        alpha2: "WF",
        alpha3: "WLF",
        name: { en: "Wallis and Futuna", fi: "Wallis ja Futunasaaret" },
    },
    WESTERN_SAHARA: {
        alpha2: "EH",
        alpha3: "ESH",
        name: { en: "Western Sahara", fi: "Länsi-Sahara" },
    },
    YEMEN: { alpha2: "YE", alpha3: "YEM", name: { en: "Yemen", fi: "Jemen" } },
    ZAMBIA: {
        alpha2: "ZM",
        alpha3: "ZMB",
        name: { en: "Zambia", fi: "Sambia" },
    },
    ZIMBABWE: {
        alpha2: "ZW",
        alpha3: "ZWE",
        name: { en: "Zimbabwe", fi: "Zimbabwe" },
    },
};

export default countryMapper;
