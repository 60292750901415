import { notification } from "antd";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import qs from "qs";
import * as R from "ramda";
import { apiBase, getAuthHeader } from ".";
import { memoize } from "../util/function";
import { chunkedJsonl } from "../util/generator";

// TODO: discover dynamically
export const knownLogGroups = [
    "/aws/lambda/step-functions-step-1-append-invoice",
    // "/aws/lambda/step-functions-step-1-append-invoice-dev",
    // "/aws/lambda/step-functions-step-1-append-invoice-high-memory",
    // "/aws/lambda/step-functions-step-1-b-wait-long-processes",
    // "/aws/lambda/step-functions-step-1-b-wait-long-processes-arm64",
    // "/aws/lambda/step-functions-step-1-b-wait-long-processes-dev-arm64",
    "/aws/lambda/step-functions-step-2-b-consolidate-predictions",
    // "/aws/lambda/step-functions-step-2-b-consolidate-predictions-arm64",
    // "/aws/lambda/step-functions-step-2-b-consolidate-predictions-arm64-high-memor",
    // "/aws/lambda/step-functions-step-2-b-consolidate-predictions-dev-arm64",
    // "/aws/lambda/step-functions-step-2-b-consolidate-predictions-high-memory",
    "/aws/lambda/step-functions-step-2-predict",
    // "/aws/lambda/step-functions-step-2-predict-arm64",
    // "/aws/lambda/step-functions-step-2-predict-arm64-high-memory",
    // "/aws/lambda/step-functions-step-2-predict-dev-arm64",
    // "/aws/lambda/step-functions-step-2-predict-high-memory",
    "/aws/lambda/step-functions-step-3-write-to-database",
    // "/aws/lambda/step-functions-step-3-write-to-database-dev",
    // "/aws/lambda/step-functions-step-3-write-to-database-high-memory",
    // "/aws/lambda/step-functions-step-a-dynamo-stream",
    // "/aws/lambda/step-functions-step-a-dynamo-stream-event-bridge",
    "/aws/lambda/step-functions-step-b-parse-invoice-api",
    // "/aws/lambda/step-functions-step-b-parse-invoice-api-dev",
    // "/aws/lambda/step-functions-step-b-parse-invoice-api-high-memory",
    "/aws/lambda/step-functions-step-b-parse-invoice-fivaldi",
    // "/aws/lambda/step-functions-step-b-parse-invoice-fivaldi-high-memory",
    "/aws/lambda/step-functions-step-b-parse-invoice-netvisor",
    // "/aws/lambda/step-functions-step-b-parse-invoice-netvisor-high-memory",
    "/aws/lambda/step-functions-step-b-parse-invoice-procountor",
    // "/aws/lambda/step-functions-step-b-parse-invoice-procountor-dev",
    // "/aws/lambda/step-functions-step-b-parse-invoice-procountor-high-memory",
    "/aws/lambda/step-functions-step-b-parse-invoice-tripletex",
    // "/aws/lambda/step-functions-step-b-parse-invoice-tripletex-high-memory",
    "/aws/lambda/step-functions-step-c-decide-if-predict",
    // "/aws/lambda/step-functions-step-c-decide-if-predict-dev",
    // "/aws/lambda/step-functions-step-c-decide-if-predict-high-memory",
    // "/aws/lambda/step-functions-step-models-activate-arm64",
    // "/aws/lambda/step-functions-step-models-activate-dev-arm64",
    // "/aws/lambda/step-functions-step-models-deactivate-arm64",
    // "/api/intg-fennoa-dev-log-group",
    // "/api/intg-fennoa-prod-log-group",
    // "/api/intg-hausvise-prod-log-group",
    // "/api/intg-vismap-dev-log-group",
    // "/api/intg-vismap-prod-log-group",
    // "/ecs/intg-fennoa-dev-log-group",
    // "/ecs/intg-fennoa-prod-log-group",
    "/ecs/intg-hausvise-prod-log-group",
    // "/ecs/intg-vismap-dev-log-group",
    // "/ecs/intg-vismap-prod-log-group",
    // "/ecs/netvisor-dev-log-group",
    "/ecs/netvisor-prod-log-group",
    // "/aws/lambda/return-logic-controller",
    "/aws/lambda/return-logic-fivaldi",
    // "/aws/lambda/return-logic-fivaldi-dev",
    // "/aws/lambda/return-logic-fivaldi-high-memory",
    "/aws/lambda/return-logic-procountor",
    // "/aws/lambda/return-logic-procountor-high-memory",
    "/aws/lambda/return-logic-tripletex",
];

type TimeRange = readonly [start: dayjs.Dayjs, end: dayjs.Dayjs];
type TimeRanges = readonly TimeRange[];

interface LogGroupDescription {
    retentionInDays: number;
    logGroupName: string;
    creationTime: number;
}

const groupDescriptions = memoize(async () => {
    const prefixes = [
        "/aws/lambda/step-functions-step-",
        "/aws/lambda/return-logic-",
        // "/ecs/intg-fennoa-prod-log-group",
        "/ecs/intg-hausvise-prod-log-group",
        // "/ecs/intg-vismap-prod-log-group",
        "/ecs/netvisor-prod-log-group",
    ];
    let res: Record<string, LogGroupDescription> = {};
    // fetch descriptions - note: purposely done sequentially to avoid rate limiting
    for (const prefix of prefixes) {
        const response = await axios.get(
            `${apiBase}/cloudwatch/describe?${qs.stringify({
                logGroupPrefix: prefix,
            })}`,
            {
                headers: {
                    Authorization: await getAuthHeader(),
                },
            },
        );
        const data = response.data.data as LogGroupDescription[];
        res = { ...res, ...R.indexBy(R.prop("logGroupName"), data) };
    }
    console.log(res);
    return res;
});

const filterLogGroupsImpl = (
    groups: string[],
    timeRange: TimeRange,
    now: dayjs.Dayjs,
    descriptions: Awaited<ReturnType<typeof groupDescriptions>>,
): [kept: string[], discarded: string[]] => {
    const kept: string[] = [];
    const discarded: string[] = [];
    for (const group of groups) {
        console.log(
            group,
            descriptions[group]?.retentionInDays ?? 0,
            timeRange[0].format(),
            now.format(),
        );
        const oldest = now.subtract(
            descriptions[group]?.retentionInDays ?? 0,
            "day",
        );
        if (oldest.isBefore(timeRange[0])) {
            kept.push(group);
        } else {
            console.log(
                `Discarding ${group} with retention ${oldest.format()} - ${timeRange[0].format()}`,
            );
            discarded.push(group);
        }
    }
    return [kept, discarded];
};

type FilterLogGroupsResult = [
    range: TimeRange,
    kept: string[],
    discarded: string[],
][];

/**
 * Filters out log groups whose retention policy would not cover the given time ranges
 * @param groups The log groups to filter
 */
const filterLogGroups = async (groups: string[], timeRanges: TimeRanges) => {
    const descriptions = await groupDescriptions();
    // console.log(descriptions);
    const now = dayjs();
    return timeRanges.reduce((acc, range) => {
        return [
            ...acc,
            [range, ...filterLogGroupsImpl(groups, range, now, descriptions)],
        ] as FilterLogGroupsResult;
    }, [] as FilterLogGroupsResult);
};

export type GetLogsInput = {
    /**
     * The start time of the logs to fetch
     */
    timeRanges: TimeRanges;
    /**
     * The log groups to fetch logs from
     */
    logGroups: string[];
} & (
    | {
          /**
           * Equivalent to querying cloudwatch with `| filter @message like ...`
           */
          messageLike: string;
          requestId?: never;
      }
    | {
          /**
           * Equivalent to querying cloudwatch with `| filter @requestId = ...`
           */
          requestId: string;
          messageLike?: never;
      }
);

export interface LogEntry {
    log: string;
    time: number;
    message?: string;
    requestId?: string;

    /**
     * This is used with non-lambdas. With lambdas, query by requestId.
     */
    logs?: LogEntry[];
}

const maxTime = (a: dayjs.Dayjs, b: dayjs.Dayjs) =>
    a.valueOf() > b.valueOf() ? a : b;

export async function* getLogs(input: GetLogsInput) {
    const cancelTokenSource = axios.CancelToken.source();
    const times = [...input.timeRanges];

    // merge overlapping ranges
    times.sort(([a], [b]) => a.valueOf() - b.valueOf());
    for (let i = 0; i < times.length - 1; ) {
        if (times[i]![1].isAfter(times[i + 1]![0])) {
            times[i] = [times[i]![0], maxTime(times[i]![1], times[i + 1]![1])];
            times.splice(i + 1, 1);
        } else {
            ++i;
        }
    }

    // filter log groups
    const filterRes = await filterLogGroups(input.logGroups, times);
    const discarded = R.uniq(filterRes.flatMap(([, , discarded]) => discarded));
    if (discarded.length) {
        notification.warning({
            message: "Kaikkia lokeja ei voitu hakea",
            description: `Seuraavien lokiryhmien retentiopolitiikka ei käsitä tarpeeksi pitkää aikaväliä: ${discarded
                .map((x) => x.split("/").at(-1))
                .join(", ")}`,
            duration: 30,
        });
    }

    // fetch logs - note: purposely done sequentially to avoid rate limiting
    for (const [[startTime, endTime], logGroups] of filterRes) {
        const request: AxiosRequestConfig = {
            method: "GET",
            url: `${apiBase}/cloudwatch/query?${qs.stringify(
                {
                    ...R.omit(["timeRanges", "logGroups"], input),
                    logGroups,
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                },
                { arrayFormat: "repeat" },
            )}`,
            headers: {
                Authorization: await getAuthHeader(),
            },
            cancelToken: cancelTokenSource.token,
        };
        try {
            const res = (
                await Array.fromAsync(chunkedJsonl<LogEntry>(request))
            ).flat(1);
            const nonLambda = (x: LogEntry) => !/\blambda\b/.test(x.log);
            const grouped = R.groupWith(
                (x, y) => nonLambda(x) && nonLambda(y) && x.log === y.log,
                res,
            );
            yield grouped.map((group): LogEntry => {
                if (nonLambda(group[0]!)) {
                    group = group.sort((a, b) => a.time - b.time);
                    return {
                        ...group[0]!,
                        logs: group,
                    };
                }
                return group[0]!;
            });
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response?.status === 404) continue;
            }
            cancelTokenSource.cancel();
            throw e;
        }
    }
}
