import { throw_ } from "./function";

const factory =
  (flags: string) =>
  (strings: TemplateStringsArray, ...values: any[]) => {
    const pattern = String.raw(strings, ...values);
    let res = "";
    for (const line of pattern.split("\n")) {
      const match =
        /^\s*(.+?\S)?\s*(?:#.*)?$/.exec(line) ??
        throw_("Invalid pattern line: " + line);
      res += match[1] ?? "";
    }
    return new RegExp(res, flags);
  };

/**
 * This namespace contains functions to create regular expressions with
 * different flags.
 * The patterns are stripped of leading and trailing whitespace and comments.
 */
export namespace re {
  export const _ = factory("");
  export const g = factory("g");
  export const gi = factory("gi");
  export const gm = factory("gm");
  export const gs = factory("gs");
  export const gx = factory("gx");
  export const gim = factory("gim");
  export const gis = factory("gis");
  export const gix = factory("gix");
  export const gms = factory("gms");
  export const gmx = factory("gmx");
  export const gsx = factory("gsx");
  export const gims = factory("gims");
  export const gimx = factory("gimx");
  export const gisx = factory("gisx");
  export const gmsx = factory("gmsx");
  export const gimsx = factory("gimsx");
  export const i = factory("i");
  export const m = factory("m");
  export const s = factory("s");
  export const x = factory("x");
  export const im = factory("im");
  export const is = factory("is");
  export const ix = factory("ix");
  export const ms = factory("ms");
  export const mx = factory("mx");
  export const sx = factory("sx");
  export const ims = factory("ims");
  export const imx = factory("imx");
  export const isx = factory("isx");
  export const msx = factory("msx");
  export const imsx = factory("imsx");
}
