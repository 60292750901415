import axios from "axios";
import dayjs from "dayjs";
import qs from "qs";
import * as R from "ramda";
import {
    apiBase,
    APIResponse,
    getAuthHeader,
    isApiError,
    notifyError,
} from ".";
import { Client, Invoice } from "../interfaces";
import {
    InvoiceAuditLog,
    InvoiceChangeLog,
    InvoiceFetchStatus,
    InvoiceLog,
    S3VersionedObject,
} from "../interfaces/InvoiceLog";
import { throw_ } from "../util/function";
import { combine, paginatedGet, yieldApiErrors } from "../util/generator";
import { getClientByID, getClients } from "./clients";
import { getLogs, knownLogGroups, LogEntry } from "./logs";
import {
    getInvoiceS3Prefix,
    getInvoiceS3Versions,
    getManualRuleS3Versions,
} from "./s3";

export const getInvoices = async (params: Object): Promise<Invoice[]> => {
    const result: APIResponse<Invoice[]> = await axios({
        method: "GET",
        url: `${apiBase}/invoices?${qs.stringify({
            ...params,
        })}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data || [];
};

export const getInvoiceByAuroraId = async (
    auroraId: number,
): Promise<Invoice> => {
    return (
        await axios.get(`${apiBase}/invoices/${auroraId}`, {
            headers: {
                Authorization: await getAuthHeader(),
            },
        })
    ).data.data;
};

export const deleteInvoice = async (id: number): Promise<void> => {
    await axios({
        method: "DELETE",
        url: `${apiBase}/invoices/${id}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });
};

type GetInvoiceLogParams = (
    | { auroraId: number }
    | (({ invoiceId: string } | { invoiceNumber: string }) &
          ({ clientId: number } | { integrationKey: string }))
) & { startDate?: string; logGroups?: string[]; endDate?: string };

async function resolveInvoiceLogParams(params: GetInvoiceLogParams) {
    let invoices =
        "auroraId" in params
            ? [await getInvoiceByAuroraId(params.auroraId)]
            : [];
    let invoiceId =
        "auroraId" in params
            ? invoices[0]!.invoiceId
            : "invoiceId" in params
              ? params.invoiceId
              : undefined;
    let invoiceNumber =
        "auroraId" in params
            ? invoices[0]!.invoiceNumber
            : "invoiceNumber" in params
              ? params.invoiceNumber
              : undefined;

    const client =
        ("integrationKey" in params
            ? (await getClients({ integrationKey: params.integrationKey }))
                  .items[0]
            : await getClientByID(
                  "auroraId" in params
                      ? invoices[0]!.clientId!
                      : params.clientId,
              )) ?? throw_("failed to get queried client where");
    const clientId = client.id;
    for (const invoice of "invoiceNumber" in params
        ? await getInvoices({ clientId, invoiceNumber })
        : await getInvoices({ clientId, invoiceId })) {
        invoices.push(await getInvoiceByAuroraId(invoice.id));
    }
    invoiceId = invoices[0]?.invoiceId;

    return {
        ...params,
        invoices: R.uniqBy(R.prop("id"), invoices),
        invoicePrefix: getInvoiceS3Prefix(client, invoiceId),
        auroraIds: Array.from(new Set(invoices?.map(R.prop("id")) ?? [])),
        clientId,
        invoiceId: invoiceId ?? invoices[0]?.invoiceId,
        invoiceNumber: invoiceNumber ?? invoices[0]?.invoiceNumber,
    };
}

type ResolvedInvoiceLogParams = Awaited<
    ReturnType<typeof resolveInvoiceLogParams>
>;

export async function* getInvoiceLog(
    params: GetInvoiceLogParams,
): AsyncGenerator<InvoiceLog[]> {
    let resolvedParams = await resolveInvoiceLogParams(params);
    let {
        invoices,
        auroraIds,
        clientId,
        invoiceId,
        invoiceNumber,
        invoicePrefix,
    } = resolvedParams;

    // Build queries
    const queries = [
        auroraIds.flatMap((auroraId) => [
            [`${apiBase}/invoices/${auroraId}/actions`, {}],
            [`${apiBase}/invoices/${auroraId}/histories`, {}],
        ]),
        invoiceNumber
            ? [
                  [
                      `${apiBase}/v2/fetchQueue`,
                      {
                          clientIds: clientId,
                          invoiceNumbers: invoiceNumber,
                      },
                  ],
              ]
            : [],
        invoiceId
            ? [
                  [
                      `${apiBase}/v2/fetchQueue`,
                      {
                          clientIds: clientId,
                          invoiceIds: invoiceId,
                      },
                  ],
              ]
            : [],
    ].flat(1) as [string, Record<string, any>][];

    // Perform queries, yielding each intermediate state
    const client = await getClientByID(clientId!);
    const accumulator: InvoiceLog[] = [
        { client },
        ...invoices.map((invoice) => ({ invoice })),
    ];
    yield accumulator;
    const gens: AsyncIterable<
        (
            | InvoiceChangeLog
            | InvoiceAuditLog
            | InvoiceFetchStatus
            | LogEntry
            | { s3Invoice: S3VersionedObject }
            | { s3ManualRule: S3VersionedObject }
        )[]
    >[] = queries.map(([url, mods]) => paginatedGet<any>(url, mods));

    /**
     * If startDate is provided, we use the given explicit date range for logs.
     * Otherwise, wait for invoice_fetch_queue and invoice_actions records to
     * determine the date range.
     */
    const explicitLogs = "startDate" in params;
    if (explicitLogs) {
        const timeRange = [
            dayjs(params.startDate).startOf("day"),
            params.endDate ? dayjs(params.endDate).endOf("day") : dayjs(),
        ] as const;
        gens.push(
            getLogsWrapper(resolvedParams, client, [timeRange], [timeRange]),
        );
    }

    if (invoicePrefix) {
        gens.push(getInvoiceS3Versions(invoicePrefix));
    }

    const manualRulePrefix = client.integrationKey || client.id;
    if (manualRulePrefix) {
        gens.push(getManualRuleS3Versions(String(manualRulePrefix)));
    }

    // Due to doing both invoiceNumber and invoiceId queries, we might get
    // duplicate results => filter them out
    const changeLogDates = new Set<string>();

    const probableProcessStarts: dayjs.Dayjs[] = [];
    const probableReturnStarts: dayjs.Dayjs[] = [];
    const gen = combine(gens.map(yieldApiErrors));
    for await (const res of gen) {
        if (isApiError(res)) {
            notifyError(res);
            continue;
        }
        for (const obj of res) {
            if ("action" in obj) {
                if (obj.action.startsWith("MARK_")) {
                    probableReturnStarts.push(dayjs(obj.createdAt));
                }
                accumulator.push({ auditLog: obj as InvoiceAuditLog });
            } else if ("type" in obj) {
                accumulator.push({ changeLog: obj as InvoiceChangeLog });
            } else if ("log" in obj) {
                accumulator.push({ cloudwatchLog: obj as LogEntry });
            } else if ("invoice" in obj) {
                if (changeLogDates.has(obj.invoice.createdAt)) {
                    continue;
                }
                if (obj.invoice.status === "pending") {
                    probableProcessStarts.push(dayjs(obj.invoice.createdAt));
                }
                changeLogDates.add(obj.invoice.createdAt);
                accumulator.push({
                    fetchStatus: obj as InvoiceFetchStatus,
                });
                if (!invoicePrefix) {
                    invoicePrefix = getInvoiceS3Prefix(
                        client,
                        obj.invoice.invoiceId,
                    );
                    if (invoicePrefix) {
                        gen.addIterable(
                            yieldApiErrors(getInvoiceS3Versions(invoicePrefix)),
                        );
                    }
                }
            } else if ("s3Invoice" in obj) {
                accumulator.push(obj);
                probableProcessStarts.push(
                    dayjs(obj.s3Invoice.version.lastModified),
                );
            } else if ("s3ManualRule" in obj) {
                accumulator.push(obj);
            } else {
                console.error(`unknown object type: ${JSON.stringify(obj)}`);
            }
        }
        yield accumulator;
    }

    if (!explicitLogs) {
        const to30minRange = (x: dayjs.Dayjs) =>
            [x, x.add(30, "minutes")] as const;
        for await (const logs of yieldApiErrors(
            getLogsWrapper(
                resolvedParams,
                client,
                probableProcessStarts.map(to30minRange),
                probableReturnStarts.map(to30minRange),
            ),
        )) {
            if (isApiError(logs)) {
                notifyError(logs);
                continue;
            }
            accumulator.push(...logs.map((x) => ({ cloudwatchLog: x })));
            yield accumulator;
        }
    }
}

type TimeRange = readonly [dayjs.Dayjs, dayjs.Dayjs];

async function* getLogsWrapper(
    { invoiceId, auroraIds, logGroups }: ResolvedInvoiceLogParams,
    client: Client,
    ptimes: readonly TimeRange[],
    rtimes: readonly TimeRange[],
) {
    let [pgroups, rgroups] = logGroups?.length
        ? [logGroups, logGroups]
        : getClientLogGroups(client);
    let messageLike = `\\b${invoiceId}#${
        client.integrationKey || client.id
    }\\b`;
    const accum: LogEntry[] = [];

    // get process logs
    if (ptimes.length) {
        for await (const entries of getLogs({
            messageLike,
            timeRanges: ptimes,
            logGroups: pgroups,
        })) {
            accum.push(...entries);
            yield entries;
        }
    }

    // get return logs
    if (rtimes.length) {
        switch (client.system) {
            case "netvisor":
                messageLike = `--invoices\\.id=(${auroraIds.join("|")})\\b`;
                break;
            default:
                break;
        }
        for await (let entries of getLogs({
            messageLike,
            timeRanges: rtimes,
            logGroups: rgroups,
        })) {
            // prevent duplicates
            entries = R.differenceWith(
                R.eqBy(R.pick(["log", "time", "message"])),
                entries,
                accum,
            );
            accum.push(...entries);
            yield entries;
        }
    }
}

const getClientLogGroups = (
    client: Client,
): [process: string[], return_: string[]] => {
    const process = knownLogGroups.filter(
        (x) =>
            x.startsWith("/aws/lambda/step-functions-step") &&
            !x.includes("step-b-parse-invoice"),
    );
    const return_: string[] = [];
    // res.push("/aws/lambda/return-logic-controller");
    switch (client.system) {
        case "ai-inside":
            process.push("/aws/lambda/step-functions-step-b-parse-invoice-api");
            break;
        case "fivaldi":
            process.push(
                "/aws/lambda/step-functions-step-b-parse-invoice-fivaldi",
            );
            return_.push("/aws/lambda/return-logic-fivaldi");
            break;
        case "netvisor":
            process.push(
                "/aws/lambda/step-functions-step-b-parse-invoice-netvisor",
                "/ecs/netvisor-prod-log-group",
            );
            return_.push("/ecs/netvisor-prod-log-group");
            break;
        case "procountor":
            process.push(
                "/aws/lambda/step-functions-step-b-parse-invoice-procountor",
            );
            return_.push("/aws/lambda/return-logic-procountor");
            break;
        case "tripletex":
            process.push(
                "/aws/lambda/step-functions-step-b-parse-invoice-tripletex",
            );
            return_.push("/aws/lambda/return-logic-tripletex");
            break;
        default:
            console.warn(`unknown system ${client.system}`);
            break;
    }
    switch (client.tags?.["system"]) {
        case "visma-public":
            // res.push('"/ecs/intg-vismap-prod-log-group"');
            break;
        case "fennoa":
            // res.push("/api/intg-fennoa-prod-log-group");
            break;
        case "hausvise":
            process.push("/ecs/intg-hausvise-prod-log-group");
            return_.push("/ecs/intg-hausvise-prod-log-group");
            break;
        default:
            break;
    }
    return [process, return_];
};
