import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import EventIterator from "event-iterator";
import qs from "qs";
import { getAuthHeader, isApiError } from "../api";
import { throw_ } from "./function";

export function yieldApiErrors<R>(iterable: AsyncGenerator<R>) {
    return yieldErrorsWhen(iterable, isApiError);
}

export async function* yieldErrorsWhen<R, T>(
    iterable: AsyncGenerator<R>,
    pred: (x: any) => x is T,
): AsyncGenerator<R | T> {
    try {
        yield* iterable;
    } catch (e) {
        if (pred(e)) {
            yield e;
        } else {
            throw e;
        }
    }
}

export async function* paginatedGet<R>(url: string, mods: Record<string, any>) {
    let page = 1;
    let response: AxiosResponse<any>;
    try {
        do {
            response = await axios.get(
                `${url}?${qs.stringify({ ...mods, page })}`,
                {
                    headers: {
                        Authorization: await getAuthHeader(),
                    },
                },
            );
            if (~~(response.status / 100) !== 2) {
                console.error(response);
                yield [] as R[];
                return;
            }
            yield response.data.data as R[];
            page++;
        } while (response.data.length > 0);
    } catch (e) {
        console.error(e);
        yield [] as R[];
    }
}

export async function* chunkedJsonl<R>(
    config: AxiosRequestConfig,
): AsyncGenerator<R[]> {
    for await (const lns of chunkedLines(config)) {
        const objs: R[] = [];
        for (const ln of lns) {
            try {
                objs.push(JSON.parse(ln) as R);
            } catch (e) {
                console.error(e);
                return null;
            }
        }
        if (objs.length > 0) {
            yield objs;
        }
    }
}

export async function* chunkedLines(config: AxiosRequestConfig) {
    let idx = 0;
    for await (const [accum, final] of chunkedAccum(config)) {
        let lns: string[] = [];
        for (;;) {
            const lf = accum.indexOf("\n", idx);
            let ln: string;
            if (lf === -1) {
                if (final && accum.length > idx) {
                    lns.push(accum.slice(idx));
                }
                break;
            } else {
                ln = accum.slice(idx, lf);
            }
            idx = lf + 1;
            lns.push(ln);
        }
        if (lns.length > 0) {
            yield lns;
        }
    }
}

export function chunkedAccum(config: AxiosRequestConfig) {
    return new EventIterator<[accum: string, final: boolean]>(
        ({ push, stop, fail }) => {
            axios({
                ...config,
                transformResponse: (data, _, code) => {
                    if (code === 200) {
                        return data;
                    }
                    try {
                        return JSON.parse(data);
                    } catch (e) {
                        console.log(data);
                        console.error(e);
                        return data;
                    }
                },
                onDownloadProgress: (event) => {
                    try {
                        if (event.event!.target.status === 200) {
                            push([
                                event.event!.target.response ??
                                    throw_("no response"),
                                false,
                            ]);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                },
            })
                .then((result) => {
                    try {
                        push([result.data ?? throw_("no data"), true]);
                    } catch (e) {
                        console.error(e);
                    }
                })
                .catch(fail)
                .finally(stop);
        },
    );
}

// https://stackoverflow.com/a/50586391
export function combine<R>(iterable: Iterable<AsyncIterable<R>>) {
    const results: R[] = [];
    const asyncIterators = Array.from(iterable, (o) =>
        o[Symbol.asyncIterator](),
    );
    let count = asyncIterators.length;
    const never = new Promise<never>(() => {});
    function getNext<T>(asyncIterator: AsyncIterator<T>, index: number) {
        return asyncIterator.next().then((result) => ({
            index,
            result,
        }));
    }
    const nextPromises = asyncIterators.map(getNext);

    async function* generator() {
        try {
            while (count) {
                const { index, result } = await Promise.race(nextPromises);
                if (result.done) {
                    nextPromises[index] = never;
                    results[index] = result.value;
                    count--;
                } else {
                    nextPromises[index] = getNext(
                        asyncIterators[index]!,
                        index,
                    );
                    yield result.value;
                }
            }
        } finally {
            for (const [index, iterator] of asyncIterators.entries())
                if (nextPromises[index] != never && iterator.return != null)
                    iterator.return();
            // no await here - see https://github.com/tc39/proposal-async-iteration/issues/126
        }
        return results;
    }

    const res = generator() as AsyncGenerator<R> & {
        addIterable: (iterable: AsyncIterable<R>) => void;
    };
    res.addIterable = (iterable: AsyncIterable<R>) => {
        const iterator = iterable[Symbol.asyncIterator]();
        asyncIterators.push(iterator);
        nextPromises.push(getNext(iterator, asyncIterators.length - 1));
        ++count;
    };
    return res;
}
