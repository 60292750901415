import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { ChangeEvent } from "react";

type EditableProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const Editable = (props: EditableProps) => {
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setValue(target.value);
  };

  const handleSave = () => {
    setEditing(false);
    props.onChange(value);
  };

  return (
    <span
      style={{
        display: "inline-flex",
        gap: "0.5rem",
        alignItems: "baseline",
      }}
    >
      {editing ? (
		<>
			<Input
			defaultValue={value}
			autoFocus
			onChange={handleChange}
			placeholder={props.placeholder}
			/>
			<Button onClick={() => setEditing(false)} type="text" icon={<CloseOutlined />} />
			<Button onClick={handleSave} type="text" icon={<CheckOutlined />} />
		</>
      ) : (
        <>
          <span>{props.value || '-'}</span>
          <Button onClick={handleEdit} type="text" icon={<EditOutlined />}></Button>
        </>
      )}
    </span>
  );
};

export default Editable;
