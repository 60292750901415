import { Button, Result } from "antd";
import { ResultStatusType } from "antd/lib/result";
import React from "react";
import { Link } from "react-router-dom";
import { APIError } from "../api";
import { pathNames } from "../components/Breadcrumbs";

const ErrorPage = ({ title, status = 'error', error, backTo }: { title: string, status?: ResultStatusType, error: APIError, backTo?: keyof typeof pathNames }) => {
    return (
        <Result
        title={title}
        status={status}
        subTitle={<pre>
            {error.message}<br />
            {JSON.stringify(error.response?.data.error)}
        </pre>}
        extra={
            backTo && 
            <Link to={`/${backTo}`}>
                <Button type="primary">Palaa {pathNames[backTo]}-sivulle</Button>
            </Link>
        }
    />
    );
};

export default ErrorPage;
