import { PlusOutlined } from "@ant-design/icons";
import { Alert, Badge, Button, Input, message, Modal, Space, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useMemo, useState } from "react";
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { createUser, CreateUserInput, getUsers } from "../api/users";
import Header from "../components/Header";
import Identifier from "../components/Identifier";
import { User, UserOrganization } from "../interfaces";
import { renderDateTime } from "../util/datetime";
import { debounce } from 'throttle-debounce';
import { SorterResult } from "antd/lib/table/interface";
import UserForm from "../forms/UserForm";
import { roles } from "../util/constants";
import { APIError } from "../api";
import { userParameters } from "../atoms";
import { sortOrder } from "../util/table";

const Users = () => {
    const [params, setParams] = useRecoilState(userParameters)
    const [formVisible, setFormVisible] = useState(false);
    const { data: users, isLoading, remove: removeUserCache, isPreviousData } = useQuery(['users', params], () => getUsers(params), {
        keepPreviousData: true,
    });
    const createUserMutation = useMutation<User, APIError, CreateUserInput>((userInput: CreateUserInput) => createUser(userInput), {
        onSuccess: (created, input) => {
            setFormVisible(false);
            removeUserCache();
            setParams({ ...params, page: 1 });
            message.success("Käyttäjä luotu onnistuneesti");
            if (created.mailchimpId) {
                message.success(`Mailchimp-kontakti luotu käyttäjälle`);
            } else if (input.createMailchimpContact) {
                message.warning(`Mailchimp-kontaktia ei luotu käyttäjälle!`);
            }
        }
    });

    const userColumns = useMemo(() => [
        {
            title: "Nimi",
            dataIndex: "name",
            key: "name",
            render: (name: string, user: User) => {
                const role = roles.find(role => role.value === user.role && role.value !== 'ORGANIZATION_USER');
                return (
                    <>
                        <Link to={`/users/${user.uid}`}>
                            <Text>{name}</Text>
                        </Link>
                        {role &&
                            <Tooltip title={role.label}>
                            <Badge
                                style={{ marginLeft: 4 }} 
                                color={role.color}
                            />
                        </Tooltip>
                        }
                    </>
                )
            },
        },
        {
            title: "Sähköposti",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Organisaatio",
            dataIndex: "organization",
            key: "organization",
            render: (organization?: UserOrganization) => organization?.name || "-",
        },
        {
            title: "UID",
            dataIndex: "uid",
            key: "uid",
            render: (uid: string) => <Identifier type="secondary" code={false}>{uid}</Identifier>,
        },
        {
            title: "Luotu",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: string) => <Text type="secondary">{renderDateTime(createdAt)}</Text>,
            sorter: true,
            sortOrder: sortOrder(params, 'createdAt'),
        }
    ], [params]);

    const onTableChange = (pagination: any, _: never, sorter: SorterResult<User>) => {
        const { field, order } = sorter;
        const { current, pageSize } = pagination;

        setParams({
            ...params,
            orderBy: order ? field as string : undefined,
            order: order == "ascend" ? "asc" : "desc",
            page: current,
            limit: pageSize
        });
    };

    const onSearch = debounce(250, (value: string) => {
        setParams({ ...params, query: value, page: 1 });
    });

    const onFormSubmit = (values: CreateUserInput) => {
        createUserMutation.mutate(values);
    };

    return (
        (<Content>
            <Header />
            <Space align="center" size="large" style={{ margin: '16px 0 24px 0' }}>
                <Title style={{ margin: 0 }} level={2}>Käyttäjät</Title>
                <Button onClick={() => setFormVisible(true)} type="primary" icon={<PlusOutlined />}>Uusi käyttäjä</Button>
            </Space>
            {createUserMutation.isError && <Alert message={createUserMutation.error.message} description={<pre>
                {createUserMutation.error.response?.data.error}
            </pre>} type="error" />}
            <Input
                placeholder="Hae nimellä, sähköpostilla tai UID:lla..."
                onChange={(e) => onSearch(e.target.value)}
            />
            <Table
                style={{ marginTop: 16 }}
                bordered
                loading={isLoading || isPreviousData}
                dataSource={users?.items}
                footer={() => users ? <div>{users?.totalCount} tulosta</div> : null}
                rowKey="uid"
                columns={userColumns}
                pagination={{
                    total: users?.totalCount,
                    pageSize: params.limit,
                    current: params.page,
                    showSizeChanger: true,
                }}
                onChange={onTableChange}
            />
            <Modal destroyOnClose title="Uusi käyttäjä" open={formVisible} onCancel={() => setFormVisible(false)} footer={null}>
                <UserForm onSubmit={onFormSubmit} submitting={createUserMutation.isLoading} />
            </Modal>
        </Content>)
    );
};

export default Users;
