import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Popover,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  Cell,
  Tooltip as ChartTooltip,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import { no404Retry, notifyError } from "../api";
import { getBillingPlans } from "../api/billingPlans";
import { getSystemLabel, updateClient } from "../api/clients";
import { getOrganizationByID, updateOrganization } from "../api/organizations";
import { createTeam, getTeams } from "../api/teams";
import BackLink from "../components/BackLink";
import BillingPlanRecordTable from "../components/BillingPlanRecordTable";
import Editable from "../components/Editable";
import Header from "../components/Header";
import Identifier from "../components/Identifier";
import Reporting from "../components/Reporting";
import SystemTag from "../components/SystemTag";
import TagEditor from "../components/TagEditor";
import TeamCard from "../components/TeamCard";
import { Member, Organization, OrganizationClient, Team } from "../interfaces";
import countryMapper from "../interfaces/countryMapper";
import { systemColors } from "../util/constants";
import { renderDateTime } from "../util/datetime";

const memberColumns = [
  {
    title: "Nimi",
    dataIndex: "name",
    key: "name",
    sorter: (a: Member, b: Member) => a.name.localeCompare(b.name),
    render: (name: string, member: Member) => (
      <Link to={`/users/${member.uid}`}>
        <Text>{name}</Text>
      </Link>
    ),
  },
  {
    title: "Sähköposti",
    dataIndex: "email",
    key: "email",
    render: (email: string) => (
      <Text copyable={{ tooltips: false }}>{email}</Text>
    ),
    sorter: (a: Member, b: Member) => a.email.localeCompare(b.email),
  },
  {
    title: "UID",
    dataIndex: "uid",
    key: "uid",
    render: (uid: string) => (
      <Identifier code={false} type="secondary">
        {uid}
      </Identifier>
    ),
  },
  {
    title: "Rooli",
    dataIndex: "role",
    key: "role",
    width: 150,
    render: (role: string) => (
      <Tag color={role === "admin" ? "purple" : ""}>
        {role === "admin" ? "Ylläpitäjä" : "Jäsen"}
      </Tag>
    ),
    filters: [
      { text: "Ylläpitäjä", value: "admin" },
      { text: "Jäsen", value: "member" },
    ],
    onFilter: (value: string, record: Member) => record.role === value,
  },
];

const { TabPane } = Tabs;

const OrganizationDetails = () => {
  const organizationId = parseInt(useParams().id || "0");

  const [searchParams, setSearchParams] = useSearchParams();

  const [teamSearchQuery, setTeamSearchQuery] = useState("");

  const [nameEditorState, setNameEditorState] = useState({
    visible: false,
    name: "",
  });

  const [newTeamModal, setNewTeamModal] = useState(false);

  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["organization", organizationId],
    () => getOrganizationByID(organizationId),
    {
      retry: no404Retry,
      onError: notifyError,
    }
  );

  const teamsQuery = useQuery("teams", () => getTeams().then((c) => c.items), {
    retry: no404Retry,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  const organizationTeams = useMemo(
    () =>
      teamsQuery.data
        ? teamsQuery.data.filter(
            (team) =>
              team.organizationId === organizationId &&
              team.name.toLowerCase().includes(teamSearchQuery.toLowerCase())
          )
        : [],
    [teamsQuery.data, teamSearchQuery]
  );

  const billingPlansQuery = useQuery("billingPlans", () =>
    getBillingPlans().then((c) => c.items)
  );

  const queryClient = useQueryClient();

  const organizationMutation = useMutation(
    (updates: Partial<Organization>) =>
      updateOrganization(organizationId, updates),
    {
      onSuccess: (organization) => {
        message.success("Organisaation tiedot päivitetty");
        queryClient.setQueryData(
          ["organization", organizationId],
          organization
        );
      },
      onError: notifyError,
    }
  );

  const removeClientFromOrganizationMutation = useMutation(
    (clientId: number) => updateClient(clientId, { organizationId: null }),
    {
      onSuccess: (_, clientId) => {
        queryClient.setQueryData(
          ["organization", organizationId],
          (prev: Organization) => ({
            ...prev,
            clients: prev.clients.filter((c) => c.id !== clientId),
          })
        );
        queryClient.invalidateQueries(["organization", organizationId]);
        message.info("Asiakas poistettu organisaatiosta.");
      },
      onError: notifyError,
    }
  );

  const handleRemoveFromOrganization = useCallback(async (id: number) => {
    removeClientFromOrganizationMutation.mutate(id);
  }, []);

  const systemDistribution = useMemo(
    () =>
      Object.entries(
        (organization?.clients || []).reduce(
          (acc, cur) => ({
            ...acc,
            [cur.accountingSystem]: (acc[cur.accountingSystem] || 0) + 1,
          }),
          {} as { [key: string]: number }
        )
      )
        .map(([accountingSystem, count]) => ({
          name: getSystemLabel(accountingSystem),
          color: systemColors[accountingSystem]?.color ?? "gray",
          value: count,
        }))
        .sort((a, b) => a.value - b.value),
    [organization]
  );

  // Columns for the OrganizationClient[] table
  const clientColumns = [
    {
      title: "Nimi",
      dataIndex: "name",
      key: "name",
      sorter: (a: OrganizationClient, b: OrganizationClient) =>
        a.name.localeCompare(b.name),
      render: (name: string, client: OrganizationClient) => (
        <Link to={`/clients/${client.id}`}>
          <Text>{name}</Text>
        </Link>
      ),
    },
    {
      title: "Järjestelmä",
      dataIndex: "accountingSystem",
      key: "accountingSystem",
      render: (system: string) =>
        system ? <SystemTag system={system} /> : "-",
    },
    {
      title: "Itseohjautuva automaatio",
      dataIndex: "progressiveAutomationEnabled",
      key: "progressiveAutomationEnabled",
      sorter: (a: OrganizationClient, b: OrganizationClient) =>
        (a.progressiveAutomationEnabled ? 1 : 0) -
        (b.progressiveAutomationEnabled ? 1 : 0),
      render: (progressiveAutomationEnabled: boolean) =>
        progressiveAutomationEnabled ? (
          <Tag color="green">Kyllä</Tag>
        ) : (
          <Tag color="red">Ei</Tag>
        ),
    },
    {
      title: "Toiminnot",
      key: "actions",
      render: (client: OrganizationClient) => (
        <Space size="middle">
          <Popconfirm
            placement="bottom"
            title="Oletko varma?"
            okText="Kyllä"
            cancelText="Ei"
            onConfirm={() => handleRemoveFromOrganization(client.id)}
          >
            <Tooltip title="Poista organisaatiosta">
              <Button danger size="small" icon={<CloseOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (isError)
    return (
      <Result
        title={`Virhe haettaessa organisaatiota ${organizationId}`}
        status="error"
        subTitle={
          <pre>
            {error.message}
            <br />
            {JSON.stringify(error.response?.data.error)}
          </pre>
        }
        extra={
          <Link to="/organizations">
            <Button type="primary">Palaa organisaatiot-sivulle</Button>
          </Link>
        }
      />
    );

  const countryOptions = Object.values(countryMapper).map((country) => {
    return {
      label: country.name.fi,
      value: country.alpha2,
    };
  });

  return (
    <Content>
      <Header resolver={() => organization?.name} />
      <Space size="middle" style={{ marginBottom: 24, width: "100%" }}>
        <BackLink to="/organizations" />
        <Title level={2} style={{ margin: 0 }}>
          {organization?.name}
        </Title>
        <Popover
          title="Muokkaa organisaation nimeä"
          trigger="click"
          visible={nameEditorState.visible}
          onVisibleChange={(visible) =>
            setNameEditorState({ visible, name: organization!.name })
          }
          placement="bottom"
          content={
            <>
              <Input
                disabled={organizationMutation.isLoading}
                value={nameEditorState.name}
                onChange={(e) =>
                  setNameEditorState({ visible: true, name: e.target.value })
                }
              />
              <Button
                type="primary"
                loading={organizationMutation.isLoading}
                onClick={() =>
                  organizationMutation.mutate(
                    { name: nameEditorState.name },
                    {
                      onSuccess: () => {
                        setNameEditorState({ visible: false, name: "" });
                        message.success("Organisaation nimi päivitetty");
                      },
                      onError: notifyError,
                    }
                  )
                }
                style={{ marginTop: 8 }}
              >
                Tallenna
              </Button>
            </>
          }
        >
          <Button type="text" icon={<EditOutlined />}></Button>
        </Popover>
      </Space>
      <Space size="large" style={{ width: "100%" }}>
        <Descriptions bordered column={2} size="middle">
          <Descriptions.Item label="ID">
            <Identifier code={false}>{organization?.id}</Identifier>
          </Descriptions.Item>
          <Descriptions.Item label="Firebasen ID">
            <Identifier>{organization?.key}</Identifier>
          </Descriptions.Item>
          <Descriptions.Item label="Y-tunnus">
            <Editable
              placeholder="FI1234567"
              value={organization?.businessId || ""}
              onChange={(value) =>
                organizationMutation.mutate({ businessId: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Luotu">
            {renderDateTime(organization?.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Hinnoittelumalli">
            <Tooltip title={organization?.billingPlanId}>
              {billingPlansQuery.data?.find(
                (plan) => plan.id === organization?.billingPlanId
              )?.description || "-"}
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Päivitetty">
            {renderDateTime(organization?.updatedAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Katuosoite" span={2}>
            <Editable
              placeholder="Katuosoite"
              value={organization?.streetAddress || ""}
              onChange={(value) =>
                organizationMutation.mutate({ streetAddress: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Kaupunki" span={2}>
            <Editable
              placeholder="Helsinki"
              value={organization?.city || ""}
              onChange={(value) => organizationMutation.mutate({ city: value })}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Postinumero">
            <Editable
              placeholder="00100"
              value={organization?.zipCode || ""}
              onChange={(value) =>
                organizationMutation.mutate({ zipCode: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Maa">
            <Select
              showSearch
              popupMatchSelectWidth={false}
              optionFilterProp="label"
              placeholder="Valitse maa"
              style={{ width: "100%" }}
              options={countryOptions}
              value={organization?.country}
              onChange={(value) =>
                organizationMutation.mutate({ country: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Laskutuksen sähköposti" span={2}>
            <Editable
              placeholder="matti.meikäläinen@gmail.com"
              value={organization?.billingEmail || ""}
              onChange={(value) =>
                organizationMutation.mutate({ billingEmail: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Laskutusviite" span={2}>
            <Editable
              placeholder="123456789"
              value={organization?.billingReference || ""}
              onChange={(value) =>
                organizationMutation.mutate({ billingReference: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Verkkolaskuosoite" span={2}>
            <Editable
              placeholder="003701234567"
              value={organization?.einvoiceAddress || ""}
              onChange={(value) =>
                organizationMutation.mutate({ einvoiceAddress: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Verkkolaskuoperaattori" span={2}>
            <Editable
              placeholder="003721234567"
              value={organization?.einvoiceOperator || ""}
              onChange={(value) =>
                organizationMutation.mutate({ einvoiceOperator: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Tagit" span={2}>
            <TagEditor
              tags={Object.entries(organization?.tags || {}).map(
                ([key, value]) => `${key}=${value}`
              )}
              onChange={(tags) =>
                organizationMutation.mutate({
                  tags: tags.reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur.split("=")[0]!]: cur.split("=")[1],
                    }),
                    {}
                  ),
                })
              }
              validate={(tag: string) => tag.split("=").length === 2}
              loading={
                organizationMutation.isLoading &&
                !!organizationMutation.variables?.tags
              }
              inputStyle={{ width: 200 }}
              color={(value) =>
                value.startsWith("aggregateKey") ? "geekblue" : undefined
              }
            />
          </Descriptions.Item>
        </Descriptions>
        <Divider type="vertical" />
        <Statistic
          suffix="kpl"
          title="Asiakkaita"
          value={organization?.clients.length}
        />
        <Divider type="vertical" />
        <Statistic
          suffix="kpl"
          title="IA käytössä"
          value={
            (organization?.clients || []).filter(
              (c) => c.progressiveAutomationEnabled
            ).length
          }
        />
        <Divider type="vertical" />
        <Statistic
          suffix="kpl"
          title="Käyttäjiä"
          value={organization?.members.length}
        />
        <Divider type="vertical" />
        <Statistic
          suffix="kpl"
          title="Tiimejä"
          value={organization?.teams.length}
        />
      </Space>

      <Tabs
        defaultActiveKey={searchParams.get("tab") || undefined}
        style={{ marginTop: 32 }}
        onChange={(key) => {
          searchParams.set("tab", key);
          setSearchParams(searchParams);
        }}
      >
        <TabPane tab="Asiakkaat" key="clients">
          <Table
            columns={clientColumns}
            rowKey="id"
            dataSource={organization?.clients.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            bordered
            size="middle"
          />
        </TabPane>

        <TabPane tab="Jäsenet" key="members">
          <Table
            columns={memberColumns}
            rowKey="uid"
            dataSource={organization?.members}
            bordered
            size="middle"
          />
        </TabPane>
        <TabPane tab="Tiimit" key="teams">
          <div style={{ display: "flex", marginBottom: 16 }}>
            <Button
              onClick={() => setNewTeamModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Luo uusi tiimi
            </Button>
            <Input
              allowClear
              style={{ marginLeft: 16, width: 300 }}
              placeholder="Hae..."
              value={teamSearchQuery}
              onChange={(e) => setTeamSearchQuery(e.target.value)}
            />
          </div>
          <NewTeamModal
            organizationId={organizationId}
            visible={newTeamModal}
            onHide={() => setNewTeamModal(false)}
          />
          {teamsQuery.isError && (
            <Alert
              message={teamsQuery.error.message}
              description={teamsQuery.error.response?.data.error}
              type="error"
            />
          )}
          <List
            grid={{
              gutter: 32,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            loading={teamsQuery.isLoading || isLoading}
            dataSource={organizationTeams}
            renderItem={(team) => (
              <List.Item>
                <TeamCard team={team} organization={organization!} />
              </List.Item>
            )}
          />
        </TabPane>

        <TabPane tab="Hinnoittelumalli" key="billingPlanRecords">
          {organization?.id && (
            <BillingPlanRecordTable organizationId={organization?.id} />
          )}
        </TabPane>

        <TabPane tab="Analytiikka" key="analytics">
          <Row gutter={16}>
            <Col span={16}>
              <Card title="Asiakkaat järjestelmittäin" size="small">
                <ResponsiveContainer height={350} width="100%">
                  <PieChart>
                    <Legend
                      align="left"
                      layout="vertical"
                      verticalAlign="middle"
                    />
                    <ChartTooltip />
                    <Pie
                      data={systemDistribution}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={80}
                      outerRadius={140}
                      fill="#82ca9d"
                      label
                      animationBegin={100}
                      animationDuration={1000}
                    >
                      {systemDistribution.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Raportit" key="reports">
          <Reporting organizationId={organizationId} />
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default OrganizationDetails;

const NewTeamModal = (props: {
  organizationId: number;
  visible: boolean;
  onHide: () => void;
}) => {
  const { organizationId, visible, onHide } = props;

  const [name, setName] = useState("");

  useEffect(() => {
    setName("");
  }, [visible]);

  const queryClient = useQueryClient();

  const createTeamMutation = useMutation(
    (name: string) =>
      createTeam({ organizationId, name, clients: [], members: [], tags: {} }),
    {
      onSuccess: (team) => {
        queryClient.setQueryData("teams", (teams: Team[]) => [...teams, team]);
        queryClient.setQueryData(
          ["organization", organizationId],
          (prev: Organization) => ({ ...prev, teams: [...prev.teams, team.id] })
        );
        queryClient.invalidateQueries(["organization", organizationId]);
        message.success("Tiimi luotu onnistuneesti.");
      },
      onError: notifyError,
    }
  );

  return (
    <Modal
      confirmLoading={createTeamMutation.isLoading}
      title="Uusi tiimi"
      okText="Luo tiimi"
      onOk={() => createTeamMutation.mutateAsync(name).then(onHide)}
      open={visible}
      onCancel={onHide}
    >
      <Form>
        <Form.Item
          label="Nimi"
          name="name"
          rules={[{ required: true, message: "Pakollinen kenttä" }]}
        >
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Anna tiimille nimi"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
