import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from 'react';
import Header from "../components/Header";
import Title from "antd/lib/typography/Title";
import { message, Space } from "antd";
import UIMessageForm from "../forms/UIMessageForm";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { UIMessageInput } from "../interfaces";
import { deleteUIMessage, getUIMessage, updateUIMessage } from "../api";
import BackLink from "../components/BackLink";

function EditUIMessage() {
    const navigate = useNavigate();
    const messageId = parseInt(useParams().id!);
    const updateUIMessageMutation = useMutation((message: UIMessageInput) => updateUIMessage(messageId, message));
    const deleteUIMessageMutation = useMutation((id: number) => deleteUIMessage(id));
    const { data: uiMessage, isLoading } = useQuery(['uiMessage', messageId], () => getUIMessage(messageId));

    useEffect(() => {
        if (updateUIMessageMutation.isSuccess) {
            message.success('Tiedote tallennettu');
            navigate('/uiMessages');
        }
    }, [navigate, updateUIMessageMutation.isSuccess]);

    useEffect(() => {
        if (deleteUIMessageMutation.isSuccess) {
            message.success('Tiedote poistettu');
            navigate('/uiMessages');
        }
    }, [navigate, deleteUIMessageMutation.isSuccess]);

    return (
        <Content>
            <Header />
            <Space style={{ marginBottom: 24 }} align="center">
                <BackLink to="/uiMessages" />
                <Title level={2} style={{ margin: 0 }}>
                    Muokkaa UI-tiedotetta
                </Title>
            </Space>
            <UIMessageForm
                onSubmit={updateUIMessageMutation.mutate}
                onDelete={() => deleteUIMessageMutation.mutate(messageId)}
                submitting={updateUIMessageMutation.isLoading}
                loading={isLoading}
                initialData={uiMessage}
            />
        </Content>
    );
};

export default EditUIMessage;
