import React from "react";
import { EInvoiceAddress } from "../interfaces";

interface EInvoiceAddressProps {
    address: EInvoiceAddress
}

const addressItems: { label: string, value: keyof EInvoiceAddress }[] = [
    { label: 'Nimi', value: 'name' },
    { label: 'Osoite', value: 'address' },
    { label: 'Y-tunnus', value: 'businessId' },
    { label: 'Operaattorin nimi', value: 'operatorName' },
    { label: 'Operaattorin osoite', value: 'operatorAddress' },
    { label: 'Lisätiedot', value: 'extraInfo' },
];

const EInvoiceAddress = (props: EInvoiceAddressProps) => {
    return (
            <div style={{ maxWidth: 500 }}>
                {addressItems.filter(item => {
                    const value = props.address[item.value];
                    if (!value) {
                        return false;
                    } else if (typeof value === 'string') {
                        return value.length > 0;
                    } else {
                        return Object.keys(value).length > 0;
                    }
                }).map(item => (
                    typeof props.address[item.value] === 'string'
                    ? <div key={item.value}><b>{item.label}:</b> {props.address[item.value]}</div>
                    : <div key={item.value}><b>{item.label}:</b> {Object.values(props.address[item.value] || {}).join(' / ')}</div>
                ))}
            </div>
    );
};

export default EInvoiceAddress;
