import axios from "axios";
import { apiBase, APIResponse, Collection, getAuthHeader } from ".";
import { Team, TeamInput } from "../interfaces";

export const getTeams = async (): Promise<Collection<Team>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/teams`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}

export const getTeamByID = async (id: number): Promise<Team> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/teams/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
}

export const createTeam = async (team: TeamInput): Promise<Team> => {
    const result = await axios({
        method: "POST",
        url: `${apiBase}/teams`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: team,
    });

    return result.data.data;
}

export const updateTeam = async (id: number, data: Partial<Team>): Promise<Team> => {
    const result: APIResponse<Team> = await axios({
        method: "PATCH",
        url: `${apiBase}/teams/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
}

export const deleteTeam = async (id: number): Promise<void> => {
    await axios({
        method: "DELETE",
        url: `${apiBase}/teams/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}
