import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Select, Space, Tooltip } from "antd";
import { BaseOptionType } from "antd/lib/select";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateClient } from "../api/clients";
import { getOrganizations } from "../api/organizations";
import { Client } from "../interfaces";

const OrganizationSwitcher = (props: { client?: Client, clients?: Client[] }) => {
    const { client, clients = [] } = props;

    if (clients.length === 0 && client) clients.push(client);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(undefined);

    const queryClient = useQueryClient();
    const { data: organizations, isLoading } = useQuery("organizations", () => getOrganizations());
    const updateClientOrganization = useMutation((org: number | null) => Promise.all(clients.map(
        client => updateClient(client.id, { organizationId: org })
    )), {
        onSuccess: (clients, orgId) => {
            clients.forEach(client => {
                queryClient.setQueryData(['client', client.id], client);
            });

            queryClient.invalidateQueries('clients');

            if (orgId) {
                message.success("Lisätty organisaatioon");
            } else {
                message.info("Poistettu organisaatiosta");
            }
            setSelectedOrganizationId(undefined);
        }
    })

    if (client && client.organization) {
        return (
            <Tooltip title="Poista organisaatiosta">
                <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => updateClientOrganization.mutate(null)}
                    okText="Kyllä"
                    cancelText="Ei"
                    placement="bottom"
                >
                    <Button
                        style={{ margin: '4px 0' }}
                        loading={updateClientOrganization.isLoading}
                        size="small"
                        icon={<CloseOutlined />}
                    />
                </Popconfirm>
            </Tooltip>
        );
    }

    if (!clients.every(client => client.organizationId === null)) {
        return (
                <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => updateClientOrganization.mutate(null)}
                    okText="Kyllä"
                    cancelText="Ei"
                    placement="bottom"
                >
                    <Button
                        style={{ margin: '4px 0' }}
                        loading={updateClientOrganization.isLoading}
                        icon={<CloseOutlined />}
                    >
                        Poista organisaatiosta    
                    </Button>
                </Popconfirm>
        );
    }

    return (
        <Space align="center">
            <Select
                disabled={!clients.length}
                placeholder="Lisää organisaatioon"
                onChange={(value: number) => setSelectedOrganizationId(value)}
                loading={isLoading}
                value={selectedOrganizationId}
                showSearch
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                filterOption={(input: string, option: BaseOptionType) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: 230 }}
                allowClear
            >
                {organizations?.items.map(org => (
                    <Select.Option key={org.id} value={org.id}>
                        {org.name}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip title="Lisää organisaatioon">
                <Button
                    icon={<CheckOutlined />}
                    loading={updateClientOrganization.isLoading}
                    disabled={!selectedOrganizationId}
                    onClick={() => updateClientOrganization.mutate(selectedOrganizationId!)}
                />
            </Tooltip>
        </Space>
    );
}

export default OrganizationSwitcher;
