import { CheckOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Descriptions, InputNumber, message, Select, Space } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateClientAutomation } from "../api/clients";
import { Approval } from "../interfaces";
import { renderDateTime } from "../util/datetime";
import Identifier from "./Identifier";
import TagEditor from "./TagEditor";
import dayjs from "dayjs";

/**
 * We need to display following
 * - ID
 * - Firebase ID
 * - Status
 * - Progressive automation analysis start date
 * - Quality assurance rate
 * - Minimum required probability
 * - maximumAllowedAbsoluteExpectedErrorValueForItem
 * - maximumAllowedAbsoluteInvoiceTotal
 * - allowedCounterPartyNames: string[];
 * - allowedPredictedLabels: string[];
 * - blockedCounterPartyNames: string[];
 * - blockedPredictedLabels: string[];
 * - createdAt
 * - updatedAt
 */

const AutomationDetails = (props: { automation: Approval, deletable: boolean, onDelete: (id: number) => void }) => {
    const { automation, deletable, onDelete } = props;

    const queryClient = useQueryClient();
    const updateAutomationMutation = useMutation((updates: Partial<Approval>) => updateClientAutomation(automation.clientId, automation.id, updates), {
        onSuccess: () => {
            queryClient.invalidateQueries(['client', automation.clientId]);
            message.success("Automaation tila päivitetty!");
        }
    });

    return (
        <Card key={automation.id} title={automation.name} extra={deletable ? <Button onClick={() => onDelete(automation.id)} danger type="text">Poista</Button> : undefined}>
            <Descriptions column={2} bordered size="small">
                <Descriptions.Item label="ID">
                    <Identifier code={false}>{automation.id}</Identifier>
                </Descriptions.Item>
                <Descriptions.Item label="Tyyppi">
                <Text>{automation.type}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Firebasen ID">
                    <Identifier>{automation.key}</Identifier>
                </Descriptions.Item>
                <Descriptions.Item label="Tila">
                    <Select
                        loading={updateAutomationMutation.isLoading}
                        onChange={status => updateAutomationMutation.mutate({ status })}
                        style={{ marginLeft: -7, width: 120 }}
                        bordered={false}
                        size="small"
                        defaultValue={automation.status}
                    >
                        <Select.Option value="SANDBOX">SANDBOX</Select.Option>
                        <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                        <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Varovaisuus (kuinka monta laskua ennen automaatiota)">
                    <Select
                        loading={updateAutomationMutation.isLoading}
                        onChange={patience => updateAutomationMutation.mutate({ patience })}
                        style={{ marginLeft: -7, width: 120 }}
                        bordered={false}
                        size="small"
                        defaultValue={automation.patience}
                    >
                        <Select.Option value="NONE">NONE (0)</Select.Option>
                        <Select.Option value="NORMAL">NORMAL (1)</Select.Option>
                        <Select.Option value="MEDIUM">MEDIUM (2)</Select.Option>
                        <Select.Option value="HIGH">HIGH (3)</Select.Option>
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Aloituspäivämäärä">
                    <DatePicker
                        onChange={progressiveAutomationAnalysisStartDate => {
                            if (!progressiveAutomationAnalysisStartDate) {
                                message.success("Päivämäärä ei voi olla tyhjä - tietoja ei pävitetty!");
                                return false;
                            }
                            return updateAutomationMutation.mutate({ progressiveAutomationAnalysisStartDate: progressiveAutomationAnalysisStartDate?.toISOString() })
                        }}
                        style={{ marginLeft: -7, width: 120 }}
                        bordered={false}
                        size="small"
                        defaultValue={dayjs(automation.progressiveAutomationAnalysisStartDate)}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Laadunvarmistuskerroin">
                    <EditableNumber loading={updateAutomationMutation.isLoading} value={automation.qualityAssuranceRate} onChange={value => updateAutomationMutation.mutate({ qualityAssuranceRate: value })} />
                </Descriptions.Item>
                <Descriptions.Item label="Suurin sallittu virhe riville">
                    <EditableNumber loading={updateAutomationMutation.isLoading} value={automation.maximumAllowedAbsoluteExpectedErrorValueForItem} onChange={value => updateAutomationMutation.mutate({ maximumAllowedAbsoluteExpectedErrorValueForItem: value })} />
                </Descriptions.Item>
                <Descriptions.Item label="Pienin sallittu todennäköisyys">
                    <EditableNumber loading={updateAutomationMutation.isLoading} value={automation.minimumRequiredProbability} onChange={value => updateAutomationMutation.mutate({ minimumRequiredProbability: value })} />
                </Descriptions.Item>
                <Descriptions.Item label="Suurin sallittu laskun summa">
                    <EditableNumber loading={updateAutomationMutation.isLoading} value={automation.maximumAllowedAbsoluteInvoiceTotal} onChange={value => updateAutomationMutation.mutate({ maximumAllowedAbsoluteInvoiceTotal: value })} />
                </Descriptions.Item>
                <Descriptions.Item label="Sallitut toimittajat">
                    <TagEditor
                        tags={automation.allowedCounterPartyNames || []}
                        onChange={tags => updateAutomationMutation.mutate({ allowedCounterPartyNames: tags })}
                        loading={updateAutomationMutation.isLoading}
                        color="green"
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Sallitut ennustettavat tietueet">
                    <TagEditor
                        tags={automation.allowedPredictedLabels || []}
                        onChange={tags => updateAutomationMutation.mutate({ allowedPredictedLabels: tags })}
                        loading={updateAutomationMutation.isLoading}
                        color="green"
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Estetyt toimittajat">
                    <TagEditor
                        tags={automation.blockedCounterPartyNames || []}
                        onChange={tags => updateAutomationMutation.mutate({ blockedCounterPartyNames: tags })}
                        loading={updateAutomationMutation.isLoading}
                        color="red"
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Estetyt ennustettavat tietueet">
                    <TagEditor
                        tags={automation.blockedPredictedLabels || []}
                        onChange={tags => updateAutomationMutation.mutate({ blockedPredictedLabels: tags })}
                        loading={updateAutomationMutation.isLoading}
                        color="red"
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Luotu">{renderDateTime(automation.createdAt)}</Descriptions.Item>
                <Descriptions.Item label="Päivitetty">{renderDateTime(automation.updatedAt)}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default AutomationDetails;

const EditableNumber = (props: { value: number, onChange: (value: number) => void, loading: boolean }) => {
    const [value, setValue] = useState(props.value);

    return (
        <Space style={{ marginLeft: -8 }}>
            <InputNumber
                value={value}
                onChange={val => setValue(val)}
                disabled={props.loading}
                onPressEnter={() => props.onChange(value)}
                size="small"
            />
            {value !== props.value && <Button
                size="small"
                loading={props.loading}
                onClick={() => props.onChange(value)}
                icon={<CheckOutlined />}
            />}
        </Space>
    );
};
