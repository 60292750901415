import { Content } from "antd/lib/layout/layout";
import { UIMessage, UIMessageInput } from "../interfaces";
import React, { useMemo } from 'react';
import Text from "antd/lib/typography/Text";
import Header from "../components/Header";
import Title from "antd/lib/typography/Title";
import { Space, Table, Tag, Button, Switch } from "antd";
import SystemTag from "../components/SystemTag";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { getUIMessages, updateUIMessage } from "../api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { messageTypes } from "../util/constants";
import { renderDateTime } from "../util/datetime";

function UIMessages() {
    const { data: messages, isLoading } = useQuery('uiMessages', getUIMessages);
    const queryClient = useQueryClient();
    const updateUIMessageMutation = useMutation(
        (event: { id: number, message: UIMessageInput }) => updateUIMessage(event.id, event.message),
        {
            onSuccess: () => queryClient.invalidateQueries('uiMessages'),
        }
    );

    const uiMessagesColumns: ColumnsType<UIMessage> = useMemo(() => [
        {
            title: 'Otsikko',
            dataIndex: 'title',
            key: 'title',
            render: (title: any, message) => <Link to={`/uiMessages/${message.id}/edit`}>
                {title['fi']}
            </Link>,
        },
        {
            title: 'Tyyppi',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (type: string) => <Tag color={messageTypes[type]?.tagColor}>{messageTypes[type]?.label}</Tag>,
        },
        {
            title: 'Järjestelmät',
            dataIndex: 'systems',
            key: 'systems',
            render: (systems: string[]) => (
                <>
                    {systems.map(system => <SystemTag system={system} key={system} />)}
                </>
            ),
            width: '20%',
        },
        {
            title: 'Aktiivinen',
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            render: (active: boolean, message: UIMessage) => (
                <Switch
                    onChange={() => updateUIMessageMutation.mutate({
                        id: message.id,
                        message: { ...message, active: !active },
                    })}
                    checked={active}
                    loading={updateUIMessageMutation.variables?.id === message.id && updateUIMessageMutation.isLoading}
                />
            ),
            width: 100,
        },
        {
            title: 'Luotu',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => <Text type="secondary">{renderDateTime(createdAt)}</Text>,
            sorter: (a: UIMessage, b: UIMessage) => a.createdAt.localeCompare(b.createdAt),
            defaultSortOrder: 'descend' as SortOrder,
            width: 200,
        }
    ], [updateUIMessageMutation]);

    return (
        <Content>
            <Header />
            <Space style={{ margin: '16px 0 24px 0' }} align="center" size="large">
                <Title level={2} style={{ margin: 0 }}>
                    UI-tiedotteet
                </Title>
                <Link to="/uiMessages/new">
                    <Button icon={<PlusOutlined />} type="primary">Uusi tiedote</Button>
                </Link>
            </Space>
            <Table
                loading={isLoading}
                columns={uiMessagesColumns}
                dataSource={messages?.items.map(message => ({ key: message.id, ...message }))}
                footer={() => `${messages?.totalCount} tulosta`}
                bordered
            />
        </Content>
    );
};

export default UIMessages;
