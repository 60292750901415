import axios from "axios";
import qs from 'qs';
import { apiBase, APIResponse, Collection, getAuthHeader } from ".";
import { CollectionOrganization, OldPeriod, Organization, PendingReport, Report } from "../interfaces";

export interface OrganizationParameters {
    name?: string;
    orderBy?: keyof Organization;
    order?: 'asc' | 'desc';
    type?: ('ai-inside' | 'normal')[];
    page: number;
    limit: number;
};

export const getOrganizations = async (): Promise<Collection<CollectionOrganization>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/organizations?compact=true`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
};

export const getOrganizationByID = async (id: number): Promise<Organization> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/organizations/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
}

export const updateOrganization = async (id: number, updates: Partial<Organization>): Promise<Organization> => {
    const org = await getOrganizationByID(id);
    const data = Object.assign(org, updates);
    const result: APIResponse<Organization> = await axios({
        method: "PUT",
        url: `${apiBase}/organizations/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
}

export const createOrganization = async (input: Partial<Organization>): Promise<Organization> => {
    const result: APIResponse<Organization> = await axios({
        method: "POST",
        url: `${apiBase}/organizations`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: input,
    });

    return result.data.data;
}

export const getOrganizationReports = async (id: number): Promise<Collection<Report>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/organizations/${id}/reports`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}

export const getOrganizationOldPeriods = async (id: number): Promise<Collection<OldPeriod>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/organizations/${id}/reports/oldPeriods`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}

export const ingestProps = async (id: number): Promise<Object> => {
   return {
        method: "POST",
        action: `${apiBase}/organizations/${id}/reports/ingest`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    };
};

export const requestReportGeneration = async (id: number, month: string, teamId: number | undefined, tag: string | undefined): Promise<void> => {
    if (teamId !== undefined && tag !== undefined) {
        return Promise.reject("Only one of teamId/tag can be specified");
    }

    await axios({
        method: "POST",
        url: `${apiBase}/organizations/${id}/reports?${qs.stringify({
            teamId,
            tag,
            month,
        })}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}

export const getPendingReports = async (id: number): Promise<Collection<PendingReport>> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/organizations/${id}/reports/pending`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
}
