import "./JsonView.css";
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as R from "ramda";
import { encode } from "html-entities";
import { re } from "../util/regex";

export type JsonViewProps =
  | { data: Record<string, any>; oldData?: undefined; newData?: undefined }
  | ({ data?: undefined } & Record<
      "oldData" | "newData",
      undefined | null | Record<string, any>
    >);

type Breadcrumb = [line: number | null, keys: string[]];

/**
 * A component that displays JSON data in a human-readable format.
 */
export const JsonView = (props: JsonViewProps) => {
  // HTML'ify `data` OR the diff of `oldData` and `newData`
  const [html, keys] = useMemo(() => {
    let str: string;
    if ("data" in props) {
      str = JSON.stringify(props.data, null, 2);
    } else {
      const diff = getDiff(props.oldData ?? {}, props.newData ?? {});
      str = stringifyDiff(diff);
    }
    const keys = str.split("\n").map((x) => {
      const match = x.match(breadcrumbPat);
      return [(match?.[1] ?? "").length, match?.[2]] as const;
    });
    return [syntaxHighlight(encode(str)), keys];
  }, [props.data, props.oldData, props.newData]);

  // calculate a breadcrumb based on the current scroll position
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb>([null, []]);
  const handleScroll = (
    e: React.UIEvent<HTMLPreElement> | { target: HTMLPreElement }
  ) => {
    const el = e.target as HTMLPreElement;
    let i;
    const ymax = el.getBoundingClientRect().top;
    for (i = 0; i < el.children.length; ++i) {
      const child = el.children[i] as HTMLElement;
      const rect = child.getBoundingClientRect();
      if (rect.top > ymax) {
        break;
      }
    }
    setBreadcrumb(getBreadcrumb(keys, i));
  };
  const preRef = useRef<HTMLPreElement>(null);
  useEffect(() => {
    handleScroll({ target: preRef.current! });
  }, []);

  return (
    <div className="json-container">
      <div
        className="json-breadcrumb"
        style={{ "--line-number": (breadcrumb[0] ?? 0) + 1 } as CSSProperties}
      >
        {breadcrumb[1].map((key, i) => (
          <span key={i}>{key}</span>
        ))}
      </div>
      <pre
        ref={preRef}
        className="json-view"
        onScroll={handleScroll}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></pre>
    </div>
  );
};

const breadcrumbPat = /^( *)(?:"([^"\n]+)")?/;
const getBreadcrumb = (
  keys: (readonly [idt: number, key: string | undefined])[],
  idx: number
): Breadcrumb => {
  if (keys.length <= idx) return [null, []];
  let maxIdx: null | number = null;
  let breadcrumb: string[] = [];
  let idt = keys[idx]?.[0] ?? 9999;
  for (let i = idx; i--; ) {
    if (keys[i]![0] < idt) {
      if (keys[i]![1]) {
        breadcrumb.unshift(keys[i]![1]!);
        maxIdx ??= i;
      }
      idt = keys[i]![0];
    }
  }
  return [maxIdx, breadcrumb];
};

const idtPat = /^     /gm;
const add = (str: string) => str.replace(idtPat, "+");
const rm = (str: string) => str.replace(idtPat, "-");

const absentPat = /^{\n +"ä": null\n +}$/;
const isAbsent = (str: string) => absentPat.test(str);

const stableUnion = <T extends any>(xs: T[], ys: T[]): T[] => {
  return [...new Set([...xs, ...ys])];
};
const isObj = (x: any) => R.is(Object, x) && !R.is(Array, x);
const getDiff = (a: any, b: any): any => {
  if (R.equals(a, b)) {
    return a;
  }
  if (isObj(a) && isObj(b)) {
    return Object.fromEntries(
      stableUnion(
        Object.getOwnPropertyNames(a),
        Object.getOwnPropertyNames(b)
      ).map((key: any) => [key, getDiff(a[key], b[key])])
    );
  }
  if (typeof a === "undefined") {
    return { ä: [{ ä: null }, b] };
  }
  if (typeof b === "undefined") {
    return { ä: [a, { ä: null }] };
  }
  return { ä: [a, b] };
};

const diffPat = re.gm`
  (^ *)?"([^"\n]+)": {\n
      \1  "ä": \[\n                                                   # container
      \1    ([^[{].*|[[{](?:.*[\]}]|(?:\n\1    (?:[\]}]|  .+))+)),\n  # A
      \1    ([^[{].*|[[{](?:.*[\]}]|(?:\n\1    (?:[\]}]|  .+))+))\n   # B
      \1  \]\n
      \1\}(,?\n)
`;
const stringifyDiff = (diff: any) => {
  return JSON.stringify(diff, null, 2).replaceAll(
    diffPat,
    (_, idt, key, a, b, end) =>
      a !== b
        ? (isAbsent(a) ? "" : `-${idt.slice(1)}"${key}": ${rm(a)}${end}`) +
          (isAbsent(b) ? "" : `+${idt.slice(1)}"${key}": ${add(b)}${end}`)
        : `${idt}"${key}": ${a}${end}`
  );
};

const syntaxHighlight = (encodedHtml: string) => {
  return encodedHtml
    .replace(tokenPat, tokenReplacer)
    .replace(/^(?:(<span[^>]+>)([.+-]))?(.*)$/gm, (_, span, diff, ln) => {
      if (diff) {
        ln = ` <span class="diff-${diff === "+" ? "add" : "rm"}">${
          span || ""
        } ${ln}</span>`;
      }
      return `<span>${ln}</span>`;
    });
};

const tokenPat = re.gm`
    (^[+-]? *)([\]}{\[]+)(?=,?$)                                        # single-line bracket(s)
   |(^[+-]? *)?(?:(&quot;(?:\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*?&quot;)   # string
        (:( [\]}{\[]+(?=,?$))?)?)                                       # ... or key
   |\b(?:(true|false)|(null))\b                                         # boolean or null
   |-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?                                   # number
`;
const brColors = ["#e07500", "#a00040", "#0010f0"];
const tokenReplacer = (
  match: string,
  i1: string,
  solobr: string,
  i2: string,
  str: string,
  key: string,
  open: string,
  bool_: string,
  null_: string
) => {
  if (open || solobr) {
    const idx = ((i1 ?? i2).length / 2) % brColors.length;
    if (solobr) {
      return `${i1}<span style="color: ${brColors[idx]}">${solobr}</span>`;
    }
    const len = open.length;
    match = `${match.slice(0, -len)}</span><span style="color: ${
      brColors[idx]
    }">${match.slice(-len)}`;
  }
  const cls = key
    ? "key"
    : str
      ? "string"
      : bool_
        ? "boolean"
        : null_
          ? "null"
          : "number";
  return '<span class="' + cls + '">' + match + "</span>";
};
