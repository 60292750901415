import React from "react";
import { Tag } from "antd";
import { getSystemLabel } from "../api/clients";
import { systemColors } from "../util/constants";



function SystemTag(props: any) {
    const { system, ...rest } = props;

    const label = getSystemLabel(system);

    return (
        <Tag key={system} color={systemColors[system]?.tag} {...rest}>{label}</Tag>
    );
};

export default SystemTag;
