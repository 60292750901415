import { Select, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { serviceTemplates } from "../util/constants";

const ServiceTemplate = (props: { serviceTemplate?: string, onChange?: (template: string) => any, loading?: boolean }) => {
    const { serviceTemplate } = props;

    if (props.loading) {
        return <Spin />;
    }

    if (!props.onChange) {
        return <Text>{serviceTemplate}</Text>
    }

    return (
        <Select
            defaultValue={serviceTemplate || undefined}
            onChange={props.onChange}
            bordered={false}
            style={{ marginLeft: -7 }}
            size="small"
            dropdownMatchSelectWidth={false}
            placeholder="Valitse palvelumalli"
        >
            {Object.keys(serviceTemplates).map(template => (
                <Select.Option key={template} value={template}>
                    {template}    
                </Select.Option>
            ))}
        </Select>
    );
};

export default ServiceTemplate;
