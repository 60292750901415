import React, { } from 'react';
import { DeleteOutlined, PlusSquareOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Form, List, message, Popconfirm, Select } from 'antd';
import { Member, Organization, OrganizationClient, Team, TeamClient } from "../interfaces";
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { deleteTeam, updateTeam } from '../api/teams';
import { useForm } from 'antd/lib/form/Form';
import TagEditor from './TagEditor';

const gridStyle = {
    width: '50%',
    height: 400,
    overflow: 'auto',
    padding: '0 16px'
};

function TeamCard(props: { team: Team, organization: Organization }) {
    const { team, organization } = props;

    const queryClient = useQueryClient();

    const [membersForm] = useForm();
    const [clientsForm] = useForm();

    const deleteTeamMutation = useMutation(() => deleteTeam(team.id), {
        onSuccess: () => {
            queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.filter(t => t.id !== team.id));
            queryClient.invalidateQueries('teams');
            queryClient.invalidateQueries(['organization', team.organizationId]);
            message.info('Tiimi poistettu');
        },
    });

    const addMembersMutation = useMutation((uids: string[]) => {
        const members = uids.map(uid => organization.members.find(m => m.uid === uid)).filter(m => m !== undefined) as Member[];
        return updateTeam(team.id, {
            members: [...team.members, ...members]
        })
    },
        {
            onSuccess: (team: Team) => {
                queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.map(t => t.id === team.id ? team : t));
                message.info('Jäsenet lisätty');
            }
        }
    );

    const removeMemberMutation = useMutation((uid: string) =>
        updateTeam(team.id, {
            members: team.members.filter(m => m.uid !== uid)
        }),
        {
            onSuccess: (team: Team) => {
                queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.map(t => t.id === team.id ? team : t));
                message.info('Jäsen poistettu');
            }
        }
    );

    const addClientsMutation = useMutation((clientIds: number[]) => {
        const clients = clientIds.map(id => organization.clients.find(c => c.id === id)).filter(c => c !== undefined) as OrganizationClient[];
        return updateTeam(team.id, {
            clients: [...team.clients, ...clients]
        })
    },
        {
            onSuccess: (team: Team) => {
                queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.map(t => t.id === team.id ? team : t));
                message.info('Asiakkaat lisätty');
            }
        }
    );

    const removeClientMutation = useMutation((clientId: number) =>
        updateTeam(team.id, {
            clients: team.clients.filter(c => c.id !== clientId)
        }),
        {
            onSuccess: (team: Team) => {
                queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.map(t => t.id === team.id ? team : t));
                message.info('Asiakas poistettu');
            }
        }
    );

    const tagsMutation = useMutation((tags: string[]) =>
        updateTeam(team.id, {
            tags: tags.reduce((acc, tag) => {
                const [key, value] = tag.split('=');
                if (key && value) {
                    acc[key] = value;
                }
                return acc;
            }, {} as { [key: string]: string })
        }),
        {
            onSuccess: (team: Team) => {
                queryClient.setQueryData('teams', (oldTeams: Team[]) => oldTeams.map(t => t.id === team.id ? team : t));
                message.info('Tagit päivitetty');
            }
        }
    );

    const otherMembers = organization.members.filter(
        member => !team.members.map(m => m.uid).includes(member.uid)
    );

    const otherClients = organization.clients.filter(
        client => !team.clients.map(c => c.id).includes(client.id)
    );

    return (
        <Card
            hoverable
            title={team.name}
            actions={[
                <Form
                    form={membersForm}
                    style={{ display: 'flex', padding: 8, alignItems: 'center' }}
                    onFinish={(values) => {
                        addMembersMutation.mutateAsync(values.members).then(() => {
                            membersForm.resetFields();
                        });
                    }}
                >
                    <Form.Item noStyle name="members">
                        <Select
                            mode='multiple'
                            placeholder='Lisää jäseniä'
                            allowClear
                            maxTagTextLength={28}
                            style={{ flex: 1, marginRight: 4, textAlign: 'left' }}
                            options={otherMembers.map(m => ({ label: m.name, value: m.uid }))}
                            optionFilterProp='label'
                        />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Button htmlType="submit" icon={<UserAddOutlined />} />
                    </Form.Item>
                </Form>,
                <Form
                    form={clientsForm}
                    style={{ display: 'flex', padding: 8, alignItems: 'center' }}
                    onFinish={(values) => {
                        addClientsMutation.mutateAsync(values.clients).then(() => {
                            clientsForm.resetFields();
                        });
                    }}
                >
                    <Form.Item noStyle name="clients">
                        <Select
                            mode='multiple'
                            placeholder='Lisää asiakkaita'
                            allowClear
                            maxTagTextLength={28}
                            style={{ flex: 1, marginRight: 4, textAlign: 'left' }}
                            options={otherClients.map(c => ({ label: c.name, value: c.id }))}
                            optionFilterProp='label'
                        />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Button htmlType="submit" icon={<PlusSquareOutlined />} />
                    </Form.Item>
                </Form>,
            ]}
            style={{ border: '1px solid #ddd' }}
            extra={
                <div>
            <TagEditor
                    tags={Object.entries(team.tags || {}).map(([key, value]) => `${key}=${value}`)}
                    onChange={tagsMutation.mutate}
                    validate={(tag: string) => tag.split('=').length === 2}
                    loading={tagsMutation.isLoading}
                    inputStyle={{ width: 120 }}
                    color={(value) => value.startsWith('aggregateKey') ? 'geekblue' : undefined}
                />
                <Divider style={{ marginLeft: '2rem', marginRight: '1.25rem' }} type="vertical" />
                <Popconfirm title="Oletko varma?" onConfirm={() => deleteTeamMutation.mutate()}>
                    <Button type="text" size="small" danger>Poista tiimi</Button>
                </Popconfirm>
                </div>
            }
        >
            <Card.Grid hoverable={false} style={gridStyle}>
                <List
                    dataSource={team.members}
                    renderItem={(member: Member) => (
                        <List.Item actions={[
                            <Button
                                onClick={() => removeMemberMutation.mutate(member.uid)}
                                type="text"
                                size="small"
                                icon={<UserDeleteOutlined />}
                            />
                        ]}>
                            <List.Item.Meta
                                title={
                                    <Link to={`/users/${member.uid}`}>{member.name}</Link>
                                }
                                description={member.email}
                            />
                        </List.Item>
                    )}
                />
            </Card.Grid>
            <Card.Grid hoverable={false} style={gridStyle}>
                <List
                    dataSource={team.clients}
                    renderItem={(client: TeamClient) => (
                        <List.Item actions={[
                            <Button
                                onClick={() => removeClientMutation.mutate(client.id)}
                                type="text"
                                size="small"
                                icon={<DeleteOutlined />}
                            />
                        ]}>
                            <List.Item.Meta
                                title={
                                    <Link to={`/clients/${client.id}`}>{client.name}</Link>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card.Grid>
        </Card>
    );
};

export default TeamCard;
