import { CheckOutlined } from '@ant-design/icons';
import { Button, Select, Space, Tooltip } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getTeams } from '../api/teams';
import { Team } from '../interfaces';

export interface IAddToTeamProps {
    submitting?: boolean;
    onSubmit: (team: Team) => void;
    onRemove?: () => void;
    organizationId?: number;
    style?: Object;
    blacklist?: number[];
}

const AddToTeam = (props: IAddToTeamProps) => {
    const { submitting, onSubmit, onRemove, organizationId, ...rest } = props;
    const [selectedTeam, setSelectedTeam] = useState<Team | null | undefined>(undefined);

    const { data: allTeams, isLoading } = useQuery('teams', () => getTeams().then(c => c.items));


    const organizationTeams = allTeams ? allTeams
        .filter(team => team.organizationId === organizationId)
        .filter(team => !props.blacklist?.includes(team.id)) : [];

    useEffect(() => {
        setSelectedTeam(undefined);
    }, [organizationId, submitting]);

    return (
        <Space {...rest}>
            <Select
                disabled={!organizationId || isLoading}
                loading={isLoading}
                showSearch
                style={{ width: 200 }}
                placeholder="Lisää tiimiin"
                optionFilterProp="children"
                filterOption={(input: string, option: BaseOptionType) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(teamId: number | string) => setSelectedTeam(allTeams?.find(team => team.id === teamId) ?? null)}
                value={selectedTeam === null ? "remove" : selectedTeam?.id}
            >
                <Select.Option value="remove">
                    (poista tiimeistä)
                </Select.Option>
                {organizationTeams?.map(team => (
                    <Select.Option key={team.id} value={team.id}>
                        {team.name}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip title="Lisää tiimiin">
                <Button
                    loading={submitting}
                    disabled={selectedTeam === undefined}
                    onClick={() => {
                        if (selectedTeam) {
                            onSubmit(selectedTeam);
                        } else if (onRemove) {
                            onRemove();
                        }
                    }}
                    icon={<CheckOutlined />}
                />
            </Tooltip>
        </Space>
    );
};

export default AddToTeam;
