"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = omit;
function omit(obj, fields) {
  var clone = Object.assign({}, obj);
  if (Array.isArray(fields)) {
    fields.forEach(function (key) {
      delete clone[key];
    });
  }
  return clone;
}