import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import App from './App';

// import 'antd/dist/antd.css';


import { ConfigProvider } from 'antd';
import fi from 'antd/lib/locale/fi_FI';
import { QueryClient, QueryClientProvider } from 'react-query';
import './app.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {}
    }
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={fi}>
            <RecoilRoot>
                <App />
            </RecoilRoot>
        </ConfigProvider>
    </QueryClientProvider>,
    document.querySelector('#root'));
