export namespace tooltips {
    export const form = {
        usage: `Syötä joko laskun Aurora ID tai asiakas-lasku-tunnistepari.`,
        logTime: `Mikäli uskot laskulla olevan sellaisia Cloudwatch-lokimerkintöjä, jotka eivät asetu S3-lasku-, MARK_*- ja pending-merkintöjen ajoista 30 minuutin aikaikkunoihin, anna Cloudwatch-lokien haun aikaväli.`,
        logGroups: `Nopeuttaaksesi Cloudwatch-hakua voit suoraan määrittää haettavat lokiryhmät.`,
    };

    export const cloudwatch = {
        "step-functions-step-1-append-invoice": `Lambda to append the invoice's data with data extracted from XML, PDF ~~or OCR~~ invoice and open sources.`,
        "step-functions-step-2-b-consolidate-predictions": `This lambda intends to do multiple things to predictions:
    
            -   reduce variance for uncertain predictions on multi invoiceRow invoice
            -   apply manually defined rules from Google Spreadsheets to invoiceRow(s) or to whole invoice
            -   make sure that there are no mismatch between account name(s) and vatPercent
            -   forcibly reduce confidence on "reminder invoices" to make sure they do not get processed automatically`,
        "step-functions-step-2-predict":
            "Lambda to request prediction(s) from all required model(s) and then add them to the invoiceRows",
        "step-functions-step-c-decide-if-predict": `Lambda to decide if the invoice must be fetched for prediction or if should we stop the execution of the step function's process`,
        "step-functions-step-3-write-to-database":
            "Lambda to write (and replace) invoices to Aurora",
        "step-functions-step-b-parse-invoice-api": `Lambda to:
    
          -   parse raw AI Inside's invoice into FabricAI's internal invoice representation
          -   trigger next step in the StepFunctions`,
        "step-functions-step-b-parse-invoice-fivaldi": `Lambda to:
    
            -   parse raw Fivaldi invoice into FabricAI's internal invoice representation
            -   perform (uniquely) Fivaldi related checks on whether we should continue or not
                -   NOTE :: Fivaldi has a lot of custom logic that is handled by \`/src/actions/analyzeRawInvoiceForNextStep.ts\`
            -   trigger next step in the StepFunctions`,
        "step-functions-step-b-parse-invoice-netvisor": `Lambda to:
    
              -   parse raw Netvisor invoice into FabricAI's internal invoice representation
              -   perform (uniquely) Netivsor related checks on whether we should continue or not
              -   trigger next step in the StepFunctions`,
        "step-functions-step-b-parse-invoice-procountor": `Lambda to:
    
                -   parse raw Procountor invoice into FabricAI's internal invoice representation
                -   perform (uniquely) Procountor related checks on whether we should continue or not
                -   adds \`fai_circulate_list\` as a dimension IF
                    -   user has in database \`/integrations/{firebaseIntegrationKey}/filters/fai_circulate_list = true\`
                    -   we can deduce the circulation list from the given verifier and acceptor(s)
                -   trigger next step in the StepFunctions`,
        "step-functions-step-b-parse-invoice-tripletex": `Lambda to:
    
          -   parse raw tripletex invoice into FabricAI's internal invoice representation
          -   perform (uniquely) Tripletex related checks on whether we should continue or not
          -   trigger next step in the StepFunctions`,
    };

    export const invoiceActions = {
        MARK_COMPLETED:
            "Lasku on loppuunkäsitelty. Taustaprosessissa olevalla asiakkaalla automaattisesti ja muuten joko IA:n tai käyttäjän toimesta",
        MARK_PRE_COMPLETED: "Lasku on esitiliöity",
        MARK_DELETED: "Lasku on poistettu käyttäjän toimesta",
        MARK_PENDING: "Laskun tila muutettiin odottaa käsittelyä -tilaan",
        CREATE_INVOICE:
            "Laskun versio (joko uusi tai hyväksytty) lisätty kantaan",
        ADD_ITEM: "Laskulle on lisätty uusi rivi käyttäjän toimesta",
        DELETE_ITEM: "Laskulta on poistettu rivi käyttäjän toimesta",
        EDIT_ITEM: "Laskulla olevaa riviä on muokattu käyttäjän toimesta",
    };

    export const s3 = {
        "S3-lasku":
            "Uusi laskutiedosto tai laskutiedoston versio on tallennettu S3-palveluun",
        "S3-sääntö":
            "Uusi sääntötiedosto tai sääntötiedoston versio on tallennettu S3-palveluun",
    };

    export const invoice = {
        "Aurora-tietue": "Laskun tila Aurorassa viimeisimmän muutoksen jälkeen",
    };

    export const invoiceHistory = {
        insert: "Tietoja on lisätty mainittuun tietokantatauluun",
        update: "Tietoja on päivitetty mainitussa tietokantataulussa",
        delete: "Tietoja on poistettu mainitusta tietokantataulusta",
    };

    export const invoiceFetchQueue = {
        pending:
            "Kirjanpito-ohjelmassa havaittu muutos (voi olla relevantti tai joku muu).",
        processing: "(ei käytössä vielä) Tämä käsittelykerta on käynnissä",
        failed: "(ei käytössä vielä) Tämä käsittelykerta epäonnistui",
        done: "(ei käytössä vielä) Tämä käsittelykerta on valmis",
    };

    export const serviceTemplate = {
        BACKGROUND_PROCESS:
            "Laskut esitiliöidään kirjanpito-ohjelmaan. Laskut eivät nouse UI:hin.",
        CUSTOMER_DOES_NOTHING:
            "Laskut nostetaan FabricAI-portaaliin odottamaan käyttäjän toimenpiteitä (PENDING), eli laskut eivät välissä odota hyväksyntää kohdejärjestelmässä. Tiliöinnin (COMPLETED) jälkeen laskut palautetaan kirjanpito-ohjelmaan.",
        BACKGROUND_PROCESS_AND_LAST_CHECK:
            "Ensimmäisellä käsittelykerralla laskut esitiliöidään (PRE_COMPLETED) ja jätetään kirjanpito-ohjelmaan. Kun laskut on hyväksytty, ne nostetaan FabricAI-portaaliin, josta ne tiliöidään (COMPLETED).",
        BACKGROUND_PROCESS_AND_LAST_CHECK_WITH_CUSTOMER_ACCOUNTS:
            "Ensimmäisellä käsittelykerralla laskut esitiliöidään (PRE_COMPLETED) ja jätetään kirjanpito-ohjelmaan. Kun laskut on hyväksytty, ne nostetaan FabricAI-portaaliin, josta ne tiliöidään (COMPLETED).",
    };

    export const serviceTemplateAbbreviations = {
        BACKGROUND_PROCESS: "Tausta",
        CUSTOMER_DOES_NOTHING: "Vaihtoeht.",
        BACKGROUND_PROCESS_AND_LAST_CHECK: "Ek standard.",
        BACKGROUND_PROCESS_AND_LAST_CHECK_WITH_CUSTOMER_ACCOUNTS: "Standardi",
    };
}
