import React from 'react';
import { DownOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { useQuery } from "react-query";
import { getUserByUID } from "../api/users";
import { auth } from "../util/firebase";
import Breadcrumbs, { SnippetResolver } from "./Breadcrumbs";
import { no404Retry } from '../api';

const menu = (
    <Menu>
        <Menu.Item icon={<SettingOutlined />} key="0">Asetukset</Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key="1">
            <a href="#" onClick={() => auth.signOut()}>Kirjaudu ulos</a>
        </Menu.Item>
    </Menu>
);

function Header(props: { resolver?: SnippetResolver }) {
    const { data: user, isError } = useQuery(
        ['user', auth.currentUser?.uid],
        () =>  getUserByUID(auth.currentUser!.uid),
        {
            enabled: !!auth.currentUser,
            retry: no404Retry,
        }
    );

    const initials = user?.name.split(' ').map(i => i[0]).join('').toUpperCase();

    return (
        <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }} >
            <Breadcrumbs resolver={props.resolver} />
            <div style={{ flex: 1 }}></div>

            <Dropdown overlay={menu}>
                <a href="#" className="ant-dropdown-link" >{user?.name} <DownOutlined /></a>
            </Dropdown>
            <Avatar style={{ marginLeft: 16, background: 'rgb(245, 56, 85)' }}>{
                isError ? '!' : initials
            }</Avatar>
        </div>
    );
};

export default Header;
