import { Descriptions, Spin } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { getClientCOA } from "../api/clients";

const ClientCOA = (props: { clientId: number }) => {
    const { clientId } = props;
    const coaQuery = useQuery(['client', clientId, 'coa'], () => getClientCOA(clientId));

    if (coaQuery.isLoading) return <Spin />

    return (
        <Descriptions bordered size="small">
            {coaQuery.data?.accounts?.map((coa, index) => (
                <Descriptions.Item key={index} label={coa.code}>
                    {coa.name}
                </Descriptions.Item>
            ))}
        </Descriptions>
    );
};

export default ClientCOA;
