import { notification } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { decodeJwt } from "jose";
import { auth } from "../util/firebase";

// Include X-App-Name: admin-panel in all axios requests
import axios from "axios";
axios.defaults.headers.common["X-App-Name"] = "admin-panel";

export interface Collection<T> {
  items: T[];
  totalCount: number;
}

export type APIResponse<T = any> = AxiosResponse<{
  status: string;
  statusCode: number;
  data: T;
  count?: number;
  totalCount?: number;
}>;

export type APIError = AxiosError<{
  status: string;
  statusCode: number;
  error: string;
}>;

export const apiBase = process.env.API_BASE_URL || "https://api.fabricai.io";
export const authBase = process.env.AUTH_BASE_URL || "https://auth.fabricai.io";

console.log(`Using API base URL: ${apiBase}`);
console.log(`Using AUTH base URL: ${authBase}`);

export const no404Retry = (failureCount: number, error: APIError): boolean => {
  if (error.response?.status === 404) {
    return false;
  }
  return failureCount < 1;
};

export function isApiError(x: any): x is APIError {
  return (
    x instanceof AxiosError &&
    typeof x.response?.data === "object" &&
    "error" in x.response?.data
  );
}

export const notifyError = (error: APIError) => {
  notification.error({
    message: error.message,
    description: error.response?.data.error,
    duration: 30,
  });
};

export const notifyOpaqueError = (error: any) => {
  if (isApiError(error)) {
    notifyError(error);
  }
  console.error(error);
};

let authToken:
  | {
      token: string;
      uid: string;
      expires: Date;
    }
  | undefined = undefined;
export const getAuthHeader = async (uid?: string): Promise<string> => {
  if (!uid) {
    uid = auth.currentUser?.uid;
  }

  if (
    typeof authToken !== "undefined" &&
    authToken.uid === uid &&
    authToken.expires > new Date()
  ) {
    return `Bearer ${authToken.token}`;
  }

  // Wait until Firebase auth has been initialized with correctuser
  if (!auth.currentUser || auth.currentUser.uid !== uid) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(getAuthHeader(uid));
        } else {
          reject(new Error("No user logged in"));
        }
      });
    });
  }

  const idToken = await auth.currentUser.getIdToken();

  if (typeof idToken === "undefined") {
    console.error("error getting Firebase id token");
    return "";
  }

  const res = await fetch(authBase, {
    method: "POST",
    headers: {
      "id-token": idToken,
      "claim-client-id": "none",
    },
  });

  const data = await res.json();

  if (res.status !== 200) {
    console.error(`authentication error: ${JSON.stringify(data)}`);
    return "";
  }

  if (!data.token) {
    console.error("received empty auth token");
    return "";
  }

  const token = decodeJwt(data.token);

  authToken = {
    token: data.token,
    uid: uid,
    expires: token.exp
      ? new Date(token.exp * 1000)
      : new Date(Date.now() + 3600 * 1000),
  };

  return `Bearer ${authToken.token}`;
};

export * from "./uiMessages";
