import { Alert } from "antd";
import React from "react";
import { UseQueryResult } from "react-query";
import { APIError } from "../api";

const QueryError = ({ query }: { query: UseQueryResult<any, APIError> }) => {
    if (!query.isError) return null;

    return (
        <Alert
            style={{ marginBottom: 16 }}
            message={query.error.message}
            description={query.error.response?.data?.error}
            type="error"
        />
    );
};

export default QueryError;
