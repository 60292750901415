import { CheckCircleTwoTone, ClockCircleTwoTone, ExclamationCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { MLModel } from '../interfaces';

export type ModelStatusBadgeProps = {
    models: MLModel[];
};

type ModelStatus = 'OK' | 'UNDEFINED' | 'TRAINING' | 'FAILED';

const badgeIcons: { [status in ModelStatus]: React.ReactElement } = {
    'OK': <CheckCircleTwoTone twoToneColor="#22c55e" />,
    'UNDEFINED': <QuestionCircleTwoTone twoToneColor="#94a3b8" />,
    'TRAINING': <ClockCircleTwoTone twoToneColor="#f59e0b" />,
    'FAILED': <ExclamationCircleTwoTone twoToneColor="#e11d48" />,
};

const ModelStatusBadge = (props: ModelStatusBadgeProps) => {
    const statuses = props.models.map(({ logs }) => (logs && logs[0]?.status)).filter(s => s);

    let status: ModelStatus = 'UNDEFINED';
    if (statuses.length && statuses.every(status => status === 'DEPLOYED')) {
        status = 'OK';
    } else if (statuses.some(status => status === 'FAILED')) {
        status = 'FAILED';
    } else if (statuses.length) {
        status = 'TRAINING';
    }

    return (
        <Tooltip title={statuses.join(', ')}>
            {badgeIcons[status]}
        </Tooltip>
    );
};

export default ModelStatusBadge;
