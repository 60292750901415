import { Alert, Button, Card, Col, Form, Input, Popconfirm, Row, Select, Space, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { BaseType } from "antd/lib/typography/Base";
import Text from "antd/lib/typography/Text";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { getSystemLabel } from "../api/clients";
import { LocaleStrings, UIMessage, UIMessageInput } from "../interfaces";
import { languages, messageTypes } from "../util/constants";

interface UIMessageFields {
    type: 'info' | 'warning' | 'error';
    systems: string[];
    active: boolean;
    languages: {
        lang: string;
        title: string;
        description: string;
    }[];
}

export interface UIMessageFormProps {
    initialData?: UIMessage,
    onSubmit?: (message: UIMessageInput) => any,
    onDelete?: () => void
    loading?: boolean,
    submitting?: boolean,
}

const required = {
    required: true,
    message: "Pakollinen",
};

const supportedSystems = [
    'fennoa',
    'fivaldi',
    'hausvise',
    'netvisor',
    'procountor',
    'tripletex',
]

function UIMessageForm(props: UIMessageFormProps) {

    const initialValues: UIMessageFields = useMemo(() => props.initialData !== undefined ? {
        type: props.initialData.type || 'info',
        systems: props.initialData.systems,
        active: props.initialData.active,
        languages: ['fi', 'en'].map(lang => ({
            lang,
            title: props.initialData?.title[lang] || '',
            description: props.initialData?.description[lang] || '',
        })),
    } : {
        type: 'info',
        systems: [],
        active: true,
        languages: [
            {lang: 'fi', title: '', description: ''},
            {lang: 'en', title: '', description: ''},
        ],
    }, [props.initialData]);
    
    const [preview, setPreview] = useState<UIMessageFields>(initialValues);

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        setPreview(initialValues);
    }, [form.resetFields, initialValues]);

    const onFinish = useCallback((values: UIMessageFields) => {
        if (!props.onSubmit) return;
        const message = {
            type: values.type,
            systems: values.systems,
            active: values.active,
            description: values.languages.reduce((acc, lang) => {
                acc[lang.lang] = lang.description;
                return acc;
            }, {} as LocaleStrings),
            title: values.languages.reduce((acc, lang) => {
                acc[lang.lang] = lang.title;
                return acc;
            }, {} as LocaleStrings),

        };
        props.onSubmit(message);
    }, [props.onSubmit]);
    
    return (
        <Row gutter={32}>
            <Col span={12}>
                <Card loading={props.loading}>
                    <Form
                        form={form}
                        layout="horizontal"
                        labelCol={{ span: 4 }}
                        onFinish={onFinish}
                        initialValues={initialValues}
                        onValuesChange={(_: any, values: UIMessageFields) => setPreview(values)}
                    >
                        <Form.Item name="type" label="Tyyppi" rules={[required]}>
                            <Select>
                                {
                                    Object.entries(messageTypes).map(([ value, messageType ]) => (
                                        <Select.Option key={value} value={value}>
                                            <Text type={messageType.color as BaseType}>{messageType.label}</Text>
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="systems"
                            label="Järjestelmät"
                            rules={[required]}
                            extra="Kirjanpito-ohjelmistot, joiden käyttäjille tiedote näytetään."
                        >
                            <Select mode="multiple" showArrow>
                                {
                                    supportedSystems.map(system => (
                                        <Select.Option key={system} value={system}>
                                            {getSystemLabel(system)}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="active"
                            label="Aktiivinen"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Form.List name="languages">
                            {(fields) => (
                                <Space style={{ width: '100%', marginBottom: 32 }} direction="vertical" size="middle">
                                    {fields.map(({ key, name, ...restField }) => (
                                            <Card key={key} type="inner" size="small"
                                                title={
                                                    <Form.Item {...restField} style={{ margin: 0 }} name={[name, 'lang']} rules={[required]} label="Kieli">
                                                        <Select disabled style={{ width: 200 }}>
                                                            {languages.map(language => (
                                                                <Select.Option key={language.value} value={language.value}>
                                                                    {language.label}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                }
                                            >
        
                                                <Form.Item {...restField} name={[name, 'title']} label={`Otsikko`} rules={[required]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item {...restField} name={[name, 'description']} label={`Kuvaus`} rules={[required]}>
                                                    <TextArea />
                                                </Form.Item>
                                            </Card>
                                    ))}
                                </Space>
                            )}
                        </Form.List>
                        {props.onDelete && (
                            <Form.Item style={{ margin: 0, float: 'left' }}>
                                <Popconfirm
                                    title="Oletko varma?"
                                    onConfirm={props.onDelete}
                                    okText="Kyllä"
                                    cancelText="Peruuta"
                                >
                                    <Button danger type="primary">Poista tiedote</Button>
                                </Popconfirm>
                            </Form.Item>
                        )}
                        <Form.Item style={{ margin: 0, float: 'right' }}>
                            <Button loading={props.submitting} type="primary" htmlType="submit">
                                Tallenna tiedote
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Esikatselu">
                    <Alert
                        type={preview.type}
                        message={preview.languages[0]?.title || 'Otsikko'}
                        description={
                            <ReactMarkdown
                                linkTarget="_blank"
                                remarkPlugins={[remarkGfm]}
                                children={preview.languages[0]?.description || 'Kuvaus'}
                            />
                        }
                        showIcon={true}
                    />
                </Card>
            </Col>
        </Row>
    );
}

export default UIMessageForm;
