import React from "react";
import {
  Checkbox,
  DatePicker,
  Divider,
  InputNumber,
  Space,
  Button,
  Select,
} from "antd";
import { Client } from "../interfaces";
import ClientFilters from "./ClientFilters";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const vatStatusesFi = [
	// Kotimaa
	'vat_1',
	// EU:n ulkopuolella
	'vat_3',
	// Ei ALV-käsittelyä
	'vat_12',
	// Rakennusalan käänteinen ALV 24 %
	'vat_45',
	// EU 0 % (tavara)
	'vat_6',
	// EU 0 % (palvelu)
	'vat_16',
	// EU, ei yhteisöhankinta
	'vat_10',
	// Ei alv.rek.
	'vat_4',
	// EU 24 % (tavara)
	'vat_36',
	// EU 14 % (tavara)
	'vat_37',
	// EU 10 % (tavara)
	'vat_38',
	// EU 24 % (palvelu)
	'vat_39',
	// EU 14 % (palvelu)
	'vat_40',
	// EU 10 % (palvelu)
	'vat_41',
	// Käännetty verovelvollisuus 24 %
	'vat_42',
	// Käännetty verovelvollisuus 14 %
	'vat_43',
	// Käännetty verovelvollisuus 10 %
	'vat_44',
	// Maahantuonti 0%
	'vat_100',
	// Maahantuonti 10%
	'vat_101',
	// Maahantuonti 14%
	'vat_102',
	// Maahantuonti 24%
	'vat_103'
];

const ClientSettings = ({
  client,
  onChange,
  loading,
}: {
  client: Client;
  onChange: (client: Partial<Client>) => void;
  loading: boolean;
}) => {
  const ds = client?.defaultsAndSettings ?? {};

  const setVatless = () => {
    onChange({
      defaultsAndSettings: {
        ...ds,
        defaultVatStatus: "vat_12",
        defaultVatPercent: 0,
        defaultVatDeductionPercent: null,
      },
    });
  };

  const handleChange = (key: string) => (e: any) => {
    let value: string | number | boolean | null;

    switch (key) {
      case "defaultVatPercent":
      case "defaultVatDeductionPercent":
        value = e as number;
        break;
      case "blockCrossUseOfMLModels":
      case "allowEmptyModelTraining":
      case "defaultMergeItems":
        value = (e as CheckboxChangeEvent).target.checked || null;
        break;
      case "defaultMTRForcedLowerThreshold":
        value = (e as dayjs.Dayjs)?.toISOString() ?? null;
        break;
      default:
        value = e as string | null;
        break;
    }

    onChange({
      defaultsAndSettings: {
        ...ds,
        [key]: value,
      },
    });
  };

  return (
    <Space
      size="large"
      split={<Divider type="vertical" style={{ height: 140 }} />}
      align="start"
    >
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'baseline' }}>
          <h4>ALV</h4>
          <Button
            disabled={loading}
            onClick={setVatless}
            size="small"
            type="dashed"
          >
            Asiakas on ALV:ton
          </Button>
        </div>
        <div
          style={{
            marginTop: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label htmlFor="defaultVatStatus">ALV-status: </label>
          <Select
            disabled={loading}
            onChange={handleChange("defaultVatStatus")}
            value={ds.defaultVatStatus ?? ""}
            id="defaultVatStatus"
            size="small"
            style={{ width: 90 }}
			options={[ {
				value: null,
				label: ''
			}, ...vatStatusesFi.map(status => ({ value: status, label: status }))]}
          />
        </div>

        <div
          style={{
            marginTop: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label htmlFor="defaultVatPercent">ALV-%: </label>
          <InputNumber
            readOnly={loading}
            onChange={handleChange("defaultVatPercent")}
            value={ds.defaultVatPercent}
            type="number"
            id="defaultVatPercent"
            name="defaultVatPercent"
            size="small"
          />
        </div>

        <div
          style={{
            marginTop: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label
            style={{ marginRight: 16 }}
            htmlFor="defaultVatDeductionPercent"
          >
            ALV-vähennysprosentti:{" "}
          </label>
          <InputNumber
            readOnly={loading}
            onChange={handleChange("defaultVatDeductionPercent")}
            value={ds.defaultVatDeductionPercent}
            type="number"
            id="defaultVatDeductionPercent"
            name="defaultVatDeductionPercent"
            size="small"
          />
        </div>
      </div>
      <div>
        <h4>Mallien koulutus</h4>
        <p>
          <Checkbox
            disabled={loading}
            onChange={handleChange("blockCrossUseOfMLModels")}
            checked={ds.blockCrossUseOfMLModels ?? false}
          >
            Mallien ristiinkäyttö kielletty
          </Checkbox>
          <br />
          <Checkbox
            disabled={loading}
            onChange={handleChange("allowEmptyModelTraining")}
            checked={ds.allowEmptyModelTraining ?? false}
          >
            Tyhjän mallin koulutus sallittu
          </Checkbox>
        </p>
        <label htmlFor="defaultMTRForcedLowerThreshold">
          Koulutusaineiston takaraja:
        </label>
        <br />
        <DatePicker
          disabled={loading}
          onChange={handleChange("defaultMTRForcedLowerThreshold")}
          value={
            ds.defaultMTRForcedLowerThreshold
              ? dayjs(ds.defaultMTRForcedLowerThreshold)
              : null
          }
          id="defaultMTRForcedLowerThreshold"
          size="small"
        />
      </div>
      <div>
        <h4>Muut</h4>
        <Checkbox
          disabled={loading}
          onChange={handleChange("defaultMergeItems")}
          checked={ds.defaultMergeItems ?? false}
        >
          Yhdistä rivit
        </Checkbox>
        <br />
        {client?.filters ? (
          <ClientFilters
            filters={client.filters}
            loading={loading}
            onChange={(filters) => onChange({ filters })}
          />
        ) : (
          "-"
        )}
      </div>
    </Space>
  );
};

export default ClientSettings;
