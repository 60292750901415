import { atom, AtomEffect, selector } from "recoil";
import { ClientParameters } from "../api/clients";
import { OrganizationParameters } from "../api/organizations";
import { UserParameters } from "../api/users";
import { ClientInput } from "../interfaces";
import { InvoiceHistoryParameters } from "../api/invoiceHistory";

export const clientParameters = atom<ClientParameters>({
  key: "clientParameters",
  default: {
    page: 1,
    limit: 10,
    orderBy: "createdAt",
    order: "desc",
  },
});

export const organizationParameters = atom<OrganizationParameters>({
  key: "organizationParameters",
  default: {
    page: 1,
    limit: 10,
  },
});

export const userParameters = atom<UserParameters>({
  key: "userParameters",
  default: {
    page: 1,
    limit: 10,
  },
});

export const invoiceHistoryParameters = atom<InvoiceHistoryParameters>({
  key: "invoiceHistoryParameters",
  default: {
    invoiceId: 0,
    page: 1,
    limit: 10,
  },
});

const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const importDataState = atom<ClientInput[] | null>({
  key: "importData",
  default: null,
  effects: [localStorageEffect("importData")],
});

export const importIntegrations = selector<ClientInput[]>({
  key: "importIntegrations",
  get: ({ get }) => get(importDataState) || [],
  set: ({ set }, integrations: ClientInput[]) =>
    set(importDataState, integrations),
});
