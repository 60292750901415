import axios from "axios";
import { apiBase, Collection, getAuthHeader } from ".";
import { UIMessage, UIMessageInput } from "../interfaces";

export const getUIMessages = async (): Promise<Collection<UIMessage>>  => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/uiMessages?all=true&orderBy=createdAt&order=desc`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.data?.length || 0,
        items: result.data.data || [],
    };
};

export const getUIMessage = async (id: number): Promise<UIMessage> => {
    const result = await axios({
        method: "GET",
        url: `${apiBase}/uiMessages/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

    return result.data.data;
};

export const createUIMessage = async (message: UIMessageInput): Promise<UIMessage> => {
    const result = await axios({
        method: "POST",
        url: `${apiBase}/uiMessages`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: message,
    });

    return result.data.data;
}

export const updateUIMessage = async (id: number, message: UIMessageInput): Promise<UIMessage> => {
    const result = await axios({
        method: "PUT",
        url: `${apiBase}/uiMessages/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
        data: message,
    });

    return result.data.data;
}

export const deleteUIMessage = async (id: number): Promise<void> => {
    await axios({
        method: "DELETE",
        url: `${apiBase}/uiMessages/${id}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });
}
