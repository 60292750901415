import { Collapse, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { useQuery } from "react-query";
import { getClientDimensions } from "../api/clients";
import { DimensionItem } from "../interfaces";

const createUserFriendlyDimensionName = (dimension: DimensionItem): string =>
    [dimension.identificationNumber, dimension.name].filter(Boolean).join(': ')
const createUserFriendlyDimensionDescription = (dimension: DimensionItem): string =>
    [dimension.description, (dimension.keywords ?? []).join(', ')].filter(Boolean).join(' ');

const ClientDimensions = (props: { clientId: number }) => {
    const { clientId } = props;
    const dimensionsQuery = useQuery(['client', clientId, 'dimensions'], () => getClientDimensions(clientId));

    const { Panel } = Collapse;

    if (dimensionsQuery.isLoading) return <Spin />

    return (
        <Collapse>
            {dimensionsQuery.data?.dimensions.map(dimension => 
            <Panel
                key={'root-' + dimension.id}
                header={<>{dimension.id}: {dimension.name} <Text type="secondary">{dimension.description}</Text></>}
            >
                <ul>
                {dimension.items.map(child => (
                    <li key={'child-' + child.id}>
                        {child.id}: {createUserFriendlyDimensionName(child)} <Text type="secondary">{createUserFriendlyDimensionDescription(child)}</Text>
                    </li>
                ))}
                </ul>
            </Panel>
            )}
        </Collapse>
    );
};

export default ClientDimensions;
