import { Breadcrumb } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export const pathNames: {[key: string]: string} = {
    'organizations': 'Organisaatiot',
    'clients': 'Asiakkaat',
    'teams': 'Tiimit',
    'users': 'Käyttäjät',
    'analytics': 'Analytiikka',
    'uiMessages': 'UI-tiedotteet',
    'settings': 'Asetukset',
    'billingPlans': 'Hinnoittelumallit',
    'new': 'Luo uusi',
    'edit': 'Muokkaa',
    'dashboard': 'Yleisnäkymä',
    'integrations': 'Kytkennät',
    'invoiceQuery': 'Käsittelyhistoria',
    'import': 'Tuonti',
    'confirm': 'Vahvista',
};

export type SnippetResolver = (identifier: string) => React.ReactNode | string;

interface PathSnippet {
    name: React.ReactNode | string;
    part: string;
    link: string | undefined;
}

function Breadcrumbs(props: { resolver?: SnippetResolver }) {
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter(i => i);

    const items = pathSnippets
        .map((part, index) => ({
            part,
            link: index === 0 ? `/${part}` : undefined,
            name: pathNames[part] || props.resolver && props.resolver(part),
        }) as PathSnippet)
        .filter((snippet) => snippet.name !== undefined)
        .map((snippet, index) => (
            <Breadcrumb.Item key={index}>
                {snippet.link
                    ? <Link to={snippet.link}>{snippet.name}</Link>
                    : snippet.name
                }
            </Breadcrumb.Item>
        ));

    items.unshift(<Breadcrumb.Item key={items.length}>Koti</Breadcrumb.Item>)

    return (
        <Breadcrumb>
            {items}
        </Breadcrumb>
    );
}

export default Breadcrumbs;
