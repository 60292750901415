import { SortOrder } from "antd/lib/table/interface";

// Mpa between API sort order and table sort order
export const sortOrder = (params: { order?: 'asc' | 'desc', orderBy?: string }, columnKey: string): SortOrder => {
    if (params.orderBy === columnKey) {
        return params.order === 'asc' ? 'ascend' : 'descend';
    }

    return null;
}
