import { Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React from "react";
import Header from "../components/Header";
import UserActionLog from "../components/UserActionLog";

const Dashboard = () => {

    return (
        <Content>
        <Header />
        <Space size="large" style={{ margin: '16px 0 24px 0' }}>
            <Title level={2} style={{ margin: 0 }}>
                Yleisnäkymä
            </Title>
        </Space>
        <Row gutter={[16, 16]}>
    <iframe
        width="100%"
        height="960"
        src="https://eu-central-1.quicksight.aws.amazon.com/sn/embed/share/accounts/314688591661/dashboards/806a7ad1-6f41-4c08-9562-49e2040efab8?directory_alias=fabricai">
    </iframe>
            <UserActionLog />
        </Row>
        </Content>
    );
};

export default Dashboard;
