import { Checkbox } from "antd";
import React from "react";
import { Filters } from "../interfaces";
import TagEditor from "./TagEditor";

interface ClientFiltersProps { filters: Filters, onChange: (filters: Filters) => void, loading: boolean }

const ClientFilters = ({ filters, onChange, loading }: ClientFiltersProps) => {
    return (
        <>
            <Checkbox
                onChange={(e) => onChange({ ...filters, dimensions: e.target.checked })}
                checked={filters.dimensions}
                disabled={loading}
            >Dimensiot</Checkbox><br />
            <Checkbox
                checked={filters.fai_circulate_list}
                onChange={(e) => onChange({ ...filters, fai_circulate_list: e.target.checked })}
                disabled={loading}
            >Kiertolista</Checkbox><br />

            <h4 style={{ marginTop: 4 }}>Hae vain tilit:</h4>
                <TagEditor
                    tags={Object.entries(filters.fetchOnlyAccounts || {})
                        .filter(([_, ok]) => ok)
                        .map(([account]) => account)}
                    onChange={(tags) => onChange({ ...filters, fetchOnlyAccounts: tags.reduce((acc, tag) => ({ ...acc, [tag]: true }), {}) })}
                    loading={loading}
                />
        </>
    );
};

export default ClientFilters;
