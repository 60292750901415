import Text, { TextProps } from 'antd/lib/typography/Text';
import React from 'react';

const Identifier = (props: TextProps) => {
    if (!props.children) {
        return <Text>-</Text>;
    }
    return (
        <Text {...props} code={props.code !== false ? true : false} copyable>{props.children}</Text>
    );
};

export default Identifier;
