import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { no404Retry } from "../api";
import {
  getOrganizationOldPeriods,
  getOrganizationReports,
  getPendingReports,
  ingestProps,
  requestReportGeneration,
} from "../api/organizations";
import { getTeams } from "../api/teams";
import { renderDate, renderDateTime } from "../util/datetime";
import "dayjs/locale/fi";
import dayjs from "dayjs";
import { uniq } from "ramda";

const Reporting = (props: { organizationId: number }) => {
  const { organizationId } = props;

  const [reportSearch, setReportSearch] = useState<string>("");
  const [teamSearch, setTeamSearch] = useState<string>("");

  const queryClient = useQueryClient();

  const reportsQuery = useQuery(
    ["reports", organizationId],
    () => getOrganizationReports(organizationId).then((c) => c.items),
    {
      retry: no404Retry,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      refetchInterval: 10 * 1000,
    }
  );

  const filteredReports = useMemo(() => {
    if (!reportsQuery.data) {
      return [];
    }

    return reportsQuery.data
      .filter((r) => {
        if (
          reportSearch &&
          !r.fileName.toLowerCase().includes(reportSearch.toLowerCase())
        ) {
          return false;
        }

        return true;
      })
      .sort(
        (a, b) =>
          new Date(b.lastModified).getTime() -
          new Date(a.lastModified).getTime()
      );
  }, [reportsQuery.data, reportSearch]);

  const periodsQuery = useQuery(
    ["reportPeriods", organizationId],
    () => getOrganizationOldPeriods(organizationId).then((c) => c.items),
    {
      retry: no404Retry,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const teamsQuery = useQuery("teams", () => getTeams().then((c) => c.items), {
    retry: no404Retry,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  const organizationTeams = useMemo(
    () =>
      teamsQuery.data
        ? teamsQuery.data.filter(
            (team) => team.organizationId === organizationId
          )
        : [],
    [teamsQuery.data]
  );

  const filteredPeriods = useMemo(() => {
    if (!periodsQuery.data) return [];
    return periodsQuery.data
      .filter((period) => {
        if (teamSearch.length > 0) {
          const team = organizationTeams.find(
            (team) => team.id === period.teamId
          );
          return (
            team && team.name.toLowerCase().includes(teamSearch.toLowerCase())
          );
        }
        return true;
      })
      .sort(
        (a, b) => new Date(b.period).getTime() - new Date(a.period).getTime()
      );
  }, [periodsQuery.data, teamSearch]);

  const pendingQuery = useQuery(
    ["pendingReports", organizationId],
    () => getPendingReports(organizationId).then((c) => c.items),
    {
      retry: no404Retry,
      refetchInterval: 10 * 1000,
    }
  );

  const generateMutation = useMutation(
    ({
      month,
      teamId,
      tag,
    }: {
      month: string;
      teamId: number | undefined;
      tag: string | undefined;
    }) => requestReportGeneration(organizationId, month, teamId, tag),
    {
      onSuccess: () => {
        message.success("Raportin generointi aloitettu", 15);
        queryClient.invalidateQueries(["pendingReports", organizationId]);
      },
      onError: (error) => {
        message.error(
          "Raportin generoinnin aloittaminen epäonnistui: " +
            JSON.stringify(error),
          15
        );
      },
    }
  );

  const [uploadProps, setUploadProps] = React.useState<Object>({
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          `Raportti ${info.file.name} ladattu, historiatiedot näkyvät hetken päästä!`,
          15
        );
      } else if (info.file.status === "error") {
        message.error(`Raportin ${info.file.name} lataus epäonnistui.`, 15);
      }
    },
  });

  useEffect(() => {
    ingestProps(organizationId).then((props) =>
      setUploadProps({
        ...uploadProps,
        ...props,
      })
    );
  }, [organizationId]);

  const tags = useMemo(() => {
    return uniq(
      organizationTeams.flatMap((team) =>
        Object.entries(team.tags ?? {}).map(([key, value]) => `${key}=${value}`)
      )
    );
  }, [organizationTeams]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card title="Luo raportti" size="small">
          <Form
            initialValues={{
              month: dayjs().startOf("month"),
            }}
            onFinish={(values) => {
              generateMutation.mutate({
                month: values.month.format("YYYY-MM"),
                teamId: values.teamId ?? undefined,
                tag: values.tag ?? undefined,
              });
            }}
          >
            <Space size="middle">
              <Form.Item noStyle name="month">
                <DatePicker
                  style={{ width: 160 }}
                  picker="month"
                  placeholder="Valitse kuukausi"
                />
              </Form.Item>
              <Divider type="vertical" />
              <Form.Item noStyle name="teamId">
                <Select
                  showSearch
                  allowClear
                  placeholder="Tiimi (valinnainen)"
                  optionFilterProp="label"
                  options={organizationTeams.map((team) => ({
                    label: team.name,
                    value: team.id,
                  }))}
                />
              </Form.Item>
              {tags.length > 0 && (
                <>
                  TAI
                  <Form.Item noStyle name="tag">
                    <Select
                      showSearch
                      allowClear
                      placeholder="Tagi (valinnainen)"
                      optionFilterProp="label"
                      options={tags.map((tag) => ({
                        label: tag,
                        value: tag,
                      }))}
                    />
                  </Form.Item>
                </>
              )}
              <Divider type="vertical" />
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit">
                  Luo raportti
                </Button>
              </Form.Item>
            </Space>
          </Form>
          {pendingQuery.data && pendingQuery.data.length > 0 && (
            <Table
              size="small"
              bordered
              style={{ marginTop: 16 }}
              title={() => "Generointi käynnissä"}
              columns={[
                {
                  dataIndex: "error",
                  key: "error",
                  render: (error) =>
                    error ? (
                      <Tooltip title={error}>
                        <ExclamationCircleOutlined style={{ color: "red" }} />
                      </Tooltip>
                    ) : (
                      <Spin size="small" />
                    ),
                  align: "center",
                },
                {
                  title: "Kuukausi",
                  dataIndex: "month",
                  key: "month",
                  render: (month) => renderDate(month).substring(2),
                },
                {
                  title: "Tiimi",
                  dataIndex: "teamId",
                  key: "teamId",
                  render: (teamId) =>
                    teamId
                      ? organizationTeams.find((team) => team.id === teamId)
                          ?.name
                      : "-",
                },
                {
                  title: "Tagi",
                  dataIndex: "tag",
                  key: "tag",
                  render: (tag) => tag || "-",
                },
                {
                  title: "Aloitettu",
                  dataIndex: "startedAt",
                  key: "startedAt",
                  render: (startedAt) => renderDateTime(startedAt),
                },
              ]}
              dataSource={pendingQuery.data}
            />
          )}
        </Card>
      </Col>
      <Col span={12}>
        <Card
          style={{ marginTop: 16 }}
          title="Generoidut raportit"
          size="small"
        >
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Hae tiedostonimellä..."
            value={reportSearch}
            onChange={(e) => setReportSearch(e.target.value)}
          />
          <Table
            loading={reportsQuery.isLoading}
            size="small"
            bordered
            columns={[
              {
                title: "Tiedosto",
                dataIndex: "fileName",
                render: (filename, { presignedURL }) => (
                  <a href={presignedURL}>{filename}</a>
                ),
              },
              {
                title: "Luotu",
                dataIndex: "lastModified",
                render: renderDateTime,
              },
            ]}
            dataSource={filteredReports}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card
          style={{ marginTop: 16 }}
          title="Tuo historiatietoja"
          size="small"
        >
          <p>
            Huomaathan, että mikäli yrität tuoda raportin, jonka sisältämiä
            rivejä on jo tietokannassa, tuonti epäonnistuu. Poista siis
            Excel-tiedostosta rivit, jotka ovat jo tietokannassa ennen tuontia.
          </p>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Valitse tiedosto</Button>
          </Upload>
        </Card>
        <Card
          style={{ marginTop: 16 }}
          loading={periodsQuery.isLoading}
          title="Historiatiedot"
          size="small"
        >
          <p>
            Näitä tietoja käytetään mm. kuvaajien luomiseen. Tiedot tallennetaan
            tietylle kuukaudelle automaattisesti raportin luonnin yhteydessä,
            mutta voit myös tuoda niitä vanhoilta raporteilta yllä.
          </p>
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Hae tiimin nimellä..."
            value={teamSearch}
            onChange={(e) => setTeamSearch(e.target.value)}
          />
          <Table
            size="small"
            bordered
            columns={[
              {
                title: "Kuukausi",
                dataIndex: "period",
                render: (date) => renderDate(date).substring(2),
              },
              {
                title: "Tiimi",
                dataIndex: "teamId",
                render: (teamId) =>
                  teamId
                    ? teamsQuery.data?.find((t) => t.id === teamId)?.name
                    : "-",
              },
              { title: "Tagi", dataIndex: "tag", render: (tag) => tag || "-" },
            ]}
            dataSource={filteredPeriods}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Reporting;
