export interface MLModelProcessor {
    deprecationNotice: string;
    description: string;
    generator: string;
    id: number;
    initialRelease: string;
    isDeprecated: boolean;
    plannedEndOfLife: string;
    processor: string;
    suggestedCountry: string;
    suggestedForRetraining: boolean;
    suggestedLabel: string;
}

export interface MLModelTrainingRequest {
    dimensionId: string | null;
    label: string;
    priority: number | null;
    processorId?: number;
}

export const mlModelTrainingStatuses = <const>['PENDING', 'PRE_PROCESSING', 'RUNNING', 'COMPLETED', 'DEPLOYED', 'FAILED', 'CANCELLED']
export type MLModelTrainingStatus = typeof mlModelTrainingStatuses[number]
export const mlModelTrainingLogStatuses = <const>[...mlModelTrainingStatuses, 'INFO']
export type MLModelTrainingLogStatus = typeof mlModelTrainingLogStatuses[number]
export interface MLModel {
    dimensionId: string;
    label: string;
    modelGenerator: string;
    modelId?: string;
    preprocessor: string;
    trainingId: number;
    logs?: ModelTrainingLog[];
    status: MLModelTrainingStatus;
}

export interface ModelTrainingLog {
    id: number;
    message: string;
    modelTrainingRequestsId: number;
    requester: string;
    status: MLModelTrainingLogStatus;
    createdAt: string;
    updatedAt: string;
}
