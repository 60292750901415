// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
  process.env.FIREBASE_CONFIG === 'dev' 
  ? {
    apiKey: "AIzaSyB9xtJ17v-ku5gVykp568IMv4hgtlTnocA",
    authDomain: "invoicetagger-qa-21761.firebaseapp.com",
    databaseURL: "https://invoicetagger-qa-21761-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "invoicetagger-qa-21761",
    storageBucket: "invoicetagger-qa-21761.appspot.com",
    messagingSenderId: "952639743500",
    appId: "1:952639743500:web:d285a35633598b7ddd7029",
    measurementId: "G-J4732E1JCE"
  }
  : {
    apiKey: "AIzaSyARdJligVMMd8GsU4nkAZ9IAhrF-OltGKk",
    authDomain: "invoicetagger.firebaseapp.com",
    databaseURL: "https://invoicetagger.firebaseio.com",
    projectId: "invoicetagger",
    storageBucket: "invoicetagger.appspot.com",
    messagingSenderId: "574424198507",
    appId: "1:574424198507:web:d4e1ab13f37fa9bc",
    measurementId: "G-QSE6305HM3"
  }

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth();
