import React, { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { ApiOutlined } from "@ant-design/icons";
import { Button, Divider, notification, Popconfirm, Space, Table, Tag } from "antd";
import { Content } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { APIError, Collection, no404Retry } from "../api";
import { getIntegrations, ignoreIntegration } from "../api/integrations";
import Header from "../components/Header";
import QueryError from "../components/QueryError";
import SystemTag from "../components/SystemTag";
import { renderDateTime } from "../util/datetime";
import { importDataState } from "../atoms";
import { Navigate } from "react-router-dom";
import { Integration } from "../interfaces";

const NewIntegrations = () => {
    const [importData, setImportData] = useRecoilState(importDataState);

    const integrationsQuery = useQuery('integrations', () => getIntegrations({ timespan: '720h' }), {
        retry: no404Retry,
        refetchOnWindowFocus: false,
        enabled: !importData,
    });

    const queryClient = useQueryClient();

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    const ignoreIntegrationsMutation = useMutation(() => Promise.all(selectedRowKeys.map((key) => ignoreIntegration(key))), {
        onSuccess: () => {
            setSelectedRowKeys([]);
            queryClient.setQueryData<Collection<Integration>>('integrations', (prev: Collection<Integration>) => ({
                ...prev,
                items: prev.items.filter(i => !selectedRowKeys.includes(i.integrationKey)),
            }));
        },
        onError: (error: APIError) => {
            notification.error({
                message: error.message,
                description: error.response?.data.error,
            });
        }
    });

    const handleStartImport = useCallback(() => {
        if (selectedRowKeys.length === 0) {
            return;
        }

        setImportData(
            integrationsQuery.data!.items
                .filter(i => selectedRowKeys.includes(i.integrationKey))
                .map(i => ({ ...i, dimensions: true, organization: i.accountingOfficeName }))
            );
    }, [selectedRowKeys, integrationsQuery.data?.items]);

    // If there are integrations saved in local storage, this means there is an import in progress
    if (importData !== null) {
        return (
            <Navigate to="import" />
        );
    }

    return (
        <Content>
            <Header />
            <Space align="center" size="large" style={{ margin: '16px 0 24px 0', width: '100%' }}>
                <Title style={{ margin: 0 }} level={2}>Uudet kytkennät</Title>
                {selectedRowKeys.length > 0 && <Space split={<Divider />}>
                    <Button icon={<ApiOutlined />} onClick={handleStartImport}>
                        Aloita valittujen ({selectedRowKeys.length}) tuonti FabricAI:hin
                    </Button>
                    <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => ignoreIntegrationsMutation.mutate()}
                    okText="Kyllä"
                    cancelText="Ei"
                    placement="bottom"
                    >
                    <Button loading={ignoreIntegrationsMutation.isLoading} danger>Ohita valitut</Button>
                    </Popconfirm>
                </Space>
                }
            </Space>
            <QueryError query={integrationsQuery} />
            <Table
                bordered
                size="small"
                loading={integrationsQuery.isLoading}
                pagination={false}
                rowKey="integrationKey"
                dataSource={integrationsQuery.data?.items}
                footer={() => <Text type="secondary">{integrationsQuery.data?.totalCount} tulosta</Text>}
                rowSelection={{
                    onChange: (selectedRowKeys: string[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    selectedRowKeys,
                }}
                columns={[
                    {
                        title: 'Yritys',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name, record) => <>{name} <Text type="secondary">({record.accountingOfficeName})</Text></>,
                    },
                    {
                        title: 'Y-tunnus',
                        dataIndex: 'businessId',
                        key: 'businessId',
                    },
                    {
                        title: 'Järjestelmä',
                        dataIndex: 'system',
                        key: 'system',
                        render: system => <SystemTag system={system} />,
                    },
                    {
                        title: 'Integraatioavain',
                        dataIndex: 'integrationKey',
                        key: 'integrationKey',
                    },
                    {
                        title: 'Kytketty',
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (timestamp, integration) => <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text type="secondary">{renderDateTime(timestamp)}</Text>
                        {integration.addedBy && <a style={{ display: 'block' }} target="_blank" href={'/users/' + integration.addedBy}><Tag color="magenta">Lisätty UI:sta</Tag></a>}
                        </div>,
                    }
                ]}
            />
        </Content>
    );
}

export default NewIntegrations;
