import { MLModel } from "./mlModel";

export interface Approval {
    allowedCounterPartyNames: string[];
    allowedPredictedLabels: string[];
    avatarURL: string;
    blockedCounterPartyNames: string[];
    blockedPredictedLabels: string[];
    clientId: number;
    createdAt: string;
    deletedAt: string;
    id: number;
    key: string;
    maximumAllowedAbsoluteExpectedErrorValueForItem: number;
    maximumAllowedAbsoluteInvoiceTotal: number;
    minimumRequiredProbability: number;
    name: string;
    patience: 'NONE' | 'NORMAL' | 'MEDIUM' | 'HIGH';
    progressiveAutomationAnalysisStartDate: string;
    qualityAssuranceRate: number;
    status: string;
    type: string;
    updatedAt: string;
}

export interface Automations {
    approval: Approval[] | null;
}

export interface ExtraInfo {
    en: string;
    fi: string;
    sv: string;
}

export interface EInvoiceAddress {
    address?: string;
    businessId?: string;
    extraInfo?: ExtraInfo;
    name?: string;
    operatorAddress?: string;
    operatorName?: string;
}

export interface FetchOnlyAccounts {
    [account: string]: boolean;
}

export interface Filters {
    dimensions: boolean;
    fai_circulate_list: boolean;
    fetchOnlyAccounts: FetchOnlyAccounts | null;
}

export interface FiscalPeriodTresholdHistory {
    endTime: string;
    fiscalPeriodTreshold: string;
}

export interface InvoiceStatusCountsInAccountingSystem {
    [month: string]: { [status: string]: number };
}
export interface OwnerOrganization {
    id: number;
    key: string;
    name: string;
}

export interface ServiceTemplateHistory {
    endTime: string;
    serviceTemplate: string;
}

export interface StatusHistory {
    endTime: string;
    status: string;
}

export interface Tags {
    [key: string]: string;
}

export interface ClientTeam {
    id: number;
    key?: string;
    name: string;
}

export interface Client {
    accountingSystemCompanyId: string;
    automations: Automations;
    businessId: string;
    contactEmail: string;
    country: string;
    createdAt: string;
    deletedAt?: string;
    domain: string;
    eInvoiceAddress?: EInvoiceAddress;
    filters?: Filters;
    fiscalPeriodTreshold: string;
    fiscalPeriodTresholdHistory?: FiscalPeriodTresholdHistory[];
    id: number;
    invoiceStatusCountsInAccountingSystem?: InvoiceStatusCountsInAccountingSystem;
    integrationKey?: string;
    key?: string;
    mainLineOfBusiness: string;
    mlModels?: MLModel[];
    name: string;
    displayName: string;
    organization?: OwnerOrganization;
    organizationId: number | null;
    providerId: number;
    serviceTemplate: string;
    serviceTemplateHistory?: ServiceTemplateHistory[];
    startDateForFetchingInvoices: string;
    invoicesLastFetched: string | null;
    status: string;
    statusHistory?: StatusHistory[];
    system: string;
    accountingSystem: string;
    tags?: Tags;
    tol: string;
    teams?: ClientTeam[];
    updatedAt: string;
    hasCoa?: boolean;
    hasDimensions?: boolean;
    defaultsAndSettings: DefaultsAndSettings;
}

export interface DefaultsAndSettings {
    allowEmptyModelTraining: boolean | null;
    blockCrossUseOfMLModels: boolean | null;
    defaultMTRForcedLowerThreshold: string | null;
    defaultVatDeductionPercent: number | null;
    defaultVatPercent: number | null;
    defaultVatStatus: string | null;
    defaultMergeItems: boolean | null;
  }

export interface Account {
    code: string;
    description: string;
    name: string;
}

export interface ClientCOA {
    accounts?: Account[];
    clientId: number;
    createdAt: string;
    hash?: string;
    updatedAt: string;
}

export interface RelatedAddress {
    city: string;
    country: string;
    name: string;
    street: string;
    zip: string;
}

export interface DimensionItem {
    description: string;
    id: string;
    identificationNumber: string;
    keywords: string[];
    name: string;
    relatedAddress: RelatedAddress;
}

export interface Dimension {
    description: string;
    id: string;
    items: DimensionItem[];
    name: string;
}

export interface ClientDimensions {
    clientId: number;
    createdAt: string;
    dimensions: Dimension[];
    hash: string;
    updatedAt: string;
}
