import axios from "axios";
import { z } from "zod";
import { Collection, apiBase, getAuthHeader } from ".";
import { billingPlan, type BillingPlan } from "../interfaces";

const billingPlans = z.array(billingPlan);

export const getBillingPlans = async (): Promise<Collection<BillingPlan>> => {
	const result = await axios({
		method: "GET",
		url: `${apiBase}/billing/plans`,
		headers: {
			"Authorization": await getAuthHeader(),
		},
	});

	return {
		totalCount: result.data.totalCount || 0,
		items: billingPlans.parse(result.data.data || []),
	};
}

export const BillingPlanRecordInput = z.object({
	billingPlanId: z.string(),
	startDate: z.string().date(),
	endDate: z.string().date().nullable(),
});

export type BillingPlanRecordInput = z.infer<typeof BillingPlanRecordInput>;

export const BillingPlanRecord = BillingPlanRecordInput.merge(z.object({
	billingPlanDescription: z.string().optional(),
	organizationId: z.number(),
	id: z.number(),
}));

export type BillingPlanRecord = z.infer<typeof BillingPlanRecord>;

export const getOrganizationBillingPlanRecords = async (organizationId: number): Promise<Collection<BillingPlanRecord>> => {
	const result = await axios({
		method: "GET",
		url: `${apiBase}/organizations/${organizationId}/billing`,
		headers: {
			"Authorization": await getAuthHeader(),
		},
	});

	return {
		totalCount: result.data.totalCount || 0,
		items: z.array(BillingPlanRecord).parse(result.data.data || []),
	};
};

export const createBillingPlanRecord = async (organizationId: number, data: BillingPlanRecordInput): Promise<BillingPlanRecord> => {
	const result = await axios({
		method: "POST",
		url: `${apiBase}/organizations/${organizationId}/billing`,
		headers: {
			"Authorization": await getAuthHeader(),
		},
		data,
	});

	return BillingPlanRecord.parse(result.data.data);
}

export const updateBillingPlanRecord = async (organizationId: number, recordId: number, data: BillingPlanRecordInput): Promise<BillingPlanRecord> => {
	const result = await axios({
		method: "PUT",
		url: `${apiBase}/organizations/${organizationId}/billing/${recordId}`,
		headers: {
			"Authorization": await getAuthHeader(),
		},
		data,
	});

	return BillingPlanRecord.parse(result.data.data);
}

export const deleteBillingPlanRecord = async (organizationId: number, recordId: number): Promise<void> => {
	await axios({
		method: "DELETE",
		url: `${apiBase}/organizations/${organizationId}/billing/${recordId}`,
		headers: {
			"Authorization": await getAuthHeader(),
		},
	});
}
