import axios from "axios";
import qs from "qs";
import { z } from "zod";
import { apiBase, APIResponse, getAuthHeader } from ".";

const AggregatedCompanyData = z.object({
    businessId: z.string(),
    country: z.string(),
    domain: z.string(),
    industry: z.string(),
    name: z.string(),
    officialName: z.string(),
    tol: z.string(),
  });

export type AggregatedCompanyData = z.infer<typeof AggregatedCompanyData>;

export const getAggregatedCompanyData = async (name: string, businessId: string): Promise<AggregatedCompanyData> => {
    let params: Record<string, string> = {
		name: name,
	};

	if (businessId) {
		params.businessId = businessId;
	}
	
	const result: APIResponse<any> = await axios({
        method: "GET",
        url: `${apiBase}/companies/aggregated?${qs.stringify({
            ...params,
        })}`,
        headers: {
            "Authorization": await getAuthHeader(),
        },
    });

	return AggregatedCompanyData.parse(result.data.data);
};
