import { ArrowRightOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Divider, List, Skeleton, Space, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getUserActionLog } from '../api/users';
import { Change, UpdateAutomationAction, UserAction } from '../interfaces';
import { renderDateTime } from '../util/datetime';

const actionsPerPage = 20;

const UserActionLog = () => {
    const actionsQuery = useInfiniteQuery(
        'actionLog',
        ({ pageParam = 0 }) => getUserActionLog(actionsPerPage, pageParam),
        {
            getNextPageParam: (lastPage, pages) => lastPage.items.length == actionsPerPage
                ? pages.length * actionsPerPage
                : undefined,
        }
    );

    const data = actionsQuery.data?.pages.flatMap(page => page.items) ?? [];

    return (
        <Col span={16}>
            <Card title="Käyttäjien toimintoloki">
                <div
                    id="scrollableDiv"
                    style={{
                        height: 400,
                        overflow: 'auto',
                    }}
                >
                    <InfiniteScroll
                        dataLength={data.length}
                        next={actionsQuery.fetchNextPage}
                        hasMore={!!actionsQuery.hasNextPage}
                        loader={<Skeleton paragraph={{ rows: 1 }} active />}
                        endMessage={<Divider plain>Ei enempää tuloksia</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={data}
                            size="small"
                            renderItem={(action) => <ActionItem key={action.id} action={action} />}
                        />
                    </InfiniteScroll>
                </div>
            </Card>
        </Col>
    )
}


type TemplateFunction<T, MT> = (action: UserAction<T, MT>) => {
    title: React.ReactNode,
    avatar?: React.ReactNode,
    content?: React.ReactNode,
    actions?: React.ReactNode[]
};

const actionTemplates: { [action: string]: TemplateFunction<typeof action, any> } = {
    'update_automation': (action: UpdateAutomationAction) => ({
        title: <>
            Asiakkaan <Link to={`/clients/${action.metadata.clientId}?tab=automations`}>
                {action.metadata.clientName}
            </Link> automaatiota #{action.metadata.automationId} muutettu </>,
        content: <Space direction='vertical'>
            {action.metadata.changes.filter(({ from, to }) => from !== to).map((change, index) => <Change key={index} change={change} />)}
        </Space>
    }),
};

const ActionItem = ({ action }: { action: UserAction<string, any> }) => {
    const template = actionTemplates[action.action] ? actionTemplates[action.action]!(action) : {
        title: `${action.userName} teki toiminnon ${action.action}`,
    };

    return (
        <List.Item
            extra={template.actions}
        >
            <List.Item.Meta
                avatar={template.avatar}
                title={template.title}
                description={<>
                    <Link to={`/users/${action.userId}`}>
                        <Text type="secondary">{action.userName}</Text>
                    </Link> - {renderDateTime(action.createdAt)}
                </>}
            />
            {template.content}
        </List.Item>
    );
};

const labels: { [key: string]: string } = {
    'allowedCounterPartyNames': 'Sallitut toimittajat',
    'blockedCounterPartyNames': 'Estetyt toimittajat',
    'allowedPredictedLabels': 'Sallitut ennustettavat tietueet',
    'blockedPredictedLabels': 'Estetyt ennustettavat tietueet',
    'status': 'Tila',
};

const Change = ({ change }: { change: Change }) => {
    const value = change.path[0]!;
    const { from, to } = change;
    const changeType = change.type;

    let content;

    switch (value) {
        case 'status':
            content = <>
                <Tag color={{
                    'ACTIVE': 'green',
                    'INACTIVE': 'red',
                    'SANDBOX': 'geekblue',
                }[from]}>{from}</Tag>
                <ArrowRightOutlined style={{ marginRight: 8 }} />
                <Tag color={{
                    'ACTIVE': 'green',
                    'INACTIVE': 'red',
                    'SANDBOX': 'geekblue',
                }[to]}>{to}</Tag>
            </>
            break;
        case 'allowedCounterPartyNames':
        case 'blockedCounterPartyNames':
        case 'allowedPredictedLabels':
        case 'blockedPredictedLabels':
            content = changeType === 'create'
                ? <Tag icon={<PlusOutlined />} color="green">{to}</Tag>
                : <Tag icon={<MinusOutlined />} color="red">{from}</Tag>
            break;
        default:
            content = <><Text code>{from}</Text> <ArrowRightOutlined /> <Text code>{to}</Text></>
    }

    return <>
        <Text style={{ marginRight: 8 }}>{labels[value] ? labels[value] : value}:</Text>
        {content}
    </>;
};

export default UserActionLog;
